import { Box } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { parse, stringify } from 'query-string'

import { selectCurrentUser } from 'src/entities/user/selectors'
import { Redirect, useLocation } from 'src/navigation'
import ImageHeaderScrollView from 'src/retired/blocks/ImageHeaderScrollView'
import { Text } from 'src/retired/elements'
import { colors } from 'src/theme'
import { useInjectEpics } from 'src/utils/injectEpics'
import { useInjectReducer } from 'src/utils/injectReducer'

import { AddFriendStep } from './AddFriendStep'
import epics from './epics'
import { RateStep } from './RateStep'
import reducer from './reducer'

export const DeedRate = () => {
  useInjectReducer({ key: 'deedRate', reducer })
  useInjectEpics({ key: 'deedRate', epics })

  const location = useLocation<{ location: string }>()
  const user = useSelector(selectCurrentUser)

  const [showAddFriendStep, setShowAddFriendStep] = useState<boolean>(false)

  const deed = user?.ratePending[0]
  const ratePendingDeedAmount = user?.ratePending.length ?? 0
  const targetLocation = parse(location?.search)?.location
  const redirectLocation = ratePendingDeedAmount
    ? `/deeds/rate?${stringify({ location: targetLocation })}`
    : targetLocation ?? '/home'

  if (!deed) {
    return <Redirect to={redirectLocation} />
  }

  const filteredAttendees = deed.attendees.filter(
    (attendee) => !(user?.myFriends.find((friend) => friend.id === attendee.id) || user?.id === attendee.id)
  )

  return (
    <ImageHeaderScrollView
      image={deed.mainPicture}
      maxHeight={225}
      title={deed.name}
      subTitle={deed.organization?.name}
      noBack
      renderForeground={() => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '80%',
            position: 'absolute',
            px: 3,
            gap: 2,
          }}
        >
          {deed.organization?.name ? (
            <Text size={12} color={colors.white} numberOfLines={1} center>
              {deed.organization?.name}
            </Text>
          ) : null}
          <Text size={32} color={colors.white} medium numberOfLines={2} center>
            {deed.name}
          </Text>
        </Box>
      )}
    >
      <Box
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {showAddFriendStep ? (
          <AddFriendStep deed={deed} attendees={filteredAttendees} redirectLocation={redirectLocation} />
        ) : (
          <RateStep
            deed={deed}
            noFriendsToAdd={filteredAttendees.length === 0}
            redirectLocation={redirectLocation}
            setShowAddFriendStep={setShowAddFriendStep}
          />
        )}
      </Box>
    </ImageHeaderScrollView>
  )
}

export default DeedRate
