import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ClearIcon from '@mui/icons-material/Clear'
import { Box, ButtonBase, CircularProgress, IconButton, InputAdornment, ListItem, TextField } from '@mui/material'
import { debounce } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import User from 'src/entities/user/model'
import { Text } from 'src/retired/elements'
import { Body2 } from 'src/retired/shared/Typography'
import { colors } from 'src/theme'

interface UserSearchListProps {
  term: string
  results: User[]
  loading: boolean
  error: string | null
  selected: Map<string, object>
  onSearch: (value: string) => void
  onClearSearch: () => void
  onSelect: (id: string, user: User) => void
}

export const UserSearchList: React.FC<UserSearchListProps> = ({
  term: initialTerm,
  results,
  loading,
  error,
  selected,
  onSearch,
  onClearSearch,
  onSelect,
}) => {
  const { t } = useTranslation('deedScreen')
  const [term, setTerm] = useState(initialTerm)

  const termParts = term.split(' ')
  const termFirstRegex = new RegExp(termParts[0], 'i')
  const termLastRegex = termParts[1] ? new RegExp(termParts[1], 'i') : termFirstRegex

  const debouncedSearch = useMemo(() => debounce(onSearch, 300), [onSearch])

  const handleSearchChange = (value: string) => {
    setTerm(value)
    debouncedSearch(value)
  }

  const handleClearSearch = () => {
    onClearSearch()
    setTerm('')
  }

  useEffect(
    () => () => {
      debouncedSearch.cancel()
    },
    [debouncedSearch]
  )

  return (
    <Box>
      <TextField
        variant="standard"
        label="Search"
        onChange={(e) => handleSearchChange(e.target.value)}
        value={term}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {term && (
                <IconButton onClick={handleClearSearch} edge="end" sx={{ mr: 1 }}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />

      {loading ? (
        <Box sx={{ py: 4, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={14} />
        </Box>
      ) : term.length > 1 && results.length === 0 ? (
        <Box sx={{ py: 4, display: 'flex', justifyContent: 'center' }}>
          <Body2 style={{ textAlign: 'center' }}>{error || t`noMatchedFound`}</Body2>
        </Box>
      ) : results.length > 0 ? (
        <Box sx={{ overflowY: 'scroll', maxHeight: 300, width: '100%' }}>
          {results.map((result: User) => (
            <ListItem
              component={ButtonBase}
              key={result.id}
              onClick={() => onSelect(result.id, result)}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                justifyContent: 'space-between',
                transition: 'background-color 0.3s',
                '&:hover': {
                  backgroundColor: colors.gray03,
                },
              }}
            >
              <Text numberOfLines={1} lineHeight="26px">
                {result.fullName.first.search(termFirstRegex) !== -1 && (
                  <Text lineHeight="26px">{result.fullName.first.match(termFirstRegex)?.[0]}</Text>
                )}
                {result.fullName.first.replace(termFirstRegex, '') !== '' && (
                  <Text color={colors.grayMedium} lineHeight="26px">
                    {result.fullName.first.replace(termFirstRegex, '')}
                  </Text>
                )}{' '}
                {result.fullName.last.search(termLastRegex) !== -1 && (
                  <Text lineHeight="26px">{result.fullName.last.match(termLastRegex)?.[0]}</Text>
                )}
                {result.fullName.last.replace(termLastRegex, '') !== '' && (
                  <Text color={colors.grayMedium} lineHeight="26px">
                    {result.fullName.last.replace(termLastRegex, '')}
                  </Text>
                )}
              </Text>
              {selected.has(result.id) ? (
                <CheckCircleIcon color="success" fontSize="small" />
              ) : (
                <AddCircleOutlineOutlinedIcon fontSize="small" />
              )}
            </ListItem>
          ))}
        </Box>
      ) : (
        <Box sx={{ pb: 2 }} />
      )}
    </Box>
  )
}
