import { createSelector } from 'reselect'

import { State } from 'src/reducers'

import { initialState } from './reducer'

const selectState = (state: State) => state.get('deed') || initialState

export const selectLoading = createSelector(selectState, (state) => state.get('loading'))

export const selectRefreshing = createSelector(selectState, (state) => state.get('refreshing'))

export const selectCompleting = createSelector(selectState, (state) => state.get('completing'))

export const selectError = createSelector(selectState, (state) => state.get('error'))

export const selectVolunteerTimesError = createSelector(selectState, (state) => state.get('volunteerTimesError'))

export const selectVolunteerTimesLoading = createSelector(selectState, (state) => state.get('volunteerTimesLoading'))

export const selectRemovingAttendee = createSelector(selectState, (state) => state.get('removingAttendee'))

export const selectSendingEmailToAttendees = createSelector(selectState, (state) =>
  state.get('sendingEmailToAttendees')
)

export const showAttendeesContactModal = createSelector(selectState, (state) =>
  state.get('toggleAttendeesContactModal')
)
