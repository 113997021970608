/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectCausesLoaded } from 'src/entities/cause/selectors'
import { selectDeedById } from 'src/entities/deed/selectors'
import { useParams } from 'src/navigation'
import { State } from 'src/reducers'
import { useInjectEpics } from 'src/utils/injectEpics'
import { useInjectReducer } from 'src/utils/injectReducer'
import { selectCurrentUser } from 'src/entities/user/selectors'

import { fetchCausesAction, fetchSkillsAction } from '../Home/actions'
import homeEpics from '../Home/epics'
import homeReducer from '../Home/reducer'

import { fetchDeedAction } from './actions'
import { selectError, selectLoading, selectRefreshing } from './selectors'
import { DeedPresentational } from './DeedPresentational'

export const Deed = (): JSX.Element => {
  useInjectReducer({ key: 'home', reducer: homeReducer })
  useInjectEpics({ key: 'home', epics: homeEpics })

  const params = useParams<{ deed?: string; lite?: string }>()

  const user = useSelector((state: State) => selectCurrentUser(state))
  const deed = useSelector((state: State) => params.deed && selectDeedById(state, params.deed)) || undefined
  const loading = useSelector((state: State) => selectLoading(state))
  const refreshing = useSelector((state: State) => selectRefreshing(state))
  const error = useSelector((state: State) => selectError(state)) || undefined
  const causesLoaded = useSelector(selectCausesLoaded)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchDeedAction(params.deed))
    dispatch(fetchSkillsAction())

    if (!causesLoaded) {
      dispatch(fetchCausesAction())
    }
  }, [dispatch, params.deed])

  return <DeedPresentational deed={deed} user={user} loading={loading} refreshing={refreshing} error={error} />
}
