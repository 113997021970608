import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Spacing, Row, Icon, Text, LinkButton } from 'src/retired/elements'
import { colors } from 'src/theme'
import { Platform } from 'src/utils'
import { CardCompactWrapper } from 'src/components'
import { selectCurrentUser } from 'src/entities/user/selectors'

const Deed = ({ deed, canCheckIn, isCheckedIn, isMyProfile, approved, applied }) => {
  const { t } = useTranslation('deed')
  const user = useSelector(selectCurrentUser)
  const isUpcoming = deed.isUpcoming()
  const isOngoing = deed.isOngoing()

  const nonprofits = deed.getNonprofits()
  const nonprofitNames = nonprofits.map((nonprofit) => nonprofit.name)?.join(', ')

  const startingAt = deed.getStartingAtForUser(isMyProfile && user?.id)
  const startingAtWithTimezone = deed.virtual ? startingAt : { value: startingAt, timeZone: deed.timeZone }
  return (
    <LinkButton
      to={`/deeds/${deed.id}${canCheckIn ? '/checkIn' : ''}`}
      style={{ minHeight: 143, paddingBottom: 25 }}
      color="transparent"
    >
      <CardCompactWrapper cardColor={deed.color()} fullWidth>
        <View style={{ flexGrow: 1 }}>
          {deed.startingAt ? (
            <>
              <Row style={{ justifyContent: 'space-between', flexGrow: 0 }}>
                <Text size={13} light left>
                  {t('date:monthYear', {
                    date: startingAtWithTimezone,
                  })}
                </Text>
                <Text size={13} light left style={{ textAlign: 'right' }}>
                  {t('date:weekdayTime', {
                    date: startingAtWithTimezone,
                  })}
                </Text>
              </Row>
              <Spacing marginTop={2} marginBottom={15}>
                <Text size={15} medium left underline>
                  {t('date:day', {
                    date: startingAtWithTimezone,
                  })}
                </Text>
              </Spacing>
            </>
          ) : (
            <Text size={13} light left>
              {approved ? t`approved` : applied ? t`applied` : ''}
            </Text>
          )}
        </View>
        <Row style={{ justifyContent: 'space-between' }}>
          <View style={{ flex: 1, paddingRight: 15, alignSelf: 'flex-end' }}>
            {nonprofitNames ? (
              <>
                <Text size={13} left numberOfLines={1} title={nonprofitNames}>
                  {nonprofitNames}
                </Text>
                <Spacing paddingBottom={5} />
              </>
            ) : null}
            <Text size={15} medium left numberOfLines={Platform.OS === 'web' ? 1 : 2}>
              {deed.name}
            </Text>
          </View>

          {isUpcoming || isOngoing ? (
            // NOTE-AZ: ideally we could do only one main check , and display linkButton or right arrow icon
            isMyProfile && canCheckIn ? (
              <LinkButton
                to={`/deeds/${deed.id}/checkIn`}
                color="transparent"
                style={{
                  borderRadius: 100,
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: colors.black,
                  alignSelf: 'flex-end',
                  paddingTop: Platform.OS === 'web' ? 0 : 5,
                  paddingLeft: 15,
                  paddingRight: 15,
                  height: 22,
                  alignItems: 'center',
                }}
              >
                <Text size={12} lineHeight={12} light>
                  {isCheckedIn ? t`checkedIn` : t`checkIn`}
                </Text>
              </LinkButton>
            ) : (
              <View style={{ alignSelf: 'flex-end' }}>
                <Icon icon="arrowCircleRightBlack" style={{ width: 26, height: 26 }} />
              </View>
            )
          ) : (
            <View style={{ flexDirection: 'row', alignSelf: 'flex-end' }}>
              {/* <Icon icon={deed.rating > 0 ? 'starFilledPink' : 'starOutlinePink'} style={{ width: 16, height: 15, marginLeft: 5 }} />
              <Icon icon={deed.rating > 1 ? 'starFilledPink' : 'starOutlinePink'} style={{ width: 16, height: 15, marginLeft: 5 }} />
              <Icon icon={deed.rating > 2 ? 'starFilledPink' : 'starOutlinePink'} style={{ width: 16, height: 15, marginLeft: 5 }} />
              <Icon icon={deed.rating > 3 ? 'starFilledPink' : 'starOutlinePink'} style={{ width: 16, height: 15, marginLeft: 5 }} />
              <Icon icon={deed.rating > 4 ? 'starFilledPink' : 'starOutlinePink'} style={{ width: 16, height: 15, marginLeft: 5 }} /> */}
            </View>
          )}
        </Row>
      </CardCompactWrapper>
    </LinkButton>
  )
}

Deed.propTypes = {
  deed: PropTypes.object,
  canCheckIn: PropTypes.bool,
  isCheckedIn: PropTypes.bool,
  isMyProfile: PropTypes.bool,
  approved: PropTypes.bool,
  applied: PropTypes.bool,
}

export default Deed
