import React from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { CardCompactWrapper } from 'src/components'
import { Icon, LinkButton, Spacing } from 'src/retired/elements'
import { Link } from 'src/navigation'
import Deed from 'src/entities/deed/model'
import User from 'src/entities/user/model'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Body1, Label } from 'src/retired/shared/Typography'

interface CreatedDeedCompactProps {
  deed: Deed
  user?: User
}

const CreatedDeedCompact = ({ deed, user }: CreatedDeedCompactProps): JSX.Element => {
  const { t } = useTranslation('createdDeedCompactBlock')
  const { colors } = useDeedTheme()

  const nonprofits = deed.getNonprofits()

  const nonprofitNames = nonprofits.map((nonprofit) => nonprofit.name)?.join(', ')

  return (
    <Link to={`/deeds/${deed.id}`} color="transparent">
      <CardCompactWrapper cardColor={deed.color()}>
        <Spacing marginBottom={12}>
          <Label>
            {t('date:dayMonthYear', {
              date: { value: deed.createdAt, timeZone: deed.timeZone },
            })}
          </Label>
        </Spacing>

        <Spacing marginBottom={2}>
          <Body1 weight="500" numberOfLines={1}>
            {deed.name}
          </Body1>
        </Spacing>

        {nonprofitNames ? (
          <Spacing>
            <Label numberOfLines={1}>{nonprofitNames}</Label>
          </Spacing>
        ) : null}

        <View style={{ position: 'absolute', right: 12, bottom: 12 }}>
          {deed.canUserEditDeed(user) ? (
            <LinkButton
              to={`/edit-deed/${deed.urlType}/${deed.id}`}
              color="transparent"
              style={{
                display: 'flex',
                borderRadius: 100,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: colors.brandColor,
                alignSelf: 'flex-start',
                paddingLeft: 15,
                paddingRight: 15,
                height: 21,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Label>{t`edit`}</Label>
            </LinkButton>
          ) : (
            <Icon icon="arrowCircleRightBlack" style={{ width: 24, height: 24 }} />
          )}
        </View>
      </CardCompactWrapper>
    </Link>
  )
}

export default CreatedDeedCompact
