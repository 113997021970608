import React from 'react'
import { useTranslation } from 'react-i18next'

import DeedType from 'src/entities/deed/model'
import User from 'src/entities/user/model'
import { Body1 } from 'src/retired/shared/Typography'
import { Spacing } from 'src/retired/elements'

import { getDeedDurationString } from '../utils'

import { Box } from './MapBlock'

export const ProjectInfoBlock = ({ deed, user }: { deed: DeedType; user?: User }): React.ReactNode => {
  const { t } = useTranslation('deedScreen')
  if (deed.type !== 'Project' || !!deed.externalId) {
    return null
  }

  return (
    <Box half>
      <Spacing marginBottom={20}>
        <Body1>
          {deed.hours.min}
          {deed.hours.max !== deed.hours.min ? `-${deed.hours.max}` : ''} {t('hour', { count: deed.hours.max })}{' '}
          {t`over`} {deed.duration && typeof deed.duration === 'object' && getDeedDurationString(deed.duration)}
        </Body1>
        {user?.organization?.name !== 'Stripe' ? (
          <Body1 marginTop={8}>{t('estimatedSavings', { value: deed.value })}</Body1>
        ) : null}
      </Spacing>
    </Box>
  )
}
