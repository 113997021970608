import Deed from 'src/entities/deed/model'

import {
  APPLY_FOR_ROLE,
  APPLY_FOR_ROLE_FAILED,
  APPLY_FOR_ROLE_SUCCESS,
  APPLY_TO_EXTERNAL_DEED,
  COMPLETE_DEED,
  COMPLETE_DEED_FAILED,
  COMPLETE_DEED_SUCCESS,
  FETCH_DEED,
  FETCH_DEED_FAILED,
  FETCH_DEED_SUCCESS,
  LOAD_VOLUNTEER_TIMES,
  LOAD_VOLUNTEER_TIMES_FAILED,
  LOAD_VOLUNTEER_TIMES_SUCCESS,
  REFRESH,
  REFRESH_FAILED,
  REFRESH_SUCCESS,
  SEND_EMAIL_TO_ATTENDEES,
  SEND_EMAIL_TO_ATTENDEES_FAILED,
  SEND_EMAIL_TO_ATTENDEES_SUCCESS,
  TOGGLE_ATTENDEES_CONTACT_MODAL,
  REMOVE_ATTENDEE,
  REMOVE_ATTENDEE_SUCCESS,
  REMOVE_ATTENDEE_FAILED,
} from './constants'

export interface FetchDeedAction {
  type: typeof FETCH_DEED
  id: string
}
export const fetchDeedAction = (id: string): FetchDeedAction => ({
  type: FETCH_DEED,
  id,
})
export interface FetchDeedSuccessAction {
  type: typeof FETCH_DEED_SUCCESS
  id: string
}
export const fetchDeedSuccessAction = (id: string): FetchDeedSuccessAction => ({
  type: FETCH_DEED_SUCCESS,
  id,
})
export interface FetchDeedFailedAction {
  type: typeof FETCH_DEED_FAILED
  error: object
  errorCode?: string
}
export const fetchDeedFailedAction = (error: object, errorCode?: string): FetchDeedFailedAction => ({
  type: FETCH_DEED_FAILED,
  error,
  errorCode,
})
export interface LoadVolunteerTimesAction {
  type: typeof LOAD_VOLUNTEER_TIMES
  deedId: string
}
export const loadVolunteerTimesAction = (deedId: string): LoadVolunteerTimesAction => ({
  type: LOAD_VOLUNTEER_TIMES,
  deedId,
})
export const loadVolunteerTimesSuccessAction = () => ({
  type: LOAD_VOLUNTEER_TIMES_SUCCESS,
})
export const loadVolunteerTimesFailedAction = (error: object, errorCode?: string) => ({
  type: LOAD_VOLUNTEER_TIMES_FAILED,
  error,
  errorCode,
})
export interface RefreshAction {
  type: typeof REFRESH
}
export const refreshAction = (): RefreshAction => ({
  type: REFRESH,
})

export const refreshSuccessAction = () => ({
  type: REFRESH_SUCCESS,
})

export const refreshFailedAction = (error: string) => ({
  type: REFRESH_FAILED,
  error,
})
export interface ApplyForRoleAction {
  type: typeof APPLY_FOR_ROLE
  deedId: string
  roleId: string
}
export const applyForRoleAction = (deedId: string, roleId: string): ApplyForRoleAction => ({
  type: APPLY_FOR_ROLE,
  deedId,
  roleId,
})

export const applyForRoleSuccessAction = () => ({
  type: APPLY_FOR_ROLE_SUCCESS,
})

export const applyForRoleFailedAction = (error: object) => ({
  type: APPLY_FOR_ROLE_FAILED,
  error,
})
export interface CompleteDeedAction {
  type: typeof COMPLETE_DEED
  id: string
}
export const completeDeedAction = (id: string): CompleteDeedAction => ({
  type: COMPLETE_DEED,
  id,
})

export const completeDeedSuccessAction = () => ({
  type: COMPLETE_DEED_SUCCESS,
})

export const completeDeedFailedAction = (error: object) => ({
  type: COMPLETE_DEED_FAILED,
  error,
})
export interface ApplyToExternalDeedAction {
  type: typeof APPLY_TO_EXTERNAL_DEED
  id: string
}
export const applyToExternalDeedAction = (id: string): ApplyToExternalDeedAction => ({
  type: APPLY_TO_EXTERNAL_DEED,
  id,
})

export const emailSend = (form, deed: Deed) => ({
  type: SEND_EMAIL_TO_ATTENDEES,
  form,
  deed,
})
export interface SendEmailToAttendeesAction {
  type: typeof SEND_EMAIL_TO_ATTENDEES
  deedId: string
  subject: string
  message: string
  fromName: string
  fromEmail: string
  resetFormCallback: () => void
}
export const sendEmailToAttendeesAction = (
  deedId: string,
  subject: string,
  message: string,
  fromName: string,
  fromEmail: string,
  resetFormCallback: () => void
): SendEmailToAttendeesAction => ({
  type: SEND_EMAIL_TO_ATTENDEES,
  deedId,
  subject,
  message,
  fromName,
  fromEmail,
  resetFormCallback,
})

export const sendEmailToAttendeesSuccessAction = () => ({
  type: SEND_EMAIL_TO_ATTENDEES_SUCCESS,
})

export const sendEmailToAttendeesFailedAction = () => ({
  type: SEND_EMAIL_TO_ATTENDEES_FAILED,
})

export const toggleAttendeesContactModal = (toggle: boolean) => ({
  type: TOGGLE_ATTENDEES_CONTACT_MODAL,
  toggle,
})

export interface RemoveAttendeeAction {
  type: typeof REMOVE_ATTENDEE
  deedId: string
  userId: string
}
export const removeAttendee = (deedId: string, userId: string): RemoveAttendeeAction => ({
  type: REMOVE_ATTENDEE,
  deedId,
  userId,
})
export const removeAttendeeSuccessAction = () => ({
  type: REMOVE_ATTENDEE_SUCCESS,
})
export const removeAttendeeFailedAction = () => ({
  type: REMOVE_ATTENDEE_FAILED,
})
