import React from 'react'
import PropTypes from 'prop-types'
import { View, StyleSheet } from 'react-primitives'

import { colors } from 'src/theme'

const Header = ({ children, transparent = true, shadow = true, divider = false }) => (
  <View style={[styles.root, transparent && styles.transparent, shadow && styles.shadow, divider && styles.divider]}>
    <View style={styles.content}>{children}</View>
  </View>
)
Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  transparent: PropTypes.bool,
  shadow: PropTypes.bool,
  divider: PropTypes.bool,
}

const styles = StyleSheet.create({
  root: {
    alignSelf: 'stretch',
    backgroundColor: colors.white,
    zIndex: 10,
  },
  transparent: {
    backgroundColor: 'transparent',
  },
  shadow: {
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 2,
    shadowColor: colors.black,
    elevation: 1,
  },
  content: {
    height: 60,
    paddingLeft: 20,
    paddingRight: 20,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  divider: {
    borderColor: colors.borderGray,
    borderBottomWidth: 1,
  },
})

export default Header
