import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { utcToZonedTime } from 'date-fns-tz'
import { capitalize } from 'lodash'

import { styled } from 'src/theme/styled'
import { EmotionTheme } from 'src/theme/ThemeProvider'
import { useParams } from 'src/navigation'
import { Loading, Modal, Row, Screen, ScrollView, Spacing } from 'src/retired/elements'
import Button from 'src/retired/shared/Button'
import { H4, Text } from 'src/retired/shared/Typography'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { selectDeedById } from 'src/entities/deed/selectors'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { validators } from 'src/utils'
import { useInjectReducer } from 'src/utils/injectReducer'
import { useInjectEpics } from 'src/utils/injectEpics'
import { State } from 'src/reducers'
import { PageTitle } from 'src/components'

import reducer from '../reducer'
import epics from '../epics'
import { initAction, setStepAction, submitDeedAction, deleteDeedAction } from '../actions'
import { selectLoading, selectStep, selectDeleting } from '../selectors'
import SelectNonprofit from '../SelectNonprofit'
import UploadCoverPhoto from '../UploadCoverPhoto'
import Review from '../Review'
import Share from '../Share'

import Details from './Details'
import VolunteerDetails from './VolunteerDetails'

const FormWrapper = styled.View<object, EmotionTheme>`
  padding: 0 ${({ theme }) => (theme.metrics.isLarge ? '180px' : '15px')};
  margin-bottom: 50px;
`
const Title = styled.View<object, EmotionTheme>`
  padding: ${({ theme }) => (theme.metrics.isLarge ? '0 55px 20px 55px' : '0 25px 25px 25px')};
`

const EventForm = (): JSX.Element => {
  useInjectReducer({ key: 'createDeedForm', reducer })
  useInjectEpics({ key: 'createDeedForm', epics })

  const { t } = useTranslation('createDeedScreen')

  const dispatch = useDispatch()

  const { id } = useParams<{ id?: string }>()

  const deed = useSelector((state: State) => (id ? selectDeedById(state, id) : null))
  const loading = useSelector(selectLoading)
  const user = useSelector(selectCurrentUser)
  const step = useSelector(selectStep)
  const deleting = useSelector(selectDeleting)

  const [deleteModalIsOpen, setDeleteModalOpen] = useState(false)

  useEffect(() => {
    if (id) {
      dispatch(initAction(id))
    }

    return () => {
      dispatch(setStepAction(0))
    }
  }, [dispatch, id])

  useEffect(() => {
    if (deed?.status === 'published') {
      dispatch(setStepAction(1))
    }
    if (deed) {
      formik.setValues({
        deedType: 'Event',
        status: deed.status,
        nonprofit: deed.organization || null,
        description: deed.description,
        name: deed.name,
        externalLink: deed.externalLink,
        picture: deed.pictures[0],
        duties: deed.duties,
        mustKnows: deed.mustKnows,
        attendeeLimit: deed.attendeeLimit,
        startingAt: utcToZonedTime(deed.startingAt, deed.timeZone),
        endingAt: utcToZonedTime(deed.endingAt, deed.timeZone),
        location: deed.location,
        locationObject: deed.locationObject,
        locationLatLng: deed.locationLatLng,
        virtual: deed.virtual,
        roles: deed.roles.toJS(),
        donationText: deed.donationText,
        donationLink: deed.donationLink,
        formQuestions: deed.formQuestions,
        timeZone: deed.timeZone,
        organizingCommunityIds: deed.organizingCommunityIds,
        linkOnly: deed.linkOnly,
      })
    }
  }, [deed])

  const initialValues = useMemo(
    () => ({
      deedType: 'Event',
      status: deed?.status || 'draft',
      nonprofit: deed?.organization || null,
      description: deed?.description || '',
      name: deed?.name || '',
      externalLink: deed?.externalLink || '',
      duties: deed?.duties || '',
      mustKnows: deed?.mustKnows || '',
      attendeeLimit: deed?.attendeeLimit || '',
      startingAt: deed?.startingAt ? utcToZonedTime(deed.startingAt, deed?.timeZone) : undefined,
      endingAt: deed?.endingAt ? utcToZonedTime(deed.endingAt, deed?.timeZone) : undefined,
      location: deed?.location || undefined,
      locationObject: deed?.locationObject || undefined,
      locationLatLng: deed?.locationLatLng || undefined,
      virtual: deed?.virtual || false,
      picture: deed?.pictures[0] || '',
      roles: deed?.roles.toJS() || [],
      donationText: deed?.donationText || '',
      donationLink: deed?.donationLink || '',
      formQuestions: deed?.formQuestions || [],
      timeZone: deed?.timeZone || 'America/New_York',
      organizingCommunityIds: deed?.organizingCommunityIds || [],
      linkOnly: false,
    }),
    []
  )

  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const errors = {
        externalLink:
          values.virtual && (validators.notEmpty(values.externalLink) || validators.isURL(values.externalLink)),
        description: validators.notEmpty(values.description),
        name: validators.notEmpty(values.name),
        duties: validators.notEmpty(values.duties),
        mustKnows: validators.notEmpty(values.mustKnows),
        attendeeLimit:
          (validators.notEmpty(values.attendeeLimit) ||
            Number.isNaN(values.attendeeLimit) ||
            Number(values.attendeeLimit) < 1) &&
          t`invalidValue`,
        startingAt: validators.notEmpty(values.startingAt) && t`invalidDate`,
        endingAt: validators.notEmpty(values.endingAt) && t`invalidDate`,
        location: !values.virtual && validators.notEmpty(values.location),
        donationLink: values.donationLink && validators.isURL(values.donationLink),
      }
      const isValid = Object.values(errors).every((value) => !value)
      return !isValid ? errors : {}
    },
    validateOnMount: true,
    onSubmit: (values) => {
      dispatch(submitDeedAction(values, id))
    },
  })

  const stepper = [
    {
      title: t`whoAreYouVolunteeringFor`,
      description: t`selectAnOrganizationToEngage`,
      component: <SelectNonprofit formik={formik} required={false} />,
    },
    {
      title: t`whatAreEventDetails`,
      description: t`provideTheEssentialInformation`,
      component: <Details formik={formik} />,
    },
    {
      title: t`addACoverPhoto`,
      description: t`aHighQualityPhotoToEvent`,
      component: <UploadCoverPhoto formik={formik} userId={user?.id} />,
    },
    {
      title: t`needsMoreDetails`,
      description: t`theseAreOptionaFields`,
      component: <VolunteerDetails formik={formik} />,
    },
    {
      title: t`reviewYourEvent`,
      description: t`reviewYourEventAndPublish`,
      component: <Review formik={formik} />,
    },
    {
      title: t`allSet`,
      description:
        formik.values.status === 'pending'
          ? t`yourEventWillBePublished`
          : formik.values.status === 'draft'
          ? t`yourEventIsSavedAsDraft`
          : t`getPeopleEngagedEvent`,
      component: <Share />,
      disableBackButton: true,
    },
  ]

  return (
    <Screen fixed>
      <PageTitle title={t`createADeed`} />

      <ScrollView keyboardShouldPersistTaps="always">
        {id && (
          <>
            <Modal
              maskClosable
              transparent
              visible={deleteModalIsOpen}
              style={{ width: 500 }}
              onClose={() => setDeleteModalOpen(false)}
            >
              <Text fontSize={12}>{t`ifYouProceedTheEvent`}</Text>
              <Row style={{ justifyContent: 'center', marginTop: 20 }}>
                <Button palette="primary" onPress={() => setDeleteModalOpen(false)} style={{ marginRight: 10 }}>
                  {t`keepMyEvent`}
                </Button>
                <Button
                  palette="primary"
                  onPress={() => dispatch(deleteDeedAction(deed ? deed.id : id))}
                  style={{ marginLeft: 10 }}
                  loading={deleting}
                >
                  {t`deleteEvent`}
                </Button>
              </Row>
            </Modal>
            <Text
              style={{
                position: 'absolute',
                right: 20,
                top: 20,
                zIndex: 100,
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => setDeleteModalOpen(true)}
              fontSize={12}
            >
              {t`deleteEvent`}
            </Text>
          </>
        )}
        {!stepper[step].disableBackButton ? (
          <NavigationHeader
            onGoBack={(deed?.status === 'published' ? step > 1 : !!step) && (() => dispatch(setStepAction(step - 1)))}
            transparent
            title={capitalize(t`createAnEvent`)}
          />
        ) : (
          <Spacing marginTop={60} />
        )}
        <Title>
          <H4 center marginBottom={8}>
            {stepper[step].title}
          </H4>
          <Text fontSize={12} lineHeight={21} center>
            {stepper[step].description}
          </Text>
        </Title>
        <FormWrapper>
          {!user || loading ? (
            <Spacing marginBottom={30} marginTop={30}>
              <Loading fill={false} />
            </Spacing>
          ) : (
            stepper[step].component
          )}
        </FormWrapper>
      </ScrollView>
    </Screen>
  )
}
EventForm.displayName = 'EventForm'
export default EventForm
