import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { List } from 'immutable'

import { eventTypes } from 'src/containers/screens/Deed/components/InfoBlock'
import Deed from 'src/entities/deed/model'
import Role from 'src/entities/deed/role'
import { selectSkills } from 'src/entities/skill/selectors'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { Divider, Row, Spacing } from 'src/retired/elements'
import { Body1, H5 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'

import { getDeedDurationString } from '../utils'

import { ShiftButtons } from './ShiftButtons'
import { ShiftCheckbox } from './ShiftCheckbox'

interface RolesBlockProps {
  deed: Deed
  scrollRef?: React.RefObject<HTMLDivElement>
}

export const RolesBlock = ({ deed, scrollRef }: RolesBlockProps): JSX.Element | null => {
  const { t } = useTranslation('deedScreen')
  const { colors } = useDeedTheme()

  const isProject = deed?.type === 'Project'

  const user = useSelector(selectCurrentUser)
  const skills = useSelector(selectSkills)

  const isMultipleShiftsFeatureEnabledForEvent = user?.hasFeature('multipleShifts') && deed?.type === 'Event'

  if (!deed?.roles?.size) {
    return null
  }

  const userSkills = user?.get('skills') as List<Role>

  return (
    <div ref={scrollRef}>
      <Divider />
      <H5 weight="500" marginBottom={8}>
        {t('roles', { context: !isProject && 'shifts' })}
      </H5>

      {isMultipleShiftsFeatureEnabledForEvent
        ? deed.roles
            .toArray()
            .map((role) => (
              <ShiftCheckbox
                role={role}
                user={user}
                deed={deed}
                hideCheckbox
                style={{ margin: '10px 0px' }}
                isAttending={user && deed.isUserAttending(user, role.id)}
                key={role.get('id')}
              />
            ))
        : deed.roles.toArray().map((role, index) => {
            const spotsAvailableForRole = deed.roleSpotsAvailable(role)
            const spotsAvailableForRoleLength =
              typeof spotsAvailableForRole === 'number' ? spotsAvailableForRole : spotsAvailableForRole.size

            const roleSkills = role.get('skills') as List<string>

            return (
              <View key={role.get('id')} style={{ marginBottom: index === deed.roles.size - 1 ? 25 : 0 }}>
                <Body1>
                  {role.get('name')}{' '}
                  {spotsAvailableForRoleLength > 0 ? (
                    <Body1 colour={colors.brandColor}>
                      {isMultipleShiftsFeatureEnabledForEvent
                        ? t('spotsStillAvailable', {
                            availableSpots: Number(spotsAvailableForRole),
                            totalSpots: role.get('requiredAmount'),
                          })
                        : t('applicantsNeeded', { count: Number(spotsAvailableForRole) })}
                    </Body1>
                  ) : (
                    <Body1 weight="500">{t`allFilledUp`}</Body1>
                  )}
                </Body1>

                {role.get('startingAt') && role.get('endingAt') && (
                  <Body1 style={{ marginTop: 10 }}>
                    {t(
                      role.get('startingAt').getFullYear() === new Date().getFullYear()
                        ? 'date:weekdayDayMonthTimeShort'
                        : 'date:weekdayDayMonthYearTimeShort',
                      {
                        date: { value: role.get('startingAt'), timeZone: deed.timeZone },
                      }
                    )}{' '}
                    –{' '}
                    {t('date:time', {
                      date: { value: role.get('endingAt'), timeZone: deed.timeZone },
                    })}
                  </Body1>
                )}

                {role.get('description') && !_.isEmpty(_.trim(role.get('description'))) ? (
                  <Body1 marginTop={8}>{role.get('description')}</Body1>
                ) : null}

                {deed.type === 'Project' && (
                  <>
                    {roleSkills.size > 0 && (
                      <Spacing marginTop={8}>
                        <Row>
                          <Body1 weight="normal">{t`skillsRequired`} </Body1>

                          {roleSkills.toArray().map((skillId, skillIndex, roleSkillsMap) => {
                            const skill = skills.get(skillId)

                            if (!skill) {
                              return null
                            }

                            const userHasSkill = Boolean(userSkills?.includes(skillId))

                            return (
                              <Body1
                                key={`${role.get('id')}${skillId}`}
                                weight="500"
                                style={userHasSkill && { fontStyle: 'italic' }}
                              >
                                {skill?.name.replace('-', '')}
                                {roleSkillsMap.length - 1 !== skillIndex ? ', ' : ''}
                              </Body1>
                            )
                          })}
                        </Row>
                      </Spacing>
                    )}

                    <Spacing marginTop={8}>
                      <Row>
                        <Body1>{t`availability`} </Body1>
                        <Body1 weight="500">
                          {role.get('estimatedHoursMin')}
                          {role.get('mode') ? (
                            `- ${role.get('estimatedHoursMax')} ${t`hours`} ${role.get('mode')}`
                          ) : (
                            <>
                              {role.get('estimatedHoursMax') !== role.get('estimatedHoursMin')
                                ? `-${role.get('estimatedHoursMax')}`
                                : ''}{' '}
                              {t('hour', { count: role.get('estimatedHoursMax') })} {t`over`}{' '}
                              {deed.duration &&
                                typeof deed.duration === 'object' &&
                                getDeedDurationString({
                                  min: role.get('durationMin'),
                                  max: role.get('durationMax'),
                                  unit: role.get('durationUnit'),
                                })}
                            </>
                          )}
                        </Body1>
                      </Row>
                    </Spacing>
                  </>
                )}

                {user && deed.hasUserAppliedForRole(user.id, role.get('id')) && (
                  <Body1 colour={colors.brandColor} marginTop={10} style={{ fontStyle: 'italic' }}>
                    {t(
                      eventTypes.includes(deed.type)
                        ? 'youveRegistredForThisRole'
                        : deed.isUserAttending(user)
                        ? 'youveBeenApprovedForThisRole'
                        : 'youveAppliedForThisRole',
                      { context: !isProject && 'shifts' }
                    )}
                  </Body1>
                )}
                {index === deed.roles.size - 1 ? null : <Divider />}
              </View>
            )
          })}
      <ShiftButtons deed={deed} user={user} />
    </div>
  )
}
