import React, { useEffect } from 'react'
import { View } from 'react-primitives'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { styled } from 'src/theme/styled'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { useHistory } from 'src/navigation'
import { LinkButton, Row, Screen, ScrollView, Spacing, Tabs } from 'src/retired/elements'
import CreatedDeed from 'src/retired/blocks/CreatedDeed'
import ActivityIndicator from 'src/retired/elements/ActivityIndicator'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { selectUserSubmittedDeedsLoaded, selectUserSubmittedDeeds } from 'src/entities/deed/selectors'
import { Text } from 'src/retired/shared/Typography'
import Header from 'src/retired/shared/Header'
import BackArrowButton from 'src/retired/shared/BackArrowButton'
import { useInjectEpics } from 'src/utils/injectEpics'
import { useInjectReducer } from 'src/utils/injectReducer'
import Deed from 'src/entities/deed/model'

import { initAction } from './types'
import reducer from './reducer'
import epics from './epics'

const DeedsWrapper = styled.View`
  padding-top: 15;
`

const EmptyCreatedDeedSection = ({ t }: { t: ReturnType<typeof useTranslation>['t'] }): JSX.Element => (
  <View style={{ marginTop: 40, marginBottom: 40 }}>
    <Spacing marginBottom={20} style={{ width: '100%' }}>
      <Text fontSize={12} medium>
        {t`helpAnOrganizationYouCareAbout`}
      </Text>
    </Spacing>
    <LinkButton
      to="/create-deed/select-type"
      color="transparent"
      style={{
        borderRadius: 100,
        borderWidth: 1,
        borderStyle: 'solid',
        alignSelf: 'flex-start',
        paddingBottom: 2,
        paddingLeft: 15,
        paddingRight: 15,
        height: 21,
        justifyContent: 'center',
      }}
    >
      <Text fontSize={12} light left>
        {t`createADeed`}
      </Text>
    </LinkButton>
  </View>
)

export const CreatedDeeds = (): JSX.Element => {
  useInjectEpics({ key: 'createdDeeds', epics })
  useInjectReducer({ key: 'createdDeeds', reducer })
  const dispatch = useDispatch()

  const { t } = useTranslation('createdDeedsProfile')
  const { colors, metrics } = useDeedTheme()
  const history = useHistory()

  const userSubmittedDeedsLoaded = useSelector(selectUserSubmittedDeedsLoaded)
  const user = useSelector(selectCurrentUser)
  const pendingCreatedDeeds = useSelector((state) => selectUserSubmittedDeeds(state, user, 'pending'))
  const ongoingCreatedDeeds = useSelector((state) => selectUserSubmittedDeeds(state, user, 'active'))
  const pastCreatedDeeds = useSelector((state) => selectUserSubmittedDeeds(state, user, 'completed'))

  useEffect(() => {
    dispatch(initAction())
  }, [dispatch])

  if (!user?.hasFeature('userCreateEvent') && !user?.hasFeature('userCreateFundraiser')) {
    history.push('/')
  }

  return (
    <Screen>
      <ScrollView>
        <Header>
          <BackArrowButton backTo="/profile" />
          <Row style={{ alignItems: 'center' }}>
            <Row style={{ alignItems: 'center' }}>
              <Text
                fontSize={metrics.isSmall ? 16 : 20}
                lineHeight={metrics.isSmall ? 16 : 20}
                weight="500"
                marginLeft={15}
                numberOfLines={1}
              >
                {t`deedsCreated`}
              </Text>
            </Row>
            <View>
              <LinkButton
                fluid
                size="extraSmall"
                to="/create-deed/select-type"
                style={{ backgroundColor: colors.gold }}
              >
                {t`createADeed`}
              </LinkButton>
            </View>
          </Row>
        </Header>
        <Spacing marginTop={5}>
          <Tabs activeTab={history.location.hash === '#past' ? 2 : history.location.hash === '#ongoing' ? 1 : 0}>
            <Tabs.Tab title={t`pending`}>
              {pendingCreatedDeeds.size > 0 ? (
                <DeedsWrapper>
                  {pendingCreatedDeeds.toArray().map((deed) => (
                    <CreatedDeed key={deed.id} deed={deed} user={user} />
                  ))}
                  {!userSubmittedDeedsLoaded ? <ActivityIndicator /> : null}
                </DeedsWrapper>
              ) : (
                <View>{userSubmittedDeedsLoaded ? <EmptyCreatedDeedSection t={t} /> : <ActivityIndicator />}</View>
              )}
            </Tabs.Tab>
            <Tabs.Tab title={t`active`}>
              {ongoingCreatedDeeds.size > 0 ? (
                <DeedsWrapper>
                  {ongoingCreatedDeeds.toArray().map((deed: Deed) => (
                    <CreatedDeed key={deed.id} deed={deed} user={user} />
                  ))}
                  {!userSubmittedDeedsLoaded ? <ActivityIndicator /> : null}
                </DeedsWrapper>
              ) : (
                <View>{userSubmittedDeedsLoaded ? <EmptyCreatedDeedSection t={t} /> : <ActivityIndicator />}</View>
              )}
            </Tabs.Tab>
            <Tabs.Tab title={t`completed`}>
              {pastCreatedDeeds.size > 0 ? (
                <DeedsWrapper>
                  {pastCreatedDeeds.toArray().map((deed: Deed) => (
                    <CreatedDeed key={deed.id} deed={deed} user={user} />
                  ))}
                  {!userSubmittedDeedsLoaded ? <ActivityIndicator /> : null}
                </DeedsWrapper>
              ) : (
                <View>{userSubmittedDeedsLoaded ? <EmptyCreatedDeedSection t={t} /> : <ActivityIndicator />}</View>
              )}
            </Tabs.Tab>
          </Tabs>
        </Spacing>
      </ScrollView>
    </Screen>
  )
}

export default CreatedDeeds
