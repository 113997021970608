import { format, startOfToday, toDate } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Collapsible } from 'src/components/Collapsible'
import { DatePicker } from 'src/components/DatePicker/DatePicker'
import { CauseMap } from 'src/entities/cause/reducer'
import { Row, ScrollView } from 'src/retired/elements'
import { Body1 } from 'src/retired/shared/Typography'
import { CheckboxItemProps, CheckboxList } from 'src/retired/elements/Checkbox/CheckboxList'
import LocationAutocompleteField from 'src/retired/shared/LocationAutocompleteField'
import addressToLocation from 'src/utils/addressToLocation/addressToLocation'
import { Coordinates } from 'src/utils/coordinates'

import { CauseFilters } from '../../DonateTab/Filters/CauseFilters'
import { ChipItemProps, ToggleableChipList } from '../../common/ToggleableChipList'
import { FilterItemContainer } from '../../common/FilterItemContainer'
import { DistanceSlider } from '../../common/DistanceSlider'

interface DeedsCountByDate {
  [key: string]: number
}

interface VolunteerFiltersProps {
  location: string | false | null
  setLocation: (newLocation?: { coordinates: Coordinates; location: string }) => void
  searchRadius: number
  setSearchRadius: (value: number) => void
  isLocationFilterEnabled?: boolean
  setDeedsOriginList: React.Dispatch<React.SetStateAction<ChipItemProps[]>>
  deedsOriginList: ChipItemProps[]
  setAreFiltersTouched: (value: boolean) => void
  volunteerTypesList: ChipItemProps[]
  setVolunteerTypesList: React.Dispatch<React.SetStateAction<ChipItemProps[]>>
  attendanceOptionsList: ChipItemProps[]
  setAttendanceOptionsList: React.Dispatch<React.SetStateAction<ChipItemProps[]>>
  date: Date | null
  setDate: React.Dispatch<React.SetStateAction<Date | null>>
  deedsCountByDate: DeedsCountByDate
  daysOptionsList: CheckboxItemProps[]
  setDaysOptionsList: React.Dispatch<React.SetStateAction<CheckboxItemProps[]>>
  timeOptionsList: CheckboxItemProps[]
  setTimeOptionsList: React.Dispatch<React.SetStateAction<CheckboxItemProps[]>>
  skillsOptionsList: ChipItemProps[]
  setSkillsOptionsList: React.Dispatch<React.SetStateAction<ChipItemProps[]>>
  currentActiveCauses: CauseMap
  causesOptionsList: ChipItemProps[]
  setCausesOptionsList: React.Dispatch<React.SetStateAction<ChipItemProps[]>>
}

export const VolunteerFilters = ({
  location,
  setLocation,
  searchRadius,
  setSearchRadius,
  isLocationFilterEnabled,
  setDeedsOriginList,
  deedsOriginList,
  setAreFiltersTouched,
  volunteerTypesList,
  setVolunteerTypesList,
  attendanceOptionsList,
  setAttendanceOptionsList,
  date,
  setDate,
  deedsCountByDate,
  daysOptionsList,
  setDaysOptionsList,
  timeOptionsList,
  setTimeOptionsList,
  skillsOptionsList,
  setSkillsOptionsList,
  currentActiveCauses,
  causesOptionsList,
  setCausesOptionsList,
}: VolunteerFiltersProps) => {
  const { t } = useTranslation('searchScreen')
  const { colors } = useDeedTheme()

  const handleLocationSelect = (newAddress: string) => {
    setAreFiltersTouched(true)

    if (!newAddress) {
      setLocation()
    } else {
      addressToLocation(newAddress).subscribe((locationAddress) => {
        if (locationAddress?.location) {
          // @NOTE-CH: the `addressToLocation` function give us coordinates as [Longitude,Latitude], which is "wrong", but since all the deeds are created like that, we can't change it
          // In this case here, since we want to use the info as a Location, then we need normal [Latitude,Longitude] order
          setLocation({
            coordinates: [
              locationAddress.location.coordinates[1],
              locationAddress.location.coordinates[0],
            ] as Coordinates,
            location: newAddress,
          })
        }
      })
    }
  }

  return (
    <ScrollView style={{ paddingHorizontal: 14 }}>
      {isLocationFilterEnabled ? (
        <FilterItemContainer title={t`location`} divider={false} style={{ zIndex: 2 }}>
          <LocationAutocompleteField
            placeholder={t`searchForAFullAddress`}
            fieldStyle={{
              borderRadius: 20,
              border: '1px solid',
              borderColor: colors.gray02,
              padding: 16,
              paddingTop: 12,
              paddingBottom: 12,
            }}
            value={location && location !== 'false' ? location : ''}
            onSelect={(address) => handleLocationSelect(address)}
            onTouched={() => setAreFiltersTouched(true)}
          />
          <Body1 marginTop={20} marginBottom={16}>{t`radius`}</Body1>
          <DistanceSlider
            searchRadius={searchRadius}
            setSearchRadius={(value: number) => {
              setSearchRadius(value)
              setAreFiltersTouched(true)
            }}
          />
        </FilterItemContainer>
      ) : null}

      <FilterItemContainer title={t`type`} divider={isLocationFilterEnabled}>
        <ToggleableChipList
          values={volunteerTypesList}
          setValues={(values) => {
            setVolunteerTypesList(values)
            setAreFiltersTouched(true)
          }}
          direction="vertical"
        />
      </FilterItemContainer>

      <FilterItemContainer title={t`opportunitySource`}>
        <Row>
          <ToggleableChipList
            values={deedsOriginList}
            setValues={(values) => {
              setDeedsOriginList(values)
              setAreFiltersTouched(true)
            }}
            multipleSelection={false}
          />
        </Row>
      </FilterItemContainer>

      <FilterItemContainer title={t`attendanceOptions`}>
        <Row>
          <ToggleableChipList
            values={attendanceOptionsList}
            setValues={(values) => {
              setAttendanceOptionsList(values)
              setAreFiltersTouched(true)
            }}
          />
        </Row>
      </FilterItemContainer>

      <FilterItemContainer title={t`date`}>
        <Row>
          <DatePicker
            placeholder={t`date`}
            value={date ? toDate(date) : null}
            onChange={(value) => {
              setDate(value)
              setAreFiltersTouched(true)
            }}
            minDate={new Date()}
            enableTodayAction={!!deedsCountByDate[format(startOfToday(), 'yyyy/MM/dd')]}
            availableDates={deedsCountByDate}
          />
        </Row>
      </FilterItemContainer>

      <FilterItemContainer>
        <Collapsible title={t`days`}>
          <CheckboxList
            values={daysOptionsList}
            setValues={(values) => {
              setDaysOptionsList(values)
              setAreFiltersTouched(true)
            }}
          />
        </Collapsible>
      </FilterItemContainer>

      <FilterItemContainer>
        <Collapsible title={t`time`}>
          <CheckboxList
            values={timeOptionsList}
            setValues={(values) => {
              setTimeOptionsList(values)
              setAreFiltersTouched(true)
            }}
          />
        </Collapsible>
      </FilterItemContainer>

      <FilterItemContainer title={t`skills`}>
        <ToggleableChipList
          values={skillsOptionsList}
          setValues={(values) => {
            setSkillsOptionsList(values)
            setAreFiltersTouched(true)
          }}
          direction="vertical"
          maxCollapsedItems={5}
        />
      </FilterItemContainer>

      <CauseFilters
        causesOptions={causesOptionsList}
        initCausesOptions={(values) => setCausesOptionsList(values)}
        onChangeValues={(values) => {
          setCausesOptionsList(values)
          setAreFiltersTouched(true)
        }}
        currentActiveCauses={currentActiveCauses}
      />
    </ScrollView>
  )
}
