import { Box, Button, CircularProgress, TextField } from '@mui/material'
import { useFormik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import validator from 'validator'

import { ModalWithCloseButton } from 'src/components/Modal/ModalWithCloseButton'
import Deed from 'src/entities/deed/model'
import User from 'src/entities/user/model'
import { H5 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'

import { sendEmailToAttendeesAction } from '../../actions'
import { selectSendingEmailToAttendees } from '../../selectors'
import { getStartDateFormattedTime } from '../../utils'

interface FormikValues {
  name: string
  email: string
  subject: string
  message: string
}

interface AttendeesContactModalProps {
  deed: Deed
  visible: boolean
  onClose: () => void
  user?: User
}

export const AttendeesContactModal = ({ deed, visible, onClose, user }: AttendeesContactModalProps) => {
  const { t } = useTranslation('deedScreen')
  const { metrics } = useDeedTheme()
  const dispatch = useDispatch()

  const sendingEmailToAttendees = useSelector(selectSendingEmailToAttendees)
  const startDateFormattedTime = getStartDateFormattedTime(deed)

  const handleClose = () => {
    formik.setTouched({})
    formik.setErrors({})
    onClose()
  }

  const initialValues: FormikValues = {
    name: '',
    email: user?.email || '',
    subject: t(`importantInfo`, { deed: deed?.name, date: startDateFormattedTime }),
    message: '',
  }

  const formik = useFormik<FormikValues>({
    initialValues,
    enableReinitialize: true,
    validate: (values) => {
      const errors = {
        name: values.name === '' || !values.name ? t`nameIsRequired` : undefined,
        email:
          values.email === '' || !values.email
            ? t`emailIsRequired`
            : !validator?.isEmail(values.email)
            ? t`enterValidEmail`
            : undefined,
        subject: values.subject === '' || !values.subject ? t`subjectIsRequired` : undefined,
        message: values.message === '' || !values.message ? t`messageIsRequired` : undefined,
      }

      const isFormikValid = Object.values(errors).every((value) => !value)
      return isFormikValid ? {} : errors
    },
    onSubmit: () => {
      const { name, email, subject, message } = formik.values
      if (formik.isValid) {
        dispatch(
          sendEmailToAttendeesAction(deed?.id, subject, message, name, email, () => {
            formik.resetForm()
          })
        )
      }
    },
    validateOnBlur: true,
  })

  return (
    <ModalWithCloseButton visible={visible} onClose={handleClose} title={<H5 weight="500">{t`emailAttendees`}</H5>}>
      <Box
        sx={{
          width: metrics.isSmall ? '100%' : 650,
          pt: 2,
          gap: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            label={t`fromName`}
            name="name"
            fullWidth
            onChange={(e) => formik.setFieldValue('name', e.target.value)}
            onBlur={() => formik.setFieldTouched('name', true)}
            value={formik.values.name}
            helperText={formik.touched?.name && formik.errors?.name}
            error={!!(formik.touched?.name && formik.errors?.name)}
          />
          <TextField
            label={t`fromEmail`}
            name="fromEmail"
            fullWidth
            onChange={(e) => formik.setFieldValue('email', e.target.value)}
            onBlur={() => formik.setFieldTouched('email', true)}
            value={formik.values.email}
            helperText={formik.touched?.email && formik.errors?.email}
            error={!!(formik.touched?.email && formik.errors?.email)}
          />
        </Box>
        <TextField
          label={t`subject`}
          name="subject"
          onChange={(e) => formik.setFieldValue('subject', e.target.value)}
          onBlur={() => formik.setFieldTouched('subject', true)}
          value={formik.values.subject}
          helperText={formik.touched?.subject && formik.errors?.subject}
          error={!!(formik.touched?.subject && formik.errors?.subject)}
        />
        <TextField
          multiline
          rows={4}
          label={t`message`}
          name="message"
          onChange={(e) => formik.setFieldValue('message', e.target.value)}
          onBlur={() => formik.setFieldTouched('message', true)}
          value={formik.values.message}
          helperText={formik.touched?.message && formik.errors?.message}
          error={!!(formik.touched?.message && formik.errors?.message)}
        />
        <Box sx={{ display: 'flex', gap: 1, pt: 2, justifyContent: 'center' }}>
          <Button variant="contained" type="submit" sx={{ width: 120 }} onClick={formik.handleSubmit}>
            {sendingEmailToAttendees ? <CircularProgress color="inherit" size={16} /> : t`send`}
          </Button>
          <Button variant="outlined" sx={{ width: 120 }} onClick={handleClose}>{t`cancel`}</Button>
        </Box>
      </Box>
    </ModalWithCloseButton>
  )
}
