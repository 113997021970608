import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { useTranslation, Trans } from 'react-i18next'
import { View } from 'react-primitives'
import { capitalize } from 'lodash'
import { utcToZonedTime } from 'date-fns-tz'

import { styled } from 'src/theme/styled'
import { EmotionTheme } from 'src/theme/ThemeProvider'
import { Link, useParams } from 'src/navigation'
import { Loading, Screen, ScrollView, Spacing } from 'src/retired/elements'
import { H4, Text } from 'src/retired/shared/Typography'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { selectDeedById } from 'src/entities/deed/selectors'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { validators } from 'src/utils'
import { useInjectReducer } from 'src/utils/injectReducer'
import { useInjectEpics } from 'src/utils/injectEpics'
import { State } from 'src/reducers'
import { PageTitle } from 'src/components'

import reducer from '../reducer'
import epics from '../epics'
import { initAction, setStepAction, submitDeedAction } from '../actions'
import { selectLoading, selectStep } from '../selectors'
import SelectNonprofit from '../SelectNonprofit'
import UploadCoverPhoto from '../UploadCoverPhoto'
import Review from '../Review'
import Share from '../Share'

import SetGoals from './SetGoals'

const FormWrapper = styled.View<object, EmotionTheme>`
  padding: 0 ${({ theme }) => (theme.metrics.isLarge ? '180px' : '15px')};
  margin-bottom: 50px;
`
const Title = styled.View<object, EmotionTheme>`
  padding: ${({ theme }) => (theme.metrics.isLarge ? '0 55px 20px 55px' : '0 25px 25px 25px')};
`

const FundraiserForm = (): JSX.Element => {
  const { t } = useTranslation('createDeedScreen')
  useInjectReducer({ key: 'createDeedForm', reducer })
  useInjectEpics({ key: 'createDeedForm', epics })

  const dispatch = useDispatch()

  const { id } = useParams<{ id?: string }>()

  const deed = useSelector((state: State) => id && selectDeedById(state, id))
  const loading = useSelector(selectLoading)
  const user = useSelector(selectCurrentUser)
  const step = useSelector(selectStep)

  useEffect(() => {
    if (id) {
      dispatch(initAction(id))
    }

    return dispatch(setStepAction(0))
  }, [dispatch, id])

  useEffect(() => {
    if (deed?.status === 'published') {
      dispatch(setStepAction(1))
    }
    if (deed) {
      formik.setValues({
        deedType: 'Campaign',
        status: deed.status,
        nonprofit: deed.nonprofits.first(),
        currency: deed.currencyCode,
        amount: String(deed.goalAmount),
        description: deed.description,
        name: deed.name,
        picture: deed.pictures[0],
        organizingCommunityIds: deed.organizingCommunityIds,
        linkOnly: deed.linkOnly,
        timeZone: deed.timeZone,
        startingAt: utcToZonedTime(deed.startingAt, deed.timeZone),
        endingAt: utcToZonedTime(deed.endingAt, deed.timeZone),
      })
    }
  }, [deed])

  const initialValues = useMemo(
    () => ({
      deedType: 'Campaign',
      status: deed?.status || 'draft',
      nonprofit: deed?.nonprofits.first() || null,
      currency: deed?.currencyCode || (user?.displayCurrency ?? 'USD'),
      amount: deed?.goalAmount || '',
      description: deed?.description || '',
      name: deed?.name || '',
      picture: deed?.pictures[0] || '',
      organizingCommunityIds: deed?.organizingCommunityIds || [],
      linkOnly: deed?.linkOnly || false,
      timeZone: deed?.timeZone || Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'America/New_York',
      startingAt: deed?.startingAt ? utcToZonedTime(deed.startingAt, deed?.timeZone) : undefined,
      endingAt: deed?.endingAt ? utcToZonedTime(deed.endingAt, deed?.timeZone) : undefined,
    }),
    []
  )

  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const errors = {
        amount: validators.notEmpty(values.amount) || validators.isNumber(values.amount),
        description: validators.notEmpty(values.description),
        name: validators.notEmpty(values.name),
      }
      const isValid = Object.values(errors).every((value) => !value)
      return !isValid ? errors : {}
    },
    validateOnMount: true,
    onSubmit: (values) => {
      dispatch(submitDeedAction(values, id))
    },
  })

  const stepper = [
    {
      title: t`whoAreYouFundraisingFor`,
      description: t`selectAnOrganization`,
      component: <SelectNonprofit formik={formik} acceptsDonations />,
    },
    {
      title: t`letsSetYourFundraiser`,
      description: t`enterYourGoal`,
      component: <SetGoals formik={formik} />,
    },
    {
      title: t`addACoverPhoto`,
      description: t`aHighQualityPhoto`,
      component: <UploadCoverPhoto formik={formik} userId={user?.id} />,
    },
    {
      title: t`reviewYourFundraiser`,
      description: t`reviewYourFundraiserPublishAndShare`,
      component: <Review formik={formik} />,
    },
    {
      title: t`allSet`,
      description:
        formik.values.status === 'pending'
          ? `yourFundraiserWillBePublished`
          : formik.values.status === 'draft'
          ? `yourFundraiserIsSavedAsDraft`
          : `getPeopleEngaged`,
      link: '/profile',
      component: <Share />,
      disableBackButton: true,
    },
  ]

  return (
    <Screen fixed>
      <PageTitle title={stepper[step].title} />

      <ScrollView>
        {!stepper[step].disableBackButton ? (
          <NavigationHeader
            onGoBack={(deed?.status === 'published' ? step > 1 : !!step) && (() => dispatch(setStepAction(step - 1)))}
            transparent
            title={capitalize(t`createAFundraiser`)}
          />
        ) : (
          <Spacing marginTop={60} />
        )}
        <Title>
          <H4 center marginBottom={8}>
            {stepper[step].title}
          </H4>
          <View style={{ width: '100%', maxWidth: 506, margin: 'auto' }}>
            <Text fontSize={12} lineHeight={21} center>
              {stepper[step].link ? (
                <Trans t={t} i18nKey={stepper[step].description} components={{ u: <Link to={stepper[step].link} /> }} />
              ) : (
                stepper[step].description
              )}
            </Text>
          </View>
        </Title>
        <FormWrapper>
          {!user || loading ? (
            <Spacing marginBottom={30} marginTop={30}>
              <Loading fill={false} />
            </Spacing>
          ) : (
            stepper[step].component
          )}
        </FormWrapper>
      </ScrollView>
    </Screen>
  )
}
FundraiserForm.displayName = 'FundraiserForm'
export default FundraiserForm
