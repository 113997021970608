import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { H3, Body1 } from 'src/retired/shared/Typography'
import Campaign from 'src/entities/campaign/model'
import { AvatarRow } from 'src/containers/modules/AvatarRow'

import { CampaignStatsCard } from './CampaignStatsCard'

export const SupportersCard = ({ campaign }: { campaign: Campaign }) => {
  const { t } = useTranslation('campaignScreen')
  const { metrics, colors } = useDeedTheme()
  const stats = campaign?.campaignStats

  if (!stats || !stats?.supporters?.length || campaign?.hideActivityFeed) {
    return null
  }

  return (
    <CampaignStatsCard
      primaryElement={
        <Trans
          t={t}
          i18nKey="campaignSupporters"
          count={stats.supportersCount}
          values={{ amount: stats.supportersCount }}
          components={{
            Text: <Body1 style={{ fontSize: 16, color: colors.gray08 }} />,
            BoldText: <H3 />,
          }}
        />
      }
      secondaryElement={
        <AvatarRow
          items={stats.supporters}
          maxImageItems={metrics.isSmall ? 1 : 2}
          withParticipantExtension={false}
          numAllItems={stats.supportersCount}
        />
      }
    />
  )
}
