import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { styled } from 'src/theme/styled'
import { EmotionTheme } from 'src/theme/ThemeProvider'
import { Divider, Loading, Screen, ScrollView, Spacing } from 'src/retired/elements'
import { Body2, H5 } from 'src/retired/shared/Typography'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { useInjectReducer } from 'src/utils/injectReducer'
import { useInjectEpics } from 'src/utils/injectEpics'
import FileUploader from 'src/components/FileUpload/FileUploader'

import { selectReceiptValidating, selectReceiptValidation } from './selectors'
import { validateReceiptAction } from './actions'
import reducer from './reducer'
import epics from './epics'
import Header from './Header'

const Wrapper = styled.View<object, EmotionTheme>`
  padding: 0 ${({ theme }: { theme: EmotionTheme }) => (theme.metrics.isLarge ? '195px' : '15px')};
  margin-bottom: 50px;
`

// @NOTE-RT: Demoware, mounted only in non-production environments
const DemoDonationReceiptForm = (): JSX.Element => {
  useInjectReducer({ key: 'demoDonationReceipt', reducer })
  useInjectEpics({ key: 'demoDonationReceipt', epics })

  const { t } = useTranslation('demoDonationReceipt')
  const dispatch = useDispatch()

  const user = useSelector(selectCurrentUser)
  const receiptValidating = useSelector(selectReceiptValidating)
  const receiptValidation = useSelector(selectReceiptValidation)

  return (
    <Screen fixed>
      <ScrollView>
        <Header />
        <Wrapper>
          {user ? (
            <>
              <H5>{t`uploadYourReceiptLabel`}</H5>
              <FileUploader
                returnFileUrl={(receipt) => {
                  dispatch(validateReceiptAction({ receipt }))
                }}
                objectPrefix={user.id}
              />
            </>
          ) : (
            <Spacing marginBottom={30} marginTop={30}>
              <Loading fill={false} />
            </Spacing>
          )}
          <Divider style={{ paddingLeft: 15, paddingRight: 15 }} />
          <>
            {!user || receiptValidating ? (
              <Spacing marginBottom={30} marginTop={30}>
                <Loading fill={false} />
              </Spacing>
            ) : receiptValidation ? (
              <Spacing marginBottom={30}>
                <Spacing>
                  <H5>
                    {receiptValidation?.getIn?.(['observations', 'receiptIsLikelyAnAcceptableDonation'])
                      ? '✅ This is likely an acceptable donation receipt'
                      : '⚠️ This receipt requires manual review'}
                  </H5>
                  <dl>
                    <dt>
                      {receiptValidation?.getIn?.(['observations', 'receiptIsLikelyADonation']) ? '✅' : '❌'} Receipt
                      is for a donation
                    </dt>
                    <dt>
                      {receiptValidation?.getIn?.(['observations', 'userIsLikelyDonor']) ? '✅' : '❌'} Receipt donor
                      matches the uploading user
                    </dt>
                    <dd></dd>
                    {[
                      receiptValidation?.getIn?.(['nonprofit', 'address', 'countryCode']),
                      receiptValidation?.getIn?.(['donationProcessor', 'address', 'countryCode']),
                    ].includes('US') ? (
                      <dt>
                        {receiptValidation?.getIn?.(['observations', 'hasIrsDisclosures']) ? '✅' : '❌'} Receipt has
                        the mandatory IRS disclosures
                      </dt>
                    ) : (
                      <dt>
                        {receiptValidation?.getIn?.(['observations', 'rewardReceived']) === false ? '✅' : '❌'} Receipt
                        states no quid pro quo/special consideration received for the donation
                      </dt>
                    )}
                    <dt>
                      {receiptValidation?.getIn?.(['observations', 'donationProcessorRecognized']) ? '✅' : '❌'}{' '}
                      Donation made through a recognized processor
                    </dt>
                  </dl>
                </Spacing>
                <Spacing>
                  <H5>
                    {receiptValidation?.getIn?.(['observations', 'receiptIsLikelyADonation']) ? '✅' : '⚠️'} Donation
                  </H5>
                  <dl>
                    <dt>Currency:</dt>
                    <dd>{receiptValidation?.getIn?.(['donation', 'currency']) ?? 'N/A'}</dd>
                    <dt>Amount:</dt>
                    <dd>{receiptValidation?.getIn?.(['donation', 'amount']) ?? 'N/A'}</dd>
                    <dt>Fee amount:</dt>
                    <dd>{receiptValidation?.getIn?.(['donation', 'feeAmount']) ?? 'N/A'}</dd>
                    <dt>Date:</dt>
                    <dd>{receiptValidation?.getIn?.(['donation', 'date']) ?? 'N/A'}</dd>
                    <dt>Identifier:</dt>
                    <dd>{receiptValidation?.getIn?.(['donation', 'identifier']) ?? 'N/A'}</dd>
                    <dt>Nonprofit</dt>
                    <dd>
                      <dl>
                        <dt>Name:</dt>
                        <dd>{receiptValidation?.getIn?.(['nonprofit', 'name']) ?? 'N/A'}</dd>
                        <dt>Tax ID:</dt>
                        <dd>{receiptValidation?.getIn?.(['nonprofit', 'taxId']) ?? 'N/A'}</dd>
                      </dl>
                    </dd>
                  </dl>
                </Spacing>
                <Spacing>
                  <H5>
                    {receiptValidation?.getIn?.(['observations', 'donationProcessorRecognized']) ? '✅' : '⚠️'} Donation
                    processor
                  </H5>
                  <dl>
                    <dt>Name:</dt>
                    <dd>{receiptValidation?.getIn?.(['donationProcessor', 'name']) ?? 'N/A'}</dd>
                  </dl>
                </Spacing>
                <Spacing>
                  <H5>{receiptValidation?.getIn?.(['observations', 'userIsLikelyDonor']) ? '✅' : '⚠️'} Donor</H5>
                  <dl>
                    <dt>First name:</dt>
                    <dd>{receiptValidation?.getIn?.(['donor', 'firstName']) ?? 'N/A'}</dd>
                    <dt>Last name:</dt>
                    <dt>Email:</dt>
                    <dd>{receiptValidation?.getIn?.(['donor', 'email']) ?? 'N/A'}</dd>
                  </dl>
                </Spacing>
              </Spacing>
            ) : (
              <Spacing marginBottom={30} marginTop={30}>
                <Body2 center>{t`uploadADonationReceiptToContinue`}</Body2>
              </Spacing>
            )}
          </>
        </Wrapper>
      </ScrollView>
    </Screen>
  )
}

export default DemoDonationReceiptForm
