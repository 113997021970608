import Api from '../api'
import { ratedDeedAction } from '../user/actions'

export const RatingApi = {
  rate: (type: string, id: string, rating: number, setDeedAsRated?: boolean) =>
    Api.post('api/ratings', {
      documentType: type,
      documentId: id,
      rate: rating,
      skipped: rating === 0,
      voteType: 'rate',
    }).map(() => (setDeedAsRated ? ratedDeedAction() : undefined)),
}

export default RatingApi
