import React from 'react'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'

import { useOpportunityQuery } from 'src/generated/graphql'
import { codeProviderMap, mapOpportunityToDeed } from 'src/utils/mapOpportunityToDeed'
import { selectCurrentUser } from 'src/entities/user/selectors'

import { DeedPresentational } from '../Deed/DeedPresentational'

const Opportunity = () => {
  const { providerId, provider: providerCode } = useParams<{ providerId: string; provider: string }>()
  const provider = codeProviderMap[providerCode]

  const user = useSelector(selectCurrentUser)
  const enabled = user?.hasFeature('autoSourceDeeds') && user?.hasFeature('browsingExternalOpportunities')

  const opportunityQuery = useOpportunityQuery({
    variables: { providerId, provider },
    skip: !enabled,
    fetchPolicy: 'cache-first',
  })
  const opportunity = opportunityQuery.data?.opportunity

  return (
    <DeedPresentational
      deed={opportunity ? mapOpportunityToDeed(opportunity) : undefined}
      user={user}
      loading={opportunityQuery.loading}
      refreshing={false}
      error={opportunityQuery.error}
    />
  )
}

export default Opportunity
