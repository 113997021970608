import i18n from 'i18next'
import { Observable } from 'rxjs'
import { ActionsObservable, ofType } from 'redux-observable'
import { mergeMap, catchError } from 'rxjs/operators'

import { showErrorAction, showMessageAction } from 'src/containers/modules/Alerts/actions'
import RatingApi from 'src/entities/rating/api'
import RelationApi from 'src/entities/relation/api'
import truthy from 'src/utils/truthy'

import {
  SubmitFriendRequestAction,
  submitFriendRequestFailedAction,
  submitFriendRequestSuccessAction,
  SubmitRateAction,
  submitRateFailedAction,
  submitRateSuccessAction,
} from './actions'
import { SUBMIT_FRIEND_REQUEST, SUBMIT_RATE } from './constants'

const submitRate = (action$: ActionsObservable<SubmitRateAction>) =>
  action$.pipe(
    ofType(SUBMIT_RATE),
    mergeMap(({ id, rating, setDeedAsRated }) => {
      const skipped = rating === 0
      const actions = [RatingApi.rate('Deed', id, rating, setDeedAsRated)]

      return Observable.combineLatest(actions).pipe(
        mergeMap((resultingActions) =>
          [
            resultingActions[0],
            !skipped && showMessageAction(i18n.t('deedScreen:thankYou')),
            submitRateSuccessAction(),
          ].filter(truthy)
        ),
        catchError(() =>
          Observable.of(submitRateFailedAction(), !skipped && showErrorAction(i18n.t('deedScreen:ratingFailed')))
        )
      )
    })
  )

const submitFriendRequest = (action$: ActionsObservable<SubmitFriendRequestAction>) =>
  action$.pipe(
    ofType(SUBMIT_FRIEND_REQUEST),
    mergeMap(({ id, friendRequest }) => {
      const actions = [RelationApi.request(friendRequest, id)]
      return Observable.combineLatest(actions).pipe(
        mergeMap((resultingActions) => [
          resultingActions[0],
          showMessageAction(i18n.t('deedScreen:thankYou'), i18n.t('deedScreen:friendRequestSent')),
          submitFriendRequestSuccessAction(friendRequest),
        ]),
        catchError(() =>
          Observable.of(submitFriendRequestFailedAction(), showErrorAction(i18n.t('deedScreen:friendRequestFailed')))
        )
      )
    })
  )

export default [submitRate, submitFriendRequest]
