import { Box, Button, CircularProgress, Divider } from '@mui/material'
import { FormikErrors, useFormik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ModalWithCloseButton } from 'src/components/Modal/ModalWithCloseButton'
import User from 'src/entities/user/model'
import { Body2, H5 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { useInjectEpics, useInjectReducer, validators } from 'src/utils'

import { clearAction, inviteAttendeesAction, searchAction } from './actions'
import { EmailInviteSection } from './EmailInviteSection'
import epics from './epics'
import reducer from './reducer'
import { SelectedUsersList } from './SelectedUsersList'
import { selectError, selectLoading, selectResults, selectSubmitError, selectSubmitting, selectTerm } from './selectors'
import { UserSearchList } from './UserSearchList'

interface FormValues {
  firstName: string
  lastName: string
  email: string
}

interface InviteModalProps {
  user: User | undefined
  close: () => void
  visible: boolean
  deedId: string
}

export const InviteModal = ({ user, close, visible, deedId }: InviteModalProps): JSX.Element => {
  useInjectReducer({ key: 'inviteModal', reducer })
  useInjectEpics({ key: 'inviteModal', epics })

  const dispatch = useDispatch()

  const { metrics } = useDeedTheme()
  const { t } = useTranslation('deedScreen')

  const results = useSelector(selectResults)
  const term = useSelector(selectTerm)
  const loading = useSelector(selectLoading)
  const error = useSelector(selectError)
  const submitting = useSelector(selectSubmitting)
  const submitError = useSelector(selectSubmitError)

  const [selected, setSelected] = useState<Map<string, Pick<User, 'id' | 'fullName' | 'email'>>>(new Map())

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {
        firstName: validators.notEmpty(values.firstName),
        lastName: validators.notEmpty(values.lastName),
        email: validators.notEmpty(values.email) || validators.isEmail(values.email) ? t`enterValidEmail` : undefined,
      }
      const isValid = Object.values(errors).every((value) => !value)
      return !isValid ? errors : {}
    },
    validateOnBlur: true,
    validateOnChange: true,
    isInitialValid: false,
    onSubmit: () => undefined,
  })

  const handleSubmitInvite = () => {
    dispatch(
      inviteAttendeesAction(deedId, selected, () => {
        setSelected(new Map())
      })
    )
  }

  const addOrRemove = (identifier: string, invite: Pick<User, 'id' | 'fullName' | 'email'>) => {
    setSelected((prev) => {
      const newSelected = new Map(prev)
      if (newSelected.has(identifier)) {
        newSelected.delete(identifier)
      } else {
        newSelected.set(identifier, invite)
      }

      return newSelected
    })
  }

  const resetSelected = () => {
    if (selected.size > 0) {
      setSelected(new Map())
    }
  }

  const handleClose = () => {
    close()
    dispatch(clearAction())
    resetSelected()
    formik.resetForm()
  }

  const handleEmailInviteAdd = () => {
    if (formik.dirty && formik.isValid) {
      const newInvite: Pick<User, 'id' | 'fullName' | 'email'> = {
        fullName: { first: formik.values.firstName, last: formik.values.lastName },
        email: formik.values.email,
        id: formik.values.email,
      }

      setSelected((prev) => {
        const updatedSelected = new Map(prev)
        updatedSelected.set(formik.values.email, newInvite)
        return updatedSelected
      })

      formik.resetForm()
    }
  }

  const isUserEmployee = !!user?.isEmployee

  return (
    <ModalWithCloseButton
      style={{ width: metrics.isSmall ? '100%' : metrics.isMedium || metrics.isLarge ? 600 : 'auto' }}
      visible={visible}
      onClose={handleClose}
      title={<H5 weight="500">{t`inviteAttendees`}</H5>}
    >
      <Box sx={{ width: '100%', pt: 2 }}>
        {isUserEmployee && (
          <UserSearchList
            term={term}
            results={results.slice(0, 50).toArray()}
            loading={loading}
            error={error}
            selected={selected}
            onSearch={(value) => dispatch(searchAction(value))}
            onClearSearch={() => dispatch(searchAction(''))}
            onSelect={addOrRemove}
          />
        )}
        {(!term || results.size === 0) && (
          <EmailInviteSection formik={formik} showTitle={!!user?.isEmployee} onClickAdd={handleEmailInviteAdd} />
        )}

        {selected.size > 0 && (
          <>
            {results.size > 0 && <Divider sx={{ mb: 1 }} />}
            <SelectedUsersList selected={selected} onRemove={addOrRemove} onClearAll={resetSelected} />
          </>
        )}
        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', pt: 2, alignItems: 'center' }}>
          {submitError && <Body2>{t`submitError`}</Body2>}
          <Button onClick={handleClose} variant="outlined">
            {t`cancel`}
          </Button>
          <Button onClick={handleSubmitInvite} disabled={selected.size === 0 || submitting} variant="contained">
            {submitting ? <CircularProgress size={14} color="inherit" /> : t`invite`}
          </Button>
        </Box>
      </Box>
    </ModalWithCloseButton>
  )
}
