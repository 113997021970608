import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import type { FormikErrors, FormikHelpers } from 'formik'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'
import { useDispatch, useSelector } from 'react-redux'

import { selectCurrentUser } from 'src/entities/user/selectors'
import locales from 'src/i18n/locales'
import { Prompt } from 'src/navigation'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { Action, ActivityIndicator, Alert, Button, Loading, Screen, ScrollView, Tooltip } from 'src/retired/elements'
import { Body1 } from 'src/retired/shared/Typography'
import { useInjectEpics, useInjectReducer, validators } from 'src/utils'
import { useDeedTheme } from 'src/theme/ThemeProvider'

import * as Actions from './actions'
import { DeleteAccountSection } from './DeleteAccountSection'
import epics from './epics'
import reducer from './reducer'
import { selectLoading, selectSubmitting } from './selectors'

const textFieldStyle = {
  mb: 2,
}

const Selector = ({
  value,
  children,
  error,
  label,
  onChange,
  style,
  disabled,
}: {
  value: string
  children: React.ReactNode
  error?: string
  label: string
  onChange?: (value: any) => void
  style?: React.CSSProperties
  disabled?: boolean
}) => {
  const isError = !!error && error.trim() !== ''
  return (
    <FormControl fullWidth error={isError} style={style} disabled={disabled}>
      <InputLabel
        id={label}
        style={{ backgroundColor: 'white', padding: '0 2px' }}
        sx={{
          top: '-6px',
          '&.MuiInputLabel-shrink': {
            top: '0px',
          },
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId={label}
        value={value}
        onChange={(e) => onChange && onChange(e.target.value)}
        style={{ borderRadius: '20px', height: '40px' }}
      >
        {children}
      </Select>
      {isError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

const FieldGroup = ({ title, children }: { title?: string; children: JSX.Element }) => (
  <View style={{ marginBottom: 24 }}>
    {title ? <Body1 style={{ marginBottom: 16 }}>{title}</Body1> : null}
    {children}
  </View>
)

interface FormValues {
  instagram: string
  fullName: { first: string; last: string }
  preferredName: { first?: string; last?: string; bothNamesRequired?: string }
  email: string
  phone: string
  tShirtSize: string
  birthday: Date | null
  organizationDepartment: string
  locale: string
}

const Settings: React.FC = () => {
  useInjectReducer({ key: 'profileSettings', reducer })
  useInjectEpics({ key: 'profileSettings', epics })
  const dispatch = useDispatch()
  const { t } = useTranslation('settingsProfile')
  const { metrics } = useDeedTheme()
  const user = useSelector(selectCurrentUser)
  const loading = useSelector(selectLoading)
  const submitting = useSelector(selectSubmitting)

  useEffect(() => {
    dispatch(Actions.initAction())
  }, [dispatch])

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      fullName: user?.legalName || { first: '', last: '' },
      preferredName: user?.preferredName || { first: '', last: '' },
      instagram: user?.instagram || '',
      email: user?.email || '',
      phone: user?.phone || '',
      tShirtSize: user?.tShirtSize || '',
      birthday: user?.birthday ? new Date(user.birthday) : null,
      organizationDepartment: user?.organizationDepartment || '',
      locale: user?.locale || 'en-US',
    },
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {}
      if (!values.fullName.first || values.fullName.first.trim() === '') {
        errors.fullName = { ...errors.fullName, first: 'First name is required' }
      }
      if (!values.fullName.last || values.fullName.last.trim() === '') {
        errors.fullName = { ...errors.fullName, last: 'Last name is required' }
      }
      if (Object.keys(errors.fullName || {}).length === 0) {
        delete errors.fullName // Remove if no errors in fullName so that isValid is correct
      }
      if (!!values.preferredName.first?.trim() !== !!values.preferredName.last?.trim()) {
        errors.preferredName = { bothNamesRequired: true }
      } else {
        delete errors.preferredName
      }
      if (!values.email || values.email.trim() === '') {
        errors.email = 'Email is required'
      } else if (validators.isEmail(values.email)) {
        errors.email = 'Invalid email address'
      }
      if (values.phone && validators.isPhone(values.phone)) {
        errors.phone = 'Invalid phone number'
      }
      if (values.instagram && !/^[a-zA-Z0-9._]*$/.test(values.instagram)) {
        errors.instagram = 'Invalid Instagram handle'
      }
      return errors
    },
    onSubmit: (values, { setSubmitting }: FormikHelpers<FormValues>) => {
      if (formik.dirty) {
        if (formik.isValid) {
          dispatch(Actions.submitAction(values))
          setSubmitting(false)
        } else {
          Alert.alert(t`invalidFields`)
        }
      } else {
        Alert.alert(t`confirmLogOut`, null, [
          { text: t`common:Cancel`, style: 'cancel' },
          {
            text: t`common:Yes`,
            style: 'destructive',
            onPress: () => dispatch(Actions.logoutAction()),
          },
        ])
      }
    },
  })

  if (loading || !user) {
    return <Loading />
  }

  const departments = [...(user.organization?.departments ?? [])]
  // Add custom user departments
  if (user.organizationDepartment && departments?.indexOf(user.organizationDepartment) === -1) {
    departments.unshift(user.organizationDepartment)
  }

  const isPreferredNameEnabled = Boolean(user.organization?.hasHrisIntegration || user.sso?.id)

  return (
    <>
      <ScrollView>
        <NavigationHeader title={t`settings`} divider />
        {user && (
          <Screen
            padding
            action
            header
            safeArea={false}
            style={{ width: metrics.isSmall ? '100%' : '70%', alignSelf: 'center', paddingTop: 32 }}
          >
            <Prompt when={formik.dirty} message={t`youHaveUnsavedChanges`} />

            <FieldGroup title={t`general`}>
              <Selector
                label={t`language`}
                onChange={(value) => {
                  formik.setFieldValue('locale', value)
                  formik.setFieldTouched('locale', true)
                }}
                value={formik.values.locale}
                style={{ marginBottom: '16px' }}
              >
                {locales.map((locale) => (
                  <MenuItem key={locale.code} value={locale.code}>
                    {locale.name}
                  </MenuItem>
                ))}
              </Selector>
            </FieldGroup>

            <FieldGroup title={t`personalInformation`}>
              <>
                <TextField
                  label={
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      {t`common:firstName` + t`common:requiredSymbol`}
                      {isPreferredNameEnabled ? <Tooltip>{t`chosenNameMessage`}</Tooltip> : null}
                    </span>
                  }
                  onChange={(e) => formik.setFieldValue('fullName.first', e.target.value)}
                  onBlur={() => formik.setFieldTouched('fullName.first', true)}
                  name="fullName.first"
                  value={formik.values?.fullName?.first}
                  inputProps={{
                    maxLength: 35,
                  }}
                  sx={textFieldStyle}
                  disabled={isPreferredNameEnabled}
                  error={Boolean(formik.touched?.fullName?.first && formik.errors?.fullName?.first)}
                  helperText={formik.touched?.fullName?.first && formik.errors?.fullName?.first}
                />
                <TextField
                  label={t`common:lastName` + t`common:requiredSymbol`}
                  onChange={(e) => formik.setFieldValue('fullName.last', e.target.value)}
                  onBlur={() => formik.setFieldTouched('fullName.last', true)}
                  name="fullName.last"
                  value={formik.values?.fullName?.last}
                  inputProps={{
                    maxLength: 70,
                  }}
                  sx={textFieldStyle}
                  disabled={isPreferredNameEnabled}
                  error={Boolean(formik.touched?.fullName?.last && formik.errors?.fullName?.last)}
                  helperText={formik.touched?.fullName?.last && formik.errors?.fullName?.last}
                />

                {isPreferredNameEnabled ? (
                  <>
                    <TextField
                      label={t`chosenFirstName`}
                      onChange={(e) => formik.setFieldValue('preferredName.first', e.target.value)}
                      onBlur={() => formik.setFieldTouched('preferredName.first', true)}
                      name="preferredName.first"
                      value={formik.values?.preferredName?.first}
                      inputProps={{
                        maxLength: 35,
                      }}
                      sx={textFieldStyle}
                      error={Boolean(
                        (formik.touched?.preferredName?.first || formik.touched?.preferredName?.last) &&
                          formik.errors?.preferredName?.bothNamesRequired &&
                          !formik.values?.preferredName?.first
                      )}
                      helperText={
                        (formik.touched?.preferredName?.first || formik.touched?.preferredName?.last) &&
                        formik.errors?.preferredName?.bothNamesRequired &&
                        !formik.values?.preferredName?.first
                          ? t`bothNamesRequired`
                          : null
                      }
                    />
                    <TextField
                      label={t`chosenLastName`}
                      onChange={(e) => formik.setFieldValue('preferredName.last', e.target.value)}
                      onBlur={() => formik.setFieldTouched('preferredName.last', true)}
                      name="preferredName.last"
                      value={formik.values?.preferredName?.last}
                      inputProps={{
                        maxLength: 70,
                      }}
                      sx={textFieldStyle}
                      helperText={
                        (formik.touched?.preferredName?.first || formik.touched?.preferredName?.last) &&
                        formik.errors?.preferredName?.bothNamesRequired &&
                        !formik.values?.preferredName?.last
                          ? t`bothNamesRequired`
                          : null
                      }
                      error={Boolean(
                        (formik.touched?.preferredName?.first || formik.touched?.preferredName?.last) &&
                          formik.errors?.preferredName?.bothNamesRequired &&
                          !formik.values?.preferredName?.last
                      )}
                    />
                  </>
                ) : null}

                {!user.isEmployee() && (
                  <TextField
                    label={t`instagram`}
                    onChange={(e) => formik.setFieldValue('instagram', e.target.value)}
                    onBlur={() => formik.setFieldTouched('instagram', true)}
                    name="instagram"
                    placeholder={t`yourInstagram`}
                    value={formik.values.instagram}
                    sx={textFieldStyle}
                    inputProps={{
                      maxLength: 30,
                    }}
                    helperText={formik.touched.instagram && formik.errors.instagram}
                    error={Boolean(formik.touched.instagram && formik.errors.instagram)}
                  />
                )}
              </>
            </FieldGroup>

            <FieldGroup title={t`privateInformation`}>
              <>
                <TextField
                  label={t`common:email` + t`common:requiredSymbol`}
                  onChange={(e) => formik.setFieldValue('email', e.target.value)}
                  onBlur={() => formik.setFieldTouched('email', true)}
                  name="email"
                  value={formik.values.email}
                  sx={textFieldStyle}
                  inputProps={{
                    maxLength: 50,
                    inputMode: 'email',
                  }}
                  helperText={formik.touched.email && formik.errors.email}
                  error={Boolean(formik.touched.email && formik.errors.email)}
                />
                <TextField
                  label={t`common:phone` + (!user.isEmployee() ? t`common:requiredSymbol` : '')}
                  onChange={(e) => {
                    if (/^\+?[0-9]*$/.test(e.target.value)) {
                      formik.setFieldValue('phone', e.target.value)
                    }
                  }}
                  onBlur={() => formik.setFieldTouched('phone', true)}
                  name="phone"
                  value={formik.values.phone}
                  sx={textFieldStyle}
                  inputProps={{
                    maxLength: 50,
                    inputMode: 'numeric',
                    pattern: '[0-9+]*',
                  }}
                  helperText={formik.touched.phone && formik.errors.phone}
                  error={Boolean(formik.touched.phone && formik.errors.phone)}
                />
                <Selector
                  label={t`tShirtSize`}
                  onChange={(value) => {
                    formik.setFieldValue('tShirtSize', value)
                    formik.setFieldTouched('tShirtSize', true)
                  }}
                  value={formik.values.tShirtSize}
                  style={{ marginBottom: '16px' }}
                >
                  <MenuItem value="">{t`select`}</MenuItem>
                  <MenuItem value="XS">{t`XS`}</MenuItem>
                  <MenuItem value="S">{t`S`}</MenuItem>
                  <MenuItem value="M">{t`M`}</MenuItem>
                  <MenuItem value="L">{t`L`}</MenuItem>
                  <MenuItem value="XL">{t`XL`}</MenuItem>
                  <MenuItem value="XXL">{t`XXL`}</MenuItem>
                  <MenuItem value="XXXL">{t`XXXL`}</MenuItem>
                </Selector>

                <DatePicker
                  label={t`dateOfBirth`}
                  value={formik.values.birthday ? new Date(formik.values.birthday) : null}
                  onChange={(date) => formik.setFieldValue('birthday', date)}
                  autoFocus={false}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      sx: textFieldStyle,
                    },
                  }}
                  maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 15))} // max 15 years ago from now
                />
              </>
            </FieldGroup>

            {user.organization && user.status === 'accepted' ? (
              <FieldGroup title={t`companyInformation`}>
                <>
                  <TextField label={t`company`} value={user.organization.name} sx={textFieldStyle} disabled />

                  {user.organizationDepartment && !user.organization?.departments?.length ? (
                    <TextField label={t`department`} value={user.organizationDepartment} sx={textFieldStyle} disabled />
                  ) : user.organization?.departments?.length ? (
                    <Selector
                      label={t`department`}
                      onChange={(value: string) => {
                        formik.setFieldValue('organizationDepartment', value)
                        formik.setFieldTouched('organizationDepartment', true)
                      }}
                      value={formik.values.organizationDepartment}
                    >
                      <MenuItem value="">{t`selectDepartment`}</MenuItem>
                      {departments.map((department) => (
                        <MenuItem key={department} value={department}>
                          {department}
                        </MenuItem>
                      ))}
                    </Selector>
                  ) : null}
                </>
              </FieldGroup>
            ) : null}

            <DeleteAccountSection />
          </Screen>
        )}
      </ScrollView>

      <Action>
        <Button
          disabled={formik.dirty && !formik.isValid}
          color={formik.dirty ? 'primary' : 'default'}
          onPress={formik.submitForm}
        >
          {formik.dirty ? submitting ? <ActivityIndicator color="#fff" /> : t`save` : t`logOut`}
        </Button>
      </Action>
    </>
  )
}

export default Settings
