import { createSelector } from 'reselect'

import { initialState } from './reducer'

const selectState = (state) => state.get('inviteModal') || initialState

export const selectResults = createSelector(selectState, (state) => state.get('results'))
export const selectTerm = createSelector(selectState, (state) => state.get('term'))
export const selectLoading = createSelector(selectState, (state) => state.get('loading'))
export const selectError = createSelector(selectState, (state) => state.get('error'))
export const selectSubmitting = createSelector(selectState, (state) => state.get('submitting'))
export const selectSubmitError = createSelector(selectState, (state) => state.get('submitError'))
