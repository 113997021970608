import { createSelector } from 'reselect'

import { DeedRateState, initialState } from './reducer'

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
export const selectState = (state: { deedRate: DeedRateState }): DeedRateState => state.get('deedRate') || initialState

export const selectRateSubmitloading = createSelector(selectState, (state) => state.rateSubmitloading)

export const selectRateSubmitError = createSelector(selectState, (state) => state.rateSubmitError)

export const selectRateSubmitSuccess = createSelector(selectState, (state) => state.rateSubmitSuccess)

export const selectLoadingFriendRequestId = createSelector(selectState, (state) => state.loadingFriendRequestId)

export const selectFriendRequestSubmitError = createSelector(selectState, (state) => state.friendRequestSubmitError)

export const selectFriendRequestSubmitSuccess = createSelector(selectState, (state) => state.friendRequestSubmitSuccess)

export const selectAddedFriends = createSelector(selectState, (state) => state.addedFriends)
