import { fromJS } from 'immutable'
import type { AnyAction } from 'redux'

import type { TypedMap } from 'src/utils/typed-map'

import {
  FETCH_DEED,
  FETCH_DEED_SUCCESS,
  FETCH_DEED_FAILED,
  LOAD_VOLUNTEER_TIMES,
  LOAD_VOLUNTEER_TIMES_SUCCESS,
  LOAD_VOLUNTEER_TIMES_FAILED,
  REFRESH,
  REFRESH_SUCCESS,
  REFRESH_FAILED,
  COMPLETE_DEED,
  COMPLETE_DEED_SUCCESS,
  COMPLETE_DEED_FAILED,
  REMOVE_ATTENDEE,
  REMOVE_ATTENDEE_SUCCESS,
  REMOVE_ATTENDEE_FAILED,
  SEND_EMAIL_TO_ATTENDEES,
  SEND_EMAIL_TO_ATTENDEES_SUCCESS,
  SEND_EMAIL_TO_ATTENDEES_FAILED,
  TOGGLE_ATTENDEES_CONTACT_MODAL,
} from './constants'

interface DeedStateMutable {
  loading: boolean
  volunteerTimesLoading: boolean
  volunteerTimesError: null | Error
  refreshing: boolean
  error: null | Error
  completing: boolean
  removingAttendee: boolean | string
  sendingEmailToAttendees: boolean
  toggleAttendeesContactModal: boolean
}

export type DeedState = TypedMap<DeedStateMutable>

export const initialState = fromJS({
  loading: false,
  volunteerTimesLoading: false,
  volunteerTimesError: null,
  refreshing: false,
  error: null,
  completing: false,
  removingAttendee: false,
  sendingEmailToAttendees: false,
  toggleAttendeesContactModal: false,
}) as DeedState

export default (state: DeedState = initialState, action: AnyAction): DeedState => {
  switch (action.type) {
    case FETCH_DEED:
      return state.merge({ loading: true, error: null })

    case FETCH_DEED_SUCCESS:
      return state.set('loading', false)

    case FETCH_DEED_FAILED:
      return state.merge({ loading: false, error: action.error })

    case LOAD_VOLUNTEER_TIMES:
      return state.merge({ volunteerTimesLoading: true, volunteerTimesError: null })

    case LOAD_VOLUNTEER_TIMES_SUCCESS:
      return state.set('volunteerTimesLoading', false)

    case LOAD_VOLUNTEER_TIMES_FAILED:
      return state.merge({ volunteerTimesLoading: false, volunteerTimesError: action.error })

    case REFRESH:
      return state.merge({ refreshing: true, error: null })

    case REFRESH_SUCCESS:
      return state.set('refreshing', false)

    case REFRESH_FAILED:
      return state.merge({ refreshing: false, error: action.error })

    case COMPLETE_DEED:
      return state.merge({ completing: true })

    case COMPLETE_DEED_SUCCESS:
    case COMPLETE_DEED_FAILED:
      return state.merge({ completing: false })

    case REMOVE_ATTENDEE:
      return state.merge({ removingAttendee: action.userId })

    case REMOVE_ATTENDEE_SUCCESS:
    case REMOVE_ATTENDEE_FAILED:
      return state.merge({ removingAttendee: false })

    case SEND_EMAIL_TO_ATTENDEES:
      return state.merge({ sendingEmailToAttendees: true })

    case SEND_EMAIL_TO_ATTENDEES_SUCCESS:
      return state.merge({ sendingEmailToAttendees: false })

    case SEND_EMAIL_TO_ATTENDEES_FAILED:
      return state.merge({
        sendingEmailToAttendees: false,
      })

    case TOGGLE_ATTENDEES_CONTACT_MODAL:
      return state.merge({ toggleAttendeesContactModal: action.toggle })

    case 'RESET':
      return initialState

    default:
      return state
  }
}
