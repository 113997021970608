import { fromJS } from 'immutable'

import type { TypedMap } from 'src/utils/typed-map'

import { Actions, ReceiptValidation } from './actions'
import { VALIDATE_RECEIPT, VALIDATE_RECEIPT_SUCCESS, VALIDATE_RECEIPT_FAILED } from './constants'

export type DemoDonationReceiptState = TypedMap<{
  receiptValidating: boolean
  receiptValidationError: unknown | null
  receiptValidation: ReceiptValidation | null
}>

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const initialState: DemoDonationReceiptState = fromJS({
  receiptValidating: false,
  receiptValidationError: null,
  receiptValidation: null,
})

export default (state: DemoDonationReceiptState = initialState, action: Actions): DemoDonationReceiptState => {
  switch (action.type) {
    case VALIDATE_RECEIPT:
      return state.merge({ receiptValidation: null, receiptValidating: true, receiptValidationError: null })

    case VALIDATE_RECEIPT_SUCCESS:
      return state.merge({
        receiptValidation: action.receiptValidation,
        receiptValidating: false,
        receiptValidationError: null,
      })

    case VALIDATE_RECEIPT_FAILED:
      return state.merge({
        receiptValidation: null,
        receiptValidating: false,
        receiptValidationError: action.error,
      })

    default:
      return state
  }
}
