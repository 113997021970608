import { Map } from 'immutable'

import Cause from 'src/entities/cause/model'

export const causes = [
  {
    icon: '',
    name: 'Advocacy',
    description: '',
    public: true,
    __t: 'Category',
    _id: '59e7cbc792b6a2fc1ac9e5b6',
    updatedAt: '2017-10-18T21:46:47.717Z',
    createdAt: '2017-10-18T21:46:47.717Z',
    id: '59e7cbc792b6a2fc1ac9e5b6',
    code: 'advocacy',
  },
  {
    name: 'Affordable and Clean Energy',
    description: 'Energy is central to nearly every major challenge and opportunity.',
    public: true,
    _id: '5d94f23ee4955af37f0561b0',
    __t: 'SDG',
    number: 7,
    color: '#fdb713',
    id: '5d94f23ee4955af37f0561b0',
    code: 'affordableandcleanenergy',
  },
  {
    icon: '',
    name: 'Animals',
    description: '',
    public: true,
    __t: 'Category',
    _id: '59e7cbc792b6a2fc1ac9e5b1',
    updatedAt: '2017-10-18T21:46:47.717Z',
    createdAt: '2017-10-18T21:46:47.717Z',
    id: '59e7cbc792b6a2fc1ac9e5b1',
    code: 'animals',
  },
  {
    icon: '',
    name: 'Arts',
    description: '',
    public: true,
    __t: 'Category',
    _id: '59e7cbc792b6a2fc1ac9e5b7',
    updatedAt: '2017-10-18T21:46:47.717Z',
    createdAt: '2017-10-18T21:46:47.717Z',
    id: '59e7cbc792b6a2fc1ac9e5b7',
    code: 'arts',
  },
  {
    icon: '',
    name: 'Children',
    description: '',
    public: true,
    __t: 'Category',
    _id: '59e7cbc792b6a2fc1ac9e5b4',
    updatedAt: '2017-10-18T21:46:47.717Z',
    createdAt: '2017-10-18T21:46:47.717Z',
    id: '59e7cbc792b6a2fc1ac9e5b4',
    code: 'children',
  },
  {
    name: 'Clean Water and Sanitation',
    description: 'Clean, accessible water for all is an essential part of the world we want to live in.',
    public: true,
    _id: '5d94f23ee4955af37f0561af',
    __t: 'SDG',
    number: 6,
    color: '#00aed9',
    id: '5d94f23ee4955af37f0561af',
    code: 'cleanwaterandsanitation',
  },
  {
    name: 'Climate Action',
    description: 'Climate change is a global challenge that affects everyone, everywhere.',
    public: true,
    _id: '5d94f23ee4955af37f0561b6',
    __t: 'SDG',
    number: 13,
    color: '#48773e',
    id: '5d94f23ee4955af37f0561b6',
    code: 'climateaction',
  },
  {
    name: 'Decent Work and Economic Growth',
    description:
      'Sustainable economic growth will require societies to create the conditions that allow people to have quality jobs.',
    public: true,
    _id: '5d94f23ee4955af37f0561b1',
    __t: 'SDG',
    number: 8,
    color: '#8f1838',
    id: '5d94f23ee4955af37f0561b1',
    code: 'decentworkandeconomicgrowth',
  },
  {
    icon: '',
    name: 'Disabilities',
    description: '',
    public: true,
    __t: 'Category',
    _id: '59e7cbc792b6a2fc1ac9e5bc',
    updatedAt: '2017-10-18T21:46:47.717Z',
    createdAt: '2017-10-18T21:46:47.717Z',
    id: '59e7cbc792b6a2fc1ac9e5bc',
    code: 'disabilities',
  },
  {
    icon: '',
    name: 'Disaster Recovery',
    description: 'Support communities to maintain or quickly resume mission-critical functions following a disaster.',
    public: true,
    __t: 'Category',
    _id: '5f2c7483c7e38fa4ad11b276',
    id: '5f2c7483c7e38fa4ad11b276',
    code: 'disasterrecovery',
  },
  {
    icon: '',
    name: 'Education',
    description: '',
    public: true,
    __t: 'Category',
    _id: '59fb3fb27585482ebdaf4cbb',
    updatedAt: '2017-11-02T15:54:47.717Z',
    createdAt: '2017-11-02T15:54:47.717Z',
    id: '59fb3fb27585482ebdaf4cbb',
    code: 'education',
  },
  {
    icon: '',
    name: 'Employment',
    description: '',
    public: true,
    __t: 'Category',
    _id: '59e7cbc792b6a2fc1ac9e5b9',
    updatedAt: '2017-10-18T21:46:47.717Z',
    createdAt: '2017-10-18T21:46:47.717Z',
    id: '59e7cbc792b6a2fc1ac9e5b9',
    code: 'employment',
  },
  {
    icon: '',
    name: 'Breaking Barriers',
    description: '',
    public: true,
    __t: 'Category',
    _id: '59e7cbc792b6a2fc1ac9e5b0',
    updatedAt: '2017-10-18T21:46:47.717Z',
    createdAt: '2017-10-18T21:46:47.717Z',
    id: '59e7cbc792b6a2fc1ac9e5b0',
  },
  {
    icon: '',
    name: 'Environment',
    description: '',
    public: true,
    __t: 'Category',
    _id: '59e7cbc792b6a2fc1ac9e5b2',
    updatedAt: '2017-10-18T21:46:47.717Z',
    createdAt: '2017-10-18T21:46:47.717Z',
    id: '59e7cbc792b6a2fc1ac9e5b2',
    code: 'environment',
  },
  {
    icon: '',
    name: 'Food Access',
    description: '',
    public: true,
    __t: 'Category',
    _id: '5d371263e43af8b54ae4f2ea',
    updatedAt: '2018-06-15T21:54:47.717Z',
    createdAt: '2018-06-15T21:54:47.717Z',
    id: '5d371263e43af8b54ae4f2ea',
    code: 'foodaccess',
  },
  {
    name: 'Gender Equality',
    description:
      'Gender equality is not only a fundamental human right, but a necessary foundation for a peaceful, prosperous and sustainable world.',
    public: true,
    _id: '5d94f23ee4955af37f0561ae',
    __t: 'SDG',
    number: 5,
    color: '#ef402b',
    id: '5d94f23ee4955af37f0561ae',
    code: 'genderequality',
  },
  {
    name: 'Good Health and Well-Being',
    description:
      'Ensuring healthy lives and promoting the well-being for all at all ages is essential to sustainable development.',
    public: true,
    _id: '5d94f23ee4955af37f0561ac',
    __t: 'SDG',
    number: 3,
    color: '#279b48',
    id: '5d94f23ee4955af37f0561ac',
    code: 'goodhealthandwell-being',
  },
  {
    icon: '',
    name: 'Health',
    description: '',
    public: true,
    __t: 'Category',
    _id: '59e7cbc792b6a2fc1ac9e5ba',
    updatedAt: '2017-10-18T21:46:47.717Z',
    createdAt: '2017-10-18T21:46:47.717Z',
    id: '59e7cbc792b6a2fc1ac9e5ba',
    code: 'health',
  },
  {
    icon: '',
    name: 'Homelessness',
    description: '',
    public: true,
    __t: 'Category',
    _id: '59e7cbc792b6a2fc1ac9e5b5',
    updatedAt: '2017-10-18T21:46:47.717Z',
    createdAt: '2017-10-18T21:46:47.717Z',
    id: '59e7cbc792b6a2fc1ac9e5b5',
    code: 'homelessness',
  },
  {
    name: 'Industry, Innovation, and Infrastructure',
    description: 'Investments in infrastructure are crucial to achieving sustainable development.',
    public: true,
    _id: '5d94f23ee4955af37f0561b2',
    __t: 'SDG',
    number: 9,
    color: '#f36d25',
    id: '5d94f23ee4955af37f0561b2',
    code: 'industryinnovationandinfrastructure',
  },
  {
    icon: '',
    name: 'LGBTQ',
    description: '',
    public: true,
    __t: 'Category',
    _id: '59e7cbc792b6a2fc1ac9e5bb',
    updatedAt: '2017-10-18T21:46:47.717Z',
    createdAt: '2017-10-18T21:46:47.717Z',
    id: '59e7cbc792b6a2fc1ac9e5bb',
    code: 'lgbtq',
  },
  {
    name: 'Life Below Water',
    description: 'Careful management of this essential global resource is a key feature of a sustainable future.',
    public: true,
    _id: '5d94f23ee4955af37f0561b7',
    __t: 'SDG',
    number: 14,
    color: '#007dbc',
    id: '5d94f23ee4955af37f0561b7',
    code: 'lifebelowwater',
  },
  {
    name: 'Life On Land',
    description:
      'Sustainably manage forests, combat desertification, halt and reverse land degradation, halt biodiversity loss',
    public: true,
    _id: '5d94f23ee4955af37f0561b8',
    __t: 'SDG',
    number: 15,
    color: '#3eb049',
    id: '5d94f23ee4955af37f0561b8',
    code: 'lifeonland',
  },
  {
    icon: '',
    name: 'Mental Health',
    description: '',
    public: true,
    __t: 'Category',
    _id: '59e7cbc792b6a2fc1ac9e5b8',
    updatedAt: '2017-10-18T21:46:47.717Z',
    createdAt: '2017-10-18T21:46:47.717Z',
    id: '59e7cbc792b6a2fc1ac9e5b8',
    code: 'mentalhealth',
  },
  {
    icon: '',
    name: 'Mentoring',
    description: '',
    public: true,
    __t: 'Category',
    _id: '59fb3fa37585482ebdaf4cba',
    updatedAt: '2017-11-02T15:54:47.717Z',
    createdAt: '2017-11-02T15:54:47.717Z',
    id: '59fb3fa37585482ebdaf4cba',
    code: 'mentoring',
  },
  {
    name: 'No Poverty',
    description: 'Economic growth must be inclusive to provide sustainable jobs and promote equality.',
    public: true,
    _id: '5d94f23ee4955af37f0561aa',
    __t: 'SDG',
    number: 1,
    color: '#eb1c2d',
    id: '5d94f23ee4955af37f0561aa',
    code: null, // assuming missing code in mapping; replace with actual code if needed
  },
  {
    name: 'Partnerships',
    description: 'Revitalize the global partnership for sustainable development.',
    public: true,
    _id: '5d94f23ee4955af37f0561ba',
    __t: 'SDG',
    number: 17,
    color: '#183668',
    id: '5d94f23ee4955af37f0561ba',
    code: 'partnerships',
  },
  {
    name: 'Peace, Justice and Strong Institutions',
    description: 'Access to justice for all, and building effective, accountable institutions at all levels.',
    public: true,
    _id: '5d94f23ee4955af37f0561b9',
    __t: 'SDG',
    number: 16,
    color: '#02558b',
    id: '5d94f23ee4955af37f0561b9',
    code: 'peacejusticeandstronginstitutions',
  },
  {
    icon: '',
    name: 'Politics',
    description: '',
    public: true,
    __t: 'Category',
    _id: '59e7cbc792b6a2fc1ac9e5bd',
    updatedAt: '2017-10-18T21:46:47.717Z',
    createdAt: '2017-10-18T21:46:47.717Z',
    id: '59e7cbc792b6a2fc1ac9e5bd',
    code: 'politics',
  },
  {
    name: 'Quality Education',
    description:
      'Obtaining a quality education is the foundation to improving people’s lives and sustainable development.',
    public: true,
    _id: '5d94f23ee4955af37f0561ad',
    __t: 'SDG',
    number: 4,
    color: '#c31f33',
    id: '5d94f23ee4955af37f0561ad',
    code: 'qualityeducation',
  },
  {
    name: 'Reduced Inequalities',
    description:
      'To reduce inequalities, policies should be universal in principle, paying attention to the needs of disadvantaged and marginalized populations.',
    public: true,
    _id: '5d94f23ee4955af37f0561b3',
    __t: 'SDG',
    number: 10,
    color: '#e11484',
    id: '5d94f23ee4955af37f0561b3',
    code: 'reducedinequalities',
  },
  {
    name: 'Responsible Consumption and Production',
    description: 'Ensure sustainable consumption and production patterns.',
    public: true,
    _id: '5d94f23ee4955af37f0561b5',
    __t: 'SDG',
    number: 12,
    color: '#cf8d2a',
    id: '5d94f23ee4955af37f0561b5',
    code: 'responsibleconsumptionandproduction',
  },
  {
    icon: '',
    name: 'Seniors',
    description: '',
    public: true,
    __t: 'Category',
    _id: '59e7cbc792b6a2fc1ac9e5b3',
    updatedAt: '2017-10-18T21:46:47.717Z',
    createdAt: '2017-10-18T21:46:47.717Z',
    id: '59e7cbc792b6a2fc1ac9e5b3',
    code: 'seniors',
  },
  {
    icon: '',
    name: 'Sports & Recreation',
    description: '',
    public: true,
    __t: 'Category',
    _id: '59e7cbc792b6a2fc1ac9e5be',
    updatedAt: '2017-10-18T21:46:47.717Z',
    createdAt: '2017-10-18T21:46:47.717Z',
    id: '59e7cbc792b6a2fc1ac9e5be',
    code: 'sportsandrecreation',
  },
  {
    icon: '',
    name: 'Sustainability',
    description: '',
    public: true,
    __t: 'Category',
    _id: '5d371256e43af8b54ae4f2e9',
    updatedAt: '2018-06-15T21:54:47.717Z',
    createdAt: '2018-06-15T21:54:47.717Z',
    id: '5d371256e43af8b54ae4f2e9',
    code: 'sustainability',
  },
  {
    name: 'Sustainable Cities and Communities',
    description:
      'There needs to be a future in which cities provide opportunities for all, with access to basic services, energy, housing, transportation and more.',
    public: true,
    _id: '5d94f23ee4955af37f0561b4',
    __t: 'SDG',
    number: 11,
    color: '#f99d26',
    picture: 'https://files.godeed.today/SDG/low/11.png',
    id: '5d94f23ee4955af37f0561b4',
    code: 'sustainablecitiesandcommunities',
  },
  {
    icon: '',
    name: 'Technology',
    description: 'Provide technology and training to communities who have traditionally not had access.',
    public: true,
    __t: 'Category',
    _id: '603e4bc22047543a409cf9a9',
    id: '603e4bc22047543a409cf9a9',
  },
  {
    icon: '',
    name: 'Veterans',
    description: '',
    public: true,
    __t: 'Category',
    _id: '59e7cbc792b6a2fc1ac9e5c0',
    updatedAt: '2017-10-18T21:46:47.717Z',
    createdAt: '2017-10-18T21:46:47.717Z',
    id: '59e7cbc792b6a2fc1ac9e5c0',
    code: 'veterans',
  },
  {
    icon: '',
    name: 'Women',
    description: '',
    public: true,
    __t: 'Category',
    _id: '59e7cbc792b6a2fc1ac9e5bf',
    updatedAt: '2017-10-18T21:46:47.717Z',
    createdAt: '2017-10-18T21:46:47.717Z',
    id: '59e7cbc792b6a2fc1ac9e5bf',
    code: 'women',
  },
  {
    icon: '',
    name: 'Youth',
    description: '',
    public: true,
    __t: 'Category',
    _id: '5b243424e61d8e3980c0f39b',
    updatedAt: '2018-06-15T21:54:47.717Z',
    createdAt: '2018-06-15T21:54:47.717Z',
    id: '5b243424e61d8e3980c0f39b',
    code: 'youth',
  },
  {
    name: 'Zero Hunger',
    description:
      'The food and agriculture sector offers key solutions for development, and is central for hunger and poverty eradication.',
    public: true,
    _id: '5d94f23ee4955af37f0561ab',
    __t: 'SDG',
    number: 2,
    color: '#d3a029',
    id: '5d94f23ee4955af37f0561ab',
    code: 'zerohunger',
  },
]

export default Map(causes.map((cause) => [cause.id, new Cause(cause)]))
