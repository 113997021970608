import { withFormik } from 'formik'
import { parse } from 'query-string'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'
import { connect, useDispatch, useSelector } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import { PageTitle } from 'src/components'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { selectIsAuthenticated } from 'src/entities/auth/selectors'
import { selectDeedById } from 'src/entities/deed/selectors'
import { selectDonationById } from 'src/entities/donation/selectors'
import { selectFormById } from 'src/entities/form/selectors'
import { selectVolunteerTimeById } from 'src/entities/volunteerTime/selectors'
import { selectCurrentUser, selectUserBrand } from 'src/entities/user/selectors'
import { setLocalSettingAction } from 'src/localSettings/actions'
import { Redirect, useLocation } from 'src/navigation'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import {
  Action,
  ActivityIndicator,
  Button,
  ExternalLink,
  FluidImage,
  Form,
  Loading,
  Screen,
  ScrollView,
} from 'src/retired/elements'
import { Body1, H3 } from 'src/retired/shared/Typography'
import { Platform, injectEpics, injectReducer } from 'src/utils'
import truthy from 'src/utils/truthy'
import { resetFormsAction } from 'src/entities/form/actions'
import { apolloClient } from 'src/entities/graphql'
import { useVolunteerRewardsQuery } from 'src/generated/graphql'
import { QuickRedirectToLogin } from 'src/navigation/QuickRedirectToLogin'
import { RenderHtml } from 'src/components/RenderHtml'

import { VolunteerRewardInfo } from './VolunteerReward/VolunteerRewardInfo'
import Question from './Question'
import Section from './Section'
import * as Actions from './actions'
import epics from './epics'
import reducer from './reducer'
import { selectAreResponsesValid, selectError, selectLoading, selectSubmitSuccess, selectSubmitting } from './selectors'
import FormFilledScreen from './FormFilledScreen'
import { VolunteerRewardEligibility } from './VolunteerReward/VolunteerRewardEligibility'
import MissingVolunteerTimeScreen from './VolunteerReward/MissingVolunteerTimeScreen'

const FormLoading = ({ formName }: { formName: string }) => (
  <Screen>
    <NavigationHeader />
    <PageTitle title={formName} />
    <div>
      <Loading />
    </div>
  </Screen>
)

const handleSubmit = (values, props, formStatus, skippedQuestions) => {
  const { actions, deed, form, volunteerTime, standalone, user, setSubmitting } = props
  const formId = form?.id || 'formQuestions'
  const volunteerTimeId = volunteerTime?.id

  if (values) {
    const data = Object.keys(values)
      .map((questionId) => {
        const isSection = form.questions.find((q) => q.id === questionId && q.questionType === 'Section')
        const isQuestionSkipped = skippedQuestions.includes(questionId)
        return isSection || isQuestionSkipped
          ? null
          : {
              question: questionId,
              value: values[questionId],
            }
      })
      .filter(truthy)
    actions.submitAction(deed, formId, data, user, formStatus, volunteerTimeId, standalone)
  }
  setSubmitting(false)
}

const getFirstForm = (deed) => {
  if (deed) {
    if (deed.formQuestions?.length && deed.formQuestionsStatus !== 'submitted') {
      // We don't support saving drafts for deed.formQuestions, so we set a fake form id to hide the "Save as draft" button.
      return { questions: deed.formQuestions, id: 'formQuestions' }
    }
    if (deed.forms?.length) {
      return deed.forms.find((form) => form.formStatus !== 'submitted')
    }
  }
}

// Visited section history, needed to make the back button work
// It doesn't have to be a React state since it doesn't have to trigger re-renders
const sectionHistory = []

export const DeedForms = (props) => {
  const {
    standalone,
    loading,
    match,
    user,
    deed,
    deedId,
    form,
    formId,
    volunteerTime,
    volunteerTimeId,
    submitting,
    submitSuccess,
    values,
    touched,
    setFieldValue,
    setFieldTouched,
    actions,
    isVolunteerRewardVolunteerHoursFlow,
    areResponsesValid,
  } = props

  const location = useLocation()
  const dispatch = useDispatch()
  const { t } = useTranslation('formsScreen')
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const brand = useSelector(selectUserBrand) || deed?.partner
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const isVolunteerRewardEnabled = user?.hasFeature('volunteerReward')

  useEffect(() => {
    if ((formId && !form) || (deedId && !deed) || (volunteerTimeId && !volunteerTime)) {
      // these conditions are to not show loader when there is nothing to fetch
      actions.initAction({ deedId, formId, volunteerTimeId, standalone })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId, form])

  useEffect(() => {
    if (!submitting) {
      setCurrentPageIndex(0)
    }
  }, [submitting])

  useEffect(
    () => () => {
      actions.resetAreResponsesValidAction()
      dispatch(resetFormsAction())
    },
    []
  )

  const { data: volunteerRewardData, loading: volunteerRewardDataLoading } = useVolunteerRewardsQuery({
    skip: Boolean(!user || !volunteerTimeId || !isVolunteerRewardEnabled),
    variables: {
      take: 1,
      where: {
        user: { equals: user?.id },
        volunteerTime: { equals: volunteerTimeId },
      },
      skipVolunteerTimeStatus: true,
    },
  })

  if (loading || volunteerRewardDataLoading) {
    return <FormLoading formName={form?.name} />
  }

  const volunteerReward = volunteerRewardData?.volunteerRewards?.items[0]

  if (volunteerReward?.amount === 0) {
    return <Redirect to="/profile" />
  }

  if (volunteerReward?.budgetingData?.isVolunteerRewardAmountInPersonalBudget === false) {
    return <Redirect to={`/profile/volunteer-hours/success/${volunteerTimeId}`} />
  }

  const oldValidationForms = user?.organization?.settings?.volunteerReward?.oldValidationForms
  const volunteerRewardValidationForm = user?.organization?.settings?.volunteerReward?.validationForm

  if (
    oldValidationForms?.length &&
    oldValidationForms.includes(formId) &&
    volunteerRewardValidationForm &&
    volunteerRewardValidationForm !== formId
  ) {
    dispatch(resetFormsAction())
    // Needed for backwards compatibility when volunteer reward validation forms change
    // It can happen that users click on an email link with an old volunteer reward form, but they should always see the most recent one
    return <Redirect to={`/forms/${volunteerRewardValidationForm}?volunteerTime=${volunteerTimeId}`} />
  }

  if (!standalone && (!deed || !user) && !isVolunteerRewardVolunteerHoursFlow) {
    return <Redirect to={`/deeds/${match.params.deed}`} />
  }
  if (!standalone && !form && !isVolunteerRewardVolunteerHoursFlow) {
    return <Redirect to={`/deeds/${match.params.deed}/confirm`} />
  }

  if (!form) {
    return <FormLoading />
  }

  const volunteerRewardValidationFormOrFlow =
    form.isVolunteerRewardValidationForm || isVolunteerRewardVolunteerHoursFlow
  // form.isVolunteerRewardValidationForm should never have a different value than isVolunteerRewardVolunteerHoursFlow and its only for extra safety

  if (form?.isVolunteerRewardValidationForm && !volunteerTime) {
    return <MissingVolunteerTimeScreen />
  }

  if (
    form &&
    volunteerRewardValidationFormOrFlow &&
    submitSuccess &&
    (areResponsesValid === true || areResponsesValid === false) // if areResponsesValid undefined it means form is not submitted yet so we need to check if it's true or false.
  ) {
    apolloClient.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'volunteerTimes({"where":{"userId":{"equals":"me"}}})',
    })
    return <VolunteerRewardEligibility areResponsesValid={areResponsesValid} volunteerTimeId={volunteerTimeId} />
  }

  if (form && volunteerRewardValidationFormOrFlow && form.hasAnswersForThisVolunteerTime) {
    return <FormFilledScreen />
  }

  if (standalone && submitSuccess) {
    return (
      <Screen>
        <NavigationHeader backTo={deed && `/deeds/${deed.id}`} brand={brand} title={form.name} />
        <Screen padding header>
          <View>
            <Body1 style={{ marginTop: 30, marginBottom: 25, textAlign: 'center' }}>
              <RenderHtml source={{ html: form.confirmationText || t`yourResponseHasBeenRecorded` }} />
            </Body1>
          </View>
        </Screen>
      </Screen>
    )
  }

  if (form.requireLogin && !isAuthenticated) {
    const previousLocation = `${location.pathname}${location.search}`
    dispatch(setLocalSettingAction('previousLocation', previousLocation))
    if (window?.sessionStorage) {
      window.sessionStorage.setItem('previousLocation', previousLocation)
    }
    return <QuickRedirectToLogin />
  }

  // Divide the form into pages according to questions and sections to manage navigation easier
  const pages = [] // example: pages =[{ sectionInfo : null, questions: [] }, { sectionInfo : {}, questions: [] }]
  if (form?.questions) {
    const formQuestions = form.questions
    const formQuestionsLength = formQuestions.length
    let pausedIndex = 0

    while (pausedIndex < formQuestionsLength) {
      let sectionInfo
      const pageQuestions = []

      for (let i = pausedIndex; i < formQuestionsLength; i += 1) {
        if (i === formQuestionsLength - 1) {
          pausedIndex = formQuestionsLength
        }

        if (formQuestions[i].questionType === 'Section') {
          if (pageQuestions.length === 0) {
            sectionInfo = formQuestions[i]
          } else {
            pausedIndex = i
            break
          }
        } else {
          pageQuestions.push(formQuestions[i])
        }
      }
      pages.push({ sectionInfo, pageQuestions })
    }
  }

  const currentSection = pages[currentPageIndex]?.sectionInfo
  const sectionBasedNextSection =
    currentSection?.typeConfiguration?.nextSection && currentSection.typeConfiguration.nextSection !== '-'
      ? currentSection.typeConfiguration.nextSection
      : null

  const sectionQuestions = pages[currentPageIndex]?.pageQuestions
  const optionBasedNextSection = (sectionQuestions ?? [])
    .map((q) => q.typeOptions.find((o) => o.title === values[q._id])?.nextSection)
    .filter((nextSection) => nextSection && nextSection !== '-')[0]

  const isFinalPage =
    sectionBasedNextSection === 'SUBMIT' || optionBasedNextSection === 'SUBMIT' || currentPageIndex === pages.length - 1

  const showBackButton = currentPageIndex > 0 && pages.length > 1
  const hideSaveAsDraftButton = form.id === 'formQuestions' || standalone || isVolunteerRewardVolunteerHoursFlow
  const currentPage = pages[currentPageIndex]

  const pageErrors = {} // Page validation is required to move to the next page or submit
  const formErrors = {} // to check if user can save without being in the final page because rest of the form might already be filled when they submitted before.

  for (let i = 0; i < pages.length; i += 1) {
    const isPageSkipped = i < currentPageIndex && !sectionHistory.includes(i)
    if (!isPageSkipped || currentPageIndex === i) {
      pages[i].pageQuestions.forEach((pageQuestion) => {
        const key = Object.keys(values).find((key) => key === pageQuestion._id)
        const value = values[key]
        if (
          pageQuestion?.isRequired &&
          ((pageQuestion.questionType !== 'NPSScale' && (!value || !value?.length) && value !== 0) ||
            (pageQuestion.questionType === 'NPSScale' && !(value || value === 0)))
        ) {
          if (currentPageIndex === i) {
            pageErrors[pageQuestion._id] = t`thisQuestionIsRequired`
          }
          formErrors[pageQuestion._id] = t`thisQuestionIsRequired`
        }
      })
    }
  }
  const hasFormErrors = Object.keys(formErrors).length
  const hasPageErrors = Object.keys(pageErrors).length
  const hasValues = Object.values(values).filter(truthy).length

  const isFormSubmittable =
    ((sectionBasedNextSection === 'SUBMIT' || optionBasedNextSection === 'SUBMIT') && hasValues && !hasPageErrors) ||
    (hasValues && !hasFormErrors)

  const formStatus = isFormSubmittable ? 'submitted' : 'draft'

  const handleSubmitButton = () => {
    const skippedQuestions = []
    if (isFormSubmittable) {
      for (let i = 0; i < pages.length; i += 1) {
        const isPageSkipped = i !== currentPageIndex && !sectionHistory.includes(i)
        if (isPageSkipped) {
          pages[i].pageQuestions.forEach((pageQuestion) => {
            const key = Object.keys(values).find((key) => key === pageQuestion._id)
            const value = values[key]
            if (value || value?.length || value === 0) {
              skippedQuestions.push(key)
            }
          })
        }
      }
    }
    handleSubmit(values, props, formStatus, skippedQuestions)
  }

  const handleBack = () => {
    if (sectionHistory.length > 0) {
      setCurrentPageIndex(sectionHistory.pop())
    } else {
      setCurrentPageIndex(0)
    }
  }

  const handleNext = () => {
    const desiredSectionId =
      // Find the first question in the current section with a selected option that has "nextSection"
      optionBasedNextSection ||
      // Or look for next section based on Section.nextSection
      sectionBasedNextSection
    // Store previous section in section history
    sectionHistory.push(currentPageIndex)
    setCurrentPageIndex(
      desiredSectionId ? pages.findIndex((page) => page.sectionInfo?._id === desiredSectionId) : currentPageIndex + 1
    )
  }

  return (
    <Screen>
      <PageTitle title={form.name} />
      <Form onSubmit={handleSubmitButton} style={{ height: '100%', paddingBottom: 150 }}>
        <ScrollView style={{ maxWidth: 800, width: '100%', marginLeft: 'auto', marginRight: 'auto', height: '100%' }}>
          <View style={{ display: Platform.OS === 'web' ? 'block' : 'flex' }}>
            <NavigationHeader backTo={deed && `/deeds/${deed.id}`} brand={brand} title={form.name} />
            <Screen padding header action>
              {!form.isSurvey && !standalone && (
                <H3 style={{ marginTop: 30, marginBottom: 25 }}>{t`pleaseFillOutTheForm`}</H3>
              )}
              {form.document ? (
                <ExternalLink href={form.document} style={{ flexShrink: 0 }}>
                  <FluidImage source={{ uri: form.document }} />
                </ExternalLink>
              ) : null}
              {volunteerRewardValidationFormOrFlow && volunteerTime && user ? (
                <VolunteerRewardInfo deed={deed} volunteerTime={volunteerTime} user={user} />
              ) : null}
              {currentPage?.sectionInfo ? (
                <Section key={currentPage.sectionInfo._id} question={currentPage.sectionInfo} />
              ) : null}
              {(currentPage?.pageQuestions ?? []).map((question) => (
                <Question
                  key={question._id}
                  question={question}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  values={values}
                  errors={formErrors}
                  touched={touched}
                  user={user}
                />
              ))}
            </Screen>
          </View>
        </ScrollView>
      </Form>
      <Action>
        <View style={{ flexDirection: 'row' }}>
          {showBackButton ? (
            <Button color="gray" fluid size="small" onPress={handleBack} style={{ marginRight: 16 }}>
              {t`back`}
            </Button>
          ) : null}
          {!isFinalPage ? (
            <Button
              disabled={hasPageErrors}
              color={hasPageErrors ? 'light' : 'primary'}
              fluid
              size="small"
              onPress={handleNext}
              style={{ marginRight: 16 }}
            >
              {t`common:Next`}
            </Button>
          ) : null}

          {formStatus === 'draft' && !hideSaveAsDraftButton ? (
            <Button disabled={!hasValues} color="primary" size="small" fluid onPress={handleSubmitButton}>
              {submitting ? <ActivityIndicator color="#fff" /> : t`saveAsDraft`}
            </Button>
          ) : null}

          {isFinalPage && !(formStatus === 'draft' && !hideSaveAsDraftButton) ? (
            <Button disabled={formStatus === 'draft'} color="primary" size="small" fluid onPress={handleSubmitButton}>
              {submitting ? <ActivityIndicator color="#fff" /> : t`common:Submit`}
            </Button>
          ) : null}
        </View>
      </Action>
    </Screen>
  )
}

const withConnect = connect(
  (state, props) => {
    const deedId = props.match.params.deed || parse(props.location.search).deed
    const formId = props.match.params.form
    const donationId = props.match.params.donation
    const volunteerTimeId = parse(props.location.search).volunteerTime || props.match.params.volunteerTimeId

    if (!formId && !deedId) {
      throw new Error('Invalid component usage, either `deed` or `form` must be in the route params')
    }
    const standalone = Boolean(props.standalone)
    const isVolunteerRewardVolunteerHoursFlow = Boolean(props.volunteerRewardVolunteerHoursFlow)

    const volunteerTime = selectVolunteerTimeById(state, volunteerTimeId)
    const deed = selectDeedById(state, deedId)
    const donation = selectDonationById(state, donationId)
    const user = selectCurrentUser(state) ?? (donation ? donation.user : null)
    const form = standalone || isVolunteerRewardVolunteerHoursFlow ? selectFormById(state, formId) : getFirstForm(deed)
    const areResponsesValid = selectAreResponsesValid(state)

    return {
      standalone,
      user,
      deed,
      deedId,
      form,
      formId,
      volunteerTime,
      volunteerTimeId,
      loading: selectLoading(state),
      submitting: selectSubmitting(state),
      submitSuccess: selectSubmitSuccess(state),
      error: selectError(state),
      isVolunteerRewardVolunteerHoursFlow,
      areResponsesValid,
    }
  },
  (dispatch) => ({
    actions: bindActionCreators({ ...Actions, showErrorAction }, dispatch),
  })
)
const withReducer = injectReducer({ key: 'deedForms', reducer })
const withEpics = injectEpics({ key: 'deedForms', epics })
const withForm = withFormik({
  enableReinitialize: true,
  validateOnBlur: true,
  validateOnChange: true,
  mapPropsToValues: ({ form, user }) => {
    const values = {}
    if (form) {
      form.questions.forEach((question) => {
        let defaultValue = ''
        if (user) {
          if (question.title === 'First Name') {
            defaultValue = user.get('fullName')?.first
          }
          if (question.title === 'Last Name') {
            defaultValue = user.get('fullName')?.last
          }
          if (question.title === 'Email') {
            defaultValue = user.get('email')
          }
          if (question.title === 'Nonprofit name') {
            defaultValue = user.getIn(['organization', 'name'])
          }
        }
        values[question._id] = question.previousResponse === 0 ? 0 : question.previousResponse || defaultValue
      })
    }
    return values
  },
})

export default compose(withReducer, withEpics, withConnect, withForm)(DeedForms)
