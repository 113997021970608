// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* global __DETECTED_VIEWER_TIME_ZONE__ */

import { Record as IRecord, List, Set } from 'immutable'
import _ from 'lodash'
import { subMinutes, addMinutes } from 'date-fns'
import type { DonationProvider, PaymentMethodType } from '@joindeed/calculate-fees'

import i18n from 'src/i18n'
import { colors } from 'src/theme'
import type { AmountCurrencies } from 'src/containers/modules/CurrencyFormat'
import { splitLocationCode } from 'src/utils'
import Donation from 'src/entities/donation/model'
import { type MatchableRule } from 'src/entities/donation/matchableRule'

import User from '../user/model'
import Cause from '../cause/model'
import Organization from '../organization/model'
import LocationLatLng from '../locationLatLng/model'
import LocationObject from '../locationObject/model'

import Role from './role'
import Milestone from './milestone'

declare let __DETECTED_VIEWER_TIME_ZONE__

interface Hours {
  min: number
  max: number
}

export interface Duration {
  min: number
  max: number
  unit: string
}

interface DonationStats {
  employeeAmount: number
  employeeAmountCurrencies: AmountCurrencies
  otherAmount: number
  otherAmountCurrencies: AmountCurrencies
  matchedByEmployerAmount: number
  matchedByEmployerAmountCurrencies: AmountCurrencies
  totalAmountCurrencies: AmountCurrencies
}

interface ExternalLinkFollow {
  activityDates: Date[]
  id: string
  user: string
}

interface MatchLocationOptions {
  exact?: boolean
  includeVirtual?: boolean
  includeNonprofits?: boolean
}

const properties = {
  id: '',
  name: '',
  startingAt: null,
  endingAt: null,
  duration: 0,
  hours: {},
  value: 0,
  organization: Organization,
  provisionalNonprofitData: undefined,
  nonprofits: List(),
  partner: Organization,
  shareLink: '',
  duties: '',
  mustKnows: '',
  isPrivate: false,
  locationLatLng: LocationLatLng,
  locationObject: LocationObject,
  location: '',
  pictures: [],
  mainPicture: '',
  attendeeLimit: 0,
  waitlistLimit: undefined,
  registrationEndingAt: undefined,
  attendees: [],
  waitlist: [],
  checkIns: [],
  invites: [],
  donations: List(),
  donationText: '',
  donationLink: '',
  backgroundCheckLink: '',
  volunteerFormLink: '',
  organizerName: '',
  organizerEmail: '',
  organizerPhone: '',
  pillars: [],
  ERGs: [],
  categories: [],
  SDGs: [],
  campaigns: [],
  formQuestions: [],
  formQuestionsStatus: undefined,
  forms: [],
  started: false,
  ended: false,
  public: false,
  timeZone: '',
  type: '',
  createdAt: null,
  description: '',
  currencyCode: 'USD',
  goalAmount: 0,
  goalAmountCurrencies: {},
  // Deprecated
  donatedAmount: 0,
  donatedAmountCurrencies: {},
  donationsUserCount: 0,
  donationsCount: 0,
  matchedAmount: 0,
  matchedAmountCurrencies: {},
  matchingPercentage: 0,
  matchingMaximumCurrencies: {},
  matchableRule: undefined,
  matchableRules: undefined,
  matchingPaymentMethods: [],
  matchingPaymentMethodsExclude: false,
  matchingBudgetId: undefined,
  creditAmountPerHour: 0,
  creditCurrencyCode: 'USD',
  donationStats: {},
  totalImpact: 0,
  milestones: List(),
  roles: List(),
  virtual: false,
  featured: false,
  matchForSearchTerm: '',
  externalId: '',
  summary: '',
  challenge: '',
  solution: '',
  longTermImpact: '',
  externalSearchSorting: 0,
  externalLink: '',
  allowedLocations: [],
  allowedCountries: [],
  allowedStates: [],
  externalLinkFollows: [],
  externalLinkFollowsCount: 0,
  userHasFollowedExternalLink: false,
  submitter: null,
  status: '',
  donationAmountOptions: [],
  minimumDonationAmount: null,
  donationProvider: '',
  organizingCommunityIds: [],
  communityIds: [],
  optedIn: false,
  linkOnly: false,
}

export default class Deed extends IRecord(properties, 'Deed') implements Deed {
  public readonly id!: string

  public readonly type!: string

  public readonly name!: string

  public readonly startingAt!: Date | null

  public readonly endingAt!: Date | null

  public readonly duration!: number | Duration

  public readonly hours!: Hours

  public readonly value!: number

  public readonly organization!: Organization

  public readonly provisionalNonprofitData!: Partial<Organization>

  public readonly nonprofits!: List<Organization>

  public readonly partner!: Organization

  public readonly shareLink!: string

  public readonly duties!: string

  public readonly mustKnows!: string

  public readonly isPrivate!: boolean

  public readonly locationLatLng!: LocationLatLng

  public readonly locationObject!: LocationObject

  public readonly location!: string

  public readonly pictures!: string[]

  public readonly mainPicture!: string

  public readonly attendeeLimit!: number

  public readonly waitlistLimit!: number

  public readonly registrationEndingAt!: Date

  public readonly attendees!: any[]

  public readonly waitlist!: any[]

  public readonly checkIns!: string[]

  public readonly invites!: any[]

  public readonly donationsCount!: number

  public readonly donations!: List<Donation>

  public readonly donationText!: string

  public readonly donationLink!: string

  public readonly backgroundCheckLink!: string

  public readonly volunteerFormLink!: string

  public readonly organizerName!: string

  public readonly organizerEmail!: string

  public readonly organizerPhone!: string

  public readonly pillars!: string[]

  public readonly ERGs!: string[]

  public readonly categories!: string[]

  public readonly SDGs!: string[]

  public readonly campaigns!: string[]

  public readonly formQuestions!: any[]

  public readonly formQuestionsStatus: 'draft' | 'submitted' | undefined

  public readonly forms!: any[]

  public readonly started!: boolean

  public readonly ended!: boolean

  public readonly public!: boolean

  // @ts-expect-error not sure if its needed
  public readonly timeZone!: string

  public readonly createdAt!: Date

  public readonly description!: string

  public readonly currencyCode!: string

  public readonly goalAmount!: number

  public readonly goalAmountCurrencies!: AmountCurrencies

  public readonly donatedAmount!: number

  public readonly donatedAmountCurrencies!: AmountCurrencies

  public readonly donationsUserCount!: number

  public readonly matchedAmount!: number

  public readonly matchedAmountCurrencies!: AmountCurrencies

  public readonly matchingPercentage!: number

  public readonly matchingMaximumCurrencies!: AmountCurrencies

  public readonly matchableRule!: MatchableRule

  public readonly matchableRules?: Record<string, MatchableRule>

  public readonly matchingPaymentMethods!: [PaymentMethodType]

  public readonly matchingPaymentMethodsExclude!: boolean

  public readonly matchingBudgetId!: string

  public readonly creditAmountPerHour!: number

  public readonly creditCurrencyCode!: string

  public readonly donationStats!: DonationStats

  public readonly totalImpact!: number

  public readonly milestones!: List<Milestone>

  public readonly roles!: List<Role>

  public readonly virtual!: boolean

  public readonly featured!: boolean

  public readonly matchForSearchTerm!: string

  public readonly externalId!: string

  public readonly summary!: string

  public readonly challenge!: string

  public readonly solution!: string

  public readonly longTermImpact!: string

  public readonly externalSearchSorting!: number

  public readonly externalLink!: string

  public readonly allowedLocations!: string[]

  public readonly allowedCountries!: string[]

  public readonly allowedStates!: string[]

  public readonly externalLinkFollows!: ExternalLinkFollow[]

  public readonly externalLinkFollowsCount!: number

  public readonly userHasFollowedExternalLink!: boolean

  public readonly submitter!: User

  public readonly status!: 'draft' | 'pending' | 'published'

  public readonly donationAmountOptions!: number[]

  public readonly minimumDonationAmount!: number | null

  public readonly donationProvider!: DonationProvider

  public readonly organizingCommunityIds!: string[]

  public readonly communityIds!: string[]

  public readonly optedIn!: boolean[]

  public readonly linkOnly!: boolean

  constructor(values: any = {}) {
    const locationObject = new LocationObject(values.locationObject)
    const validValues = _.pick(values, Object.keys(properties))
    super({
      ...validValues,
      id: values._id || values.id,
      milestones: values.milestones && List(values.milestones.map((milestone: any) => new Milestone(milestone))),
      roles: values.roles && List(values.roles.map((role: any) => new Role(role))),
      startingAt: values.startingAt ? new Date(values.startingAt) : null,
      endingAt: values.endingAt ? new Date(values.endingAt) : null,
      registrationEndingAt: values.registrationEndingAt ? new Date(values.registrationEndingAt) : null,
      createdAt: new Date(values.createdAt),
      organization: values.organization && new Organization(values.organization),
      provisionalNonprofitData: values.provisionalNonprofitData && new Organization(values.provisionalNonprofitData),
      nonprofits: values.nonprofits && List(values.nonprofits.map((nonprofit: any) => new Organization(nonprofit))),
      partner: values.partner && new Organization(values.partner),
      locationLatLng: new LocationLatLng(values.location),
      locationObject,
      location: String(locationObject),
      donations: values.donations && List(values.donations.map((donation: any) => new Donation(donation))),
      type: values.__t,
      submitter: values.submitter ? new User(values.submitter) : null,
    })
  }

  public isFull(): boolean {
    return this.attendees.length >= this.attendeeLimit
  }

  public isWaitlistFull(): boolean {
    return Number.isFinite(this.waitlistLimit) ? this.waitlist.length >= this.waitlistLimit : false
  }

  public isDuringCheckInWindow(): boolean {
    if (!this.startingAt || !this.endingAt) {
      return false
    }

    const now = new Date()
    const checkInStart = subMinutes(this.startingAt, 30)
    const checkInEnd = addMinutes(this.endingAt, 30)
    return now > checkInStart && checkInEnd > now
  }

  public hasUserAppliedForRole(user: string | User, roleId?: string): boolean {
    const userId = typeof user === 'string' ? user : user?.id
    const getAllRoleAttendeesIds = (role?: Role): string[] =>
      role
        ?.get('applicants')
        .concat(role.get('approved'), role.get('rejected'))
        .map((attendee: any) => (typeof attendee === 'string' ? attendee : attendee.id)) ?? []

    if (roleId) {
      const role = this.roles.find((roleObject: Role) => roleObject.get('id') === roleId)
      return getAllRoleAttendeesIds(role).includes(userId)
    }

    return this.roles?.flatMap((role: Role) => getAllRoleAttendeesIds(role)).includes(userId)
  }

  public numberOfRolesUserApplied(user: string | User): number {
    const userId = typeof user === 'string' ? user : user?.id
    const getAllRoleAttendeesIds = (role?: Role): string[] =>
      role
        ?.get('applicants')
        .concat(role.get('approved'), role.get('rejected'))
        .map((attendee: any) => (typeof attendee === 'string' ? attendee : attendee.id)) ?? []

    return this.roles?.toArray().filter((role: Role) => getAllRoleAttendeesIds(role).includes(userId)).length || 0
  }

  // All roles the user is applied for, is an applicant or got rejected
  public userRoles(user: string | User): Role[] {
    const userId = typeof user === 'string' ? user : user?.id

    return this.roles?.toArray().flatMap((role: Role) => {
      const allAttendees = role.get('applicants').concat(role.get('approved'), role.get('rejected'))

      const isUserApplied = allAttendees.some((attendee: any) => {
        const attendeeId = typeof attendee === 'string' ? attendee : attendee.id
        return attendeeId === userId
      })

      return isUserApplied ? [role] : []
    })
  }

  // This gets the roles that are not fullfilled yet
  public roleSpotsAvailable(role?: Role): number | List<Role> {
    if (role) {
      return role.get('requiredAmount') - role.get('approved').size
    }

    return this.roles?.filter((role_) => this.roleSpotsAvailable(role_) > 0)
  }

  public hasAnyRoleToApplyLeft(user: string | User): boolean {
    const userId = typeof user === 'string' ? user : user?.id

    return this.roles.toArray().some((role: Role) => {
      const allAttendees = role.get('applicants').concat(role.get('approved'), role.get('rejected'))

      const isUserApplied = allAttendees.some((attendee: any) => {
        const attendeeId = typeof attendee === 'string' ? attendee : attendee.id
        return attendeeId === userId
      })

      const roleSpotsAvailable = this.roleSpotsAvailable(role) > 0

      // If the user has not applied and there are available spots, return true
      return !isUserApplied && roleSpotsAvailable
    })
  }

  // This counts the total number spots left on the deed
  public numberOfSpotsAvailable(): number {
    if (this.roles.size > 0) {
      return this.roles?.reduce((total, role) => this.roleSpotsAvailable(role) + total, 0) || 0
    }
    if (this.attendeeLimit) {
      const spotsLeft = this.attendeeLimit - this.attendees.length
      return spotsLeft > 0 ? spotsLeft : 0 // Account for more attendees than limit allows
    }
  }

  public isUserAttending(user: string | User, roleId?: string): boolean {
    const userId = typeof user === 'string' ? user : user?.id
    if (roleId) {
      const getAllRoleAttendeesIds = (role?: Role): string[] =>
        role
          ?.get('applicants')
          .concat(role.get('approved'))
          .map((attendee: any) => (typeof attendee === 'string' ? attendee : attendee.id)) ?? []

      const role = this.roles.find((roleObject: Role) => roleObject.get('id') === roleId)
      return getAllRoleAttendeesIds(role).includes(userId)
    } else {
      return !!this.attendees.find((attendee) => (typeof attendee === 'string' ? attendee : attendee.id) === userId)
    }
  }

  public isUserWaitlisted(user: string | User): boolean {
    const userId = typeof user === 'string' ? user : user?.id
    return !!this.waitlist.find(
      (waitlisted) => (typeof waitlisted === 'string' ? waitlisted : waitlisted.id) === userId
    )
  }

  public isUserCheckedIn(user: string | User): boolean {
    const userId = typeof user === 'string' ? user : user?.id
    return this.checkIns.includes(userId)
  }

  public allSkills(): Set<string> {
    return (
      this.roles
        ?.reduce((allSkills: List<string>, role: Role) => allSkills.concat(role.get('skills')), List())
        .toSet() || Set()
    )
  }

  public color(hex = true): string {
    switch (this.type) {
      case 'Event':
        return hex ? colors.teal : 'teal'

      case 'Campaign':
        return hex ? colors.blue : 'blue'

      case 'Project':
        return hex ? colors.pink : 'pink'

      case 'BaseEvent':
        return hex ? colors.darkViolet : 'darkViolet'

      default:
        return ''
    }
  }

  get urlType(): string {
    return this.type === 'Campaign' ? 'fundraiser' : this.type.toLowerCase()
  }

  get isOpportunity(): boolean {
    return this.id?.startsWith('opportunity-')
  }

  public hasCause(cause: Cause): boolean {
    const causeField = cause.fieldName()
    return this.get(causeField).includes(cause.get('id'))
  }

  public hasCampaign(campaignId: string): boolean {
    return this.get('campaigns').includes(campaignId)
  }

  public getNonprofits(): List<Organization> {
    return this.get('type') === 'Campaign' && this.get('nonprofits')?.size > 0
      ? this.get('nonprofits')
      : this.get('organization')
      ? List([this.get('organization')])
      : this.get('provisionalNonprofitData')
      ? List([this.get('provisionalNonprofitData')])
      : List([])
  }

  public matchLocation(location?: string, options: MatchLocationOptions = {}): boolean {
    const opts = {
      exact: true,
      includeVirtual: false,
      includeNonprofits: true,
      ...options,
    }

    if (this.get('virtual')) {
      return opts.includeVirtual
    }

    const { countryCode: filterCountryCode, stateCode: filterStateCode } = splitLocationCode(location)

    let locations = List([this.get('locationObject') as LocationObject])

    if (opts.includeNonprofits) {
      const nonprofitsLocation = this.getNonprofits()
        .filter((nonprofit) => nonprofit?.locationObject?.countryCode)
        .map((nonprofit) => nonprofit?.locationObject)
      if (nonprofitsLocation) {
        locations = locations.concat(nonprofitsLocation)
      }
    }

    return !!locations.find((loc) => {
      const { countryCode: locCountryCode, stateCode: locStateCode } = splitLocationCode(loc.locationCode)

      let match = locCountryCode === filterCountryCode

      if (filterStateCode && opts.exact) {
        match = match && locStateCode === filterStateCode
      }
      return match
    })
  }

  public getShortLocation(): string {
    if (this.get('type') === 'Campaign') {
      const nonprofitWithLocation = this.getNonprofits().find((nonprofit) => !!nonprofit?.locationObject?.country)
      return nonprofitWithLocation?.locationObject?.country ?? ''
    }

    return this.get('virtual') ? i18n.t('deedModel:virtual') : this.get('locationObject').area
  }

  public isUpcoming(): boolean {
    return !this.started && !this.ended
  }

  public isOngoing(): boolean {
    return this.started && !this.ended
  }

  public isPast(): boolean {
    return this.ended
  }

  public isUpcomingForUser(user: User): boolean {
    return user?.isUserDeed(this.id) && !user?.hasCompletedDeed?.(this.id) && !this.started && !this.ended
  }

  public isOngoingForUser(user: User): boolean {
    return user?.isUserDeed(this.id) && !user?.hasCompletedDeed?.(this.id) && !this.ended && this.started
  }

  public isPastForUser(user: User): boolean {
    return user?.isUserDeed(this.id) && (this.ended || user?.hasCompletedDeed?.(this.id))
  }

  public canUserLogHours(user: User): boolean {
    // Deeds entities can be generated from third party APIs,
    // in that case, we won't be able to query volunteer-time
    // since Deed will have a MongoDB ObjectId
    return (
      user &&
      user.isUserDeed(this.id) &&
      this.type !== 'BaseEvent' &&
      !this.isExternal &&
      ((this.externalLink && this.userHasFollowedExternalLink) || (this.type === 'Project' ? true : this.started))
    )
  }

  public canUserEditDeed(user?: User): boolean {
    // @NOTE-CH: A Deed can be edit by the submitter user if it's not published yet or it's published but is upcoming
    return Boolean(user && this.isSubmittedByUser(user) && (this.isUpcoming() || this.status !== 'published'))
  }

  public getRolesForUser(userId?: string): List<Role> {
    return (
      (userId &&
        this.roles?.filter((role) => [...role.approved, ...role.applicants, ...role.rejected].includes(userId))) ||
      List([])
    )
  }

  public getStartingAtForUser(userId?: string): Date | null {
    return (
      this.getRolesForUser(userId)
        .map((role) => role.startingAt)
        .reduce<Date>((a, b) => (a < b ? a : b)) || this.startingAt
    )
  }

  public getEndingAtForUser(userId?: string): Date | null {
    return (
      this.getRolesForUser(userId)
        .map((role) => role.endingAt)
        .reduce<Date>((a, b) => (a > b ? a : b)) || this.endingAt
    )
  }

  public getTotalDurationInMinutesForUser(userId?: string, selectedRoleId?: string): number {
    if (selectedRoleId) {
      const selectedRole = this.roles?.find((r) => r?.id === selectedRoleId)
      const duration =
        selectedRole?.startingAt &&
        selectedRole.endingAt &&
        selectedRole.endingAt.getTime() - selectedRole.startingAt.getTime()
      if (typeof duration === 'number') {
        return duration / (1000 * 60)
      }
    }
    return (
      (this.getRolesForUser(userId)
        .map((role) => (role.startingAt && role.endingAt && role.endingAt.getTime() - role.startingAt.getTime()) || 0)
        .reduce<number>((a, b) => a + b) ||
        (this.endingAt && this.startingAt && this.endingAt.getTime() - this.startingAt.getTime()) ||
        0) /
      (1000 * 60)
    )
  }

  // Is the Deed solely external, without an ID in Deed's database
  get isExternal(): boolean {
    return this.id.startsWith('ext-')
  }

  // eslint-disable-next-line @typescript-eslint/no-dupe-class-members
  get timeZone() {
    if (this.virtual) {
      return __DETECTED_VIEWER_TIME_ZONE__
    }

    return this.get('timeZone')
  }

  public isSubmittedByUser(userOrUserId: string | User): boolean {
    if (!this.submitter || !userOrUserId) {
      return false
    }

    const userId = typeof userOrUserId === 'string' ? userOrUserId : userOrUserId?.id
    return this.submitter?.id === userId
  }

  get externalSource(): string | undefined {
    const externalSource = this.externalId?.match(/ext-(.*)-/)
    return externalSource ? externalSource[1] : undefined
  }
}
