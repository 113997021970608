import { createSelector } from 'reselect'

import type { State } from 'src/reducers'

import { DemoDonationReceiptState, initialState } from './reducer'

const selectState = (state: State): DemoDonationReceiptState => state.get('demoDonationReceipt') || initialState

export const selectReceiptValidating = createSelector(selectState, (state) => state.get('receiptValidating'))

export const selectReceiptValidation = createSelector(selectState, (state) => state.get('receiptValidation'))
