import {
  RESET_RATE_DEED_FLOW,
  SUBMIT_FRIEND_REQUEST,
  SUBMIT_FRIEND_REQUEST_FAILED,
  SUBMIT_FRIEND_REQUEST_SUCCESS,
  SUBMIT_RATE,
  SUBMIT_RATE_FAILED,
  SUBMIT_RATE_SUCCESS,
} from './constants'

export type DeedRateState = {
  rateSubmitloading: boolean
  rateSubmitError: boolean
  rateSubmitSuccess: boolean
  loadingFriendRequestId: string | null
  friendRequestSubmitError: boolean
  friendRequestSubmitSuccess: boolean
  addedFriends: string[]
}

export const initialState: DeedRateState = {
  rateSubmitloading: false,
  rateSubmitError: false,
  rateSubmitSuccess: false,
  loadingFriendRequestId: null,
  friendRequestSubmitError: false,
  friendRequestSubmitSuccess: false,
  addedFriends: [],
}

type DeedRateAction =
  | { type: typeof RESET_RATE_DEED_FLOW }
  | { type: typeof SUBMIT_RATE }
  | { type: typeof SUBMIT_RATE_SUCCESS }
  | { type: typeof SUBMIT_RATE_FAILED }
  | { type: typeof SUBMIT_FRIEND_REQUEST; friendRequest: string }
  | { type: typeof SUBMIT_FRIEND_REQUEST_SUCCESS; friendUserId: string }
  | { type: typeof SUBMIT_FRIEND_REQUEST_FAILED }
  | { type: 'RESET' }

export default (state: DeedRateState = initialState, action: DeedRateAction): DeedRateState => {
  switch (action.type) {
    case SUBMIT_RATE:
      return {
        ...state,
        rateSubmitloading: true,
        rateSubmitError: false,
      }

    case SUBMIT_RATE_SUCCESS:
      return {
        ...state,
        rateSubmitloading: false,
        rateSubmitSuccess: true,
      }

    case SUBMIT_RATE_FAILED:
      return {
        ...state,
        rateSubmitloading: false,
        rateSubmitError: true,
      }

    case SUBMIT_FRIEND_REQUEST:
      return {
        ...state,
        loadingFriendRequestId: action.friendRequest,
        friendRequestSubmitError: false,
      }

    case SUBMIT_FRIEND_REQUEST_SUCCESS:
      return {
        ...state,
        loadingFriendRequestId: null,
        friendRequestSubmitSuccess: true,
        addedFriends: [...state.addedFriends, action.friendUserId].filter((v, i, a) => a.indexOf(v) === i),
      }

    case SUBMIT_FRIEND_REQUEST_FAILED:
      return {
        ...state,
        loadingFriendRequestId: null,
        friendRequestSubmitError: true,
      }

    case RESET_RATE_DEED_FLOW:
      return initialState

    case 'RESET':
      return initialState

    default:
      return state
  }
}
