// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* global __DETECTED_VIEWER_TIME_ZONE__ */

import React, { PureComponent } from 'react'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import i18n from 'i18next'
import { ApolloProvider as ApolloProviderOriginal } from '@apollo/client'
import { ConnectedRouter } from 'connected-react-router/immutable'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import configureStore from 'src/configureStore'
import Router from 'src/navigation/Router'
import Alert from 'src/retired/elements/Alert'
import PersistGate from 'src/containers/modules/PersistGate'
import Alerts from 'src/containers/modules/Alerts'
import Disclaimer from 'src/containers/modules/Disclaimer'
import CookieConsent from 'src/containers/modules/CookieConsent'
import UpdateScreen from 'src/containers/screens/Update'
import { ThemeProvider } from 'src/theme/ThemeProvider'
import { historyStack } from 'src/navigation'
import { apolloClient } from 'src/entities/graphql'

import './i18n'
import { getLanguageCode } from './utils/languageUtils'
import { locales } from './i18n/interpolationFormat'

const history = createBrowserHistory({
  getUserConfirmation: (message, callback) =>
    Alert.alert(message, undefined, [
      { text: i18n.t('common:Cancel'), style: 'cancel' },
      {
        text: i18n.t('common:Yes'),
        style: 'destructive',
        // eslint-disable-next-line node/no-callback-literal
        onPress: () => callback(true),
      },
    ]),
})

const store = configureStore({}, history)

history.listen((location) => {
  if (history.action === 'PUSH') {
    historyStack.push(location.pathname)
  } else {
    historyStack.pop()
    if (history.action === 'REPLACE') {
      historyStack.push(location.pathname)
    }
  }
})

// eslint-disable-next-line react/prop-types
const ApolloProvider = ({ children }: { children: React.ReactNode }) => (
  <ApolloProviderOriginal client={apolloClient}>{children}</ApolloProviderOriginal>
)

class Root extends PureComponent {
  render() {
    return (
      <Provider store={store}>
        <ApolloProvider>
          <ConnectedRouter history={history}>
            <ThemeProvider>
              <PersistGate>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={locales[getLanguageCode(i18n.language)] || locales.en}
                >
                  <Alerts />
                  <Router history={history} />
                  <CookieConsent />
                  <Disclaimer />
                  <UpdateScreen />
                </LocalizationProvider>
              </PersistGate>
            </ThemeProvider>
          </ConnectedRouter>
        </ApolloProvider>
      </Provider>
    )
  }
}

export default Root
