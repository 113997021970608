import User from 'src/entities/user/model'

import {
  SEARCH,
  SEARCH_SUCCESS,
  SEARCH_FAILED,
  CLEAR,
  INVITE_ATTENDEES,
  INVITE_ATTENDEES_SUCCESS,
  INVITE_ATTENDEES_FAILED,
} from './constants'

export interface SearchAction {
  type: typeof SEARCH
  term: string
}
export const searchAction = (term: string): SearchAction => ({
  type: SEARCH,
  term,
})
export const searchSuccessAction = (results) => ({
  type: SEARCH_SUCCESS,
  results,
})
export const searchFailedAction = (error: object) => ({
  type: SEARCH_FAILED,
  error,
})

export interface InviteAttendeesAction {
  type: typeof INVITE_ATTENDEES
  deedId: string
  invites: Map<string, Pick<User, 'id' | 'fullName' | 'email'>>
  resetFormCallback?: () => void
}
export const inviteAttendeesAction = (
  deedId: string,
  invites: Map<string, Pick<User, 'id' | 'fullName' | 'email'>>,
  resetFormCallback?: () => void
): InviteAttendeesAction => ({
  type: INVITE_ATTENDEES,
  deedId,
  invites,
  resetFormCallback,
})
export const inviteAttendeesSuccessAction = () => ({
  type: INVITE_ATTENDEES_SUCCESS,
})
export const inviteAttendeesFailedAction = (error: object) => ({
  type: INVITE_ATTENDEES_FAILED,
  error,
})

export const clearAction = () => ({
  type: CLEAR,
})
