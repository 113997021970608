import React from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { Row, Icon, Text, LinkButton } from 'src/retired/elements'
import Chip from 'src/retired/shared/Chip'
import { Link } from 'src/navigation'
import { Platform } from 'src/utils'
import Deed from 'src/entities/deed/model'
import User from 'src/entities/user/model'
import { CardCompactWrapper } from 'src/components'

const CreatedDeed = ({ deed, user }: { deed: Deed; user: User }): JSX.Element => {
  const { t } = useTranslation('createdDeedBlock')

  return (
    <Link to={`/deeds/${deed.id}`} style={{ minHeight: 143, paddingBottom: 25 }}>
      <CardCompactWrapper cardColor={deed.color()} fullWidth>
        <View style={{ flexGrow: 1 }}>
          <Row style={{ justifyContent: 'space-between', flexGrow: 0 }}>
            <Text size={13} light>
              {t('date:dayMonthYear', {
                date: { value: deed.createdAt, timeZone: deed.timeZone },
              })}
            </Text>
            {deed.status !== 'published' && (
              <Chip type="square" backgroundColor="grayLight" textSize={12}>
                {t(deed.status)}
              </Chip>
            )}
          </Row>
        </View>
        <Row style={{ justifyContent: 'space-between' }}>
          <View style={{ flex: 1, paddingRight: 15, alignSelf: 'flex-end' }}>
            <Text size={15} bold numberOfLines={Platform.OS === 'web' ? 1 : 2}>
              {deed.name}
            </Text>

            <Text size={15} numberOfLines={Platform.OS === 'web' ? 1 : 2}>
              {deed.nonprofits?.map((nonprofit) => nonprofit.name)}
            </Text>
          </View>
          {deed.canUserEditDeed(user) ? (
            <LinkButton
              to={`/edit-deed/${deed.urlType}/${deed.id}`}
              color="transparent"
              style={{
                borderRadius: 100,
                borderWidth: 1,
                borderStyle: 'solid',
                alignSelf: 'flex-end',
                paddingBottom: 1,
                paddingLeft: 15,
                paddingRight: 15,
                height: 22,
                alignItems: 'center',
              }}
            >
              <Text size={12} light>
                {t`edit`}
              </Text>
            </LinkButton>
          ) : (
            <View style={{ alignSelf: 'flex-end' }}>
              <Icon icon="arrowCircleRightBlack" style={{ width: 26, height: 26 }} />
            </View>
          )}
        </Row>
      </CardCompactWrapper>
    </Link>
  )
}

export default CreatedDeed
