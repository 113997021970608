import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined'
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import { Box, Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Deed from 'src/entities/deed/model'
import User from 'src/entities/user/model'
import { Avatar, ScrollView } from 'src/retired/elements'
import { Body1 } from 'src/retired/shared/Typography'
import { ColorsProps } from 'src/theme/colors'
import { downloadFileFromApi } from 'src/utils/downloadFileFromApi'

import { Box as MapBox } from '../MapBlock'
import { toggleAttendeesContactModal } from '../../actions'
import { showAttendeesContactModal } from '../../selectors'

import { InviteModal } from './InviteModal'
import { AttendeesContactModal } from './AttendeesContactModal'
import { AttendeesListModal } from './AttendeesListModal'

const MAXIMUM_ATTENDEES_DISPLAY = 30

export const AttendeesBlock = ({
  deed,
  colors,
  styles,
  user,
}: {
  deed: Deed
  colors: ColorsProps
  styles: { scrollView: React.CSSProperties; scrollViewContainer: React.CSSProperties }
  user?: User
}): React.ReactNode => {
  const { t } = useTranslation('deedScreen')
  const dispatch = useDispatch()

  const { attendees } = deed
  const showExternalLinkFollows = !!deed.externalLinkFollowsCount && deed.type === 'Project'
  const showAttendees = !!attendees?.length
  const showContactModal = useSelector(showAttendeesContactModal)

  const [showAttendeesModal, setShowAttendeesModal] = useState(false)
  const [showInviteOverlay, setShowInviteOverlay] = useState(false)

  const setShowAttendeesContactModal = (toggle: boolean) => {
    dispatch(toggleAttendeesContactModal(toggle))
  }

  if (deed.type === 'Campaign') {
    return null
  }

  return (
    <MapBox>
      <InviteModal deedId={deed.id} user={user} close={() => setShowInviteOverlay(false)} visible={showInviteOverlay} />
      <AttendeesListModal
        deed={deed}
        visible={showAttendeesModal}
        onClose={() => setShowAttendeesModal(false)}
        user={user}
      />
      <AttendeesContactModal
        deed={deed}
        visible={showContactModal}
        onClose={() => setShowAttendeesContactModal(false)}
        user={user}
      />
      <Box style={{ width: '100%', marginBottom: 20 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {showExternalLinkFollows && (
              <Body1 weight="500" style={{ color: colors.gray }}>{`${deed.externalLinkFollowsCount} ${t('interested')}${
                showAttendees ? ', ' : ''
              }`}</Body1>
            )}

            <Body1 weight="500">{`${attendees.length} ${deed.isPast() ? t('attended') : t('attending')}`}</Body1>
            {['BaseEvent', 'Event', 'Project'].includes(deed.type) && !deed.isPast() && (
              <>
                ,
                <Body1 weight="500" colour={colors.brandColor}>
                  {' '}
                  {t('spotsLeft', {
                    count: deed.numberOfSpotsAvailable(),
                  })}
                </Body1>
              </>
            )}
          </Box>

          {showAttendees ? (
            <Button sx={{ px: 2 }} onClick={() => setShowAttendeesModal(true)}>{t`viewAll`}</Button>
          ) : null}
        </Box>

        {showAttendees && (
          <ScrollView
            horizontal
            style={[styles.scrollView, { marginTop: 5 }]}
            contentContainerStyle={styles.scrollViewContainer}
          >
            {attendees?.slice(0, MAXIMUM_ATTENDEES_DISPLAY).map((attendee) => (
              <Avatar
                key={attendee.id}
                user={attendee}
                link={user && String(attendee.id) !== String(user.id)}
                style={{ borderWidth: attendee.clickable ? 2 : 0, borderColor: colors.blue }}
              />
            ))}
          </ScrollView>
        )}

        <Box sx={{ display: 'flex', gap: 1, mt: 3, alignItems: 'center' }}>
          <Button
            variant="outlined"
            size="small"
            style={{ height: 28, color: colors.grayText, borderColor: colors.grayText }}
            onClick={() => setShowInviteOverlay(true)}
            endIcon={<PersonAddAltIcon />}
          >
            {t`invite`}
          </Button>
          {user && deed.isSubmittedByUser(user) && showAttendees && (
            <>
              <Button
                variant="outlined"
                size="small"
                style={{ height: 28, color: colors.grayText, borderColor: colors.grayText }}
                onClick={() => {
                  downloadFileFromApi(`api/deeds/${deed.id}/volunteers`, 'volunteers.csv')
                }}
                endIcon={<DownloadForOfflineOutlinedIcon />}
              >
                {t`export`}
              </Button>
              <Button
                variant="outlined"
                size="small"
                style={{ height: 28, color: colors.grayText, borderColor: colors.grayText }}
                onClick={() => setShowAttendeesContactModal(true)}
                endIcon={<ContactMailOutlinedIcon />}
              >
                {t`contact`}
              </Button>
            </>
          )}
        </Box>
      </Box>
    </MapBox>
  )
}
