import React, { ChangeEvent } from 'react'
import { Box, Button, TextField, TextFieldProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Body2 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'

interface EmailInviteSectionProps {
  formik: any
  showTitle: boolean
  onClickAdd: () => void
}

export const EmailInviteSection = ({ formik, showTitle, onClickAdd }: EmailInviteSectionProps) => {
  const { t } = useTranslation('deedScreen')
  const { metrics } = useDeedTheme()

  const handleOnChange = (fieldName: string, value: string) => {
    formik.setFieldValue(fieldName, value)
  }

  const textFieldProps = (name: string, label: string, maxLength: number, flex: number): TextFieldProps => ({
    variant: 'standard',
    label: t(label),
    name,
    value: formik.values[name],
    onChange: (e: ChangeEvent<HTMLInputElement>) => handleOnChange(name, e.target.value),
    inputProps: { maxLength },
    error: Boolean(formik.touched[name] && formik.errors[name]),
    helperText: formik.touched[name] && formik.errors[name],
    sx: { flex },
  })

  return (
    <>
      {showTitle && (
        <Box sx={{ my: 2, display: 'flex', justifyContent: 'center' }}>
          <Body2>{t`orInviteThemByEmail`}</Body2>
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: metrics.isSmall ? 'column' : 'row',
          justifyContent: 'space-between',
          gap: 1,
          mb: 2,
        }}
      >
        <Box sx={{ display: 'flex', gap: 1 }}>
          <TextField {...textFieldProps('firstName', 'firstName', 50, 1)} autoFocus={!showTitle} />
          <TextField {...textFieldProps('lastName', 'lastName', 50, 1)} />
        </Box>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <TextField {...textFieldProps('email', 'email', 50, 1)} inputProps={{ keyboardtype: 'email' }} />
          <Button variant="contained" disabled={!formik.isValid} onClick={onClickAdd}>
            {t`common:add`}
          </Button>
        </Box>
      </Box>
    </>
  )
}
