import { View } from 'react-primitives'
import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { styled } from 'src/theme/styled'
import { useDeedTheme, EmotionTheme } from 'src/theme/ThemeProvider'
import { Box } from 'src/containers/screens/Deed/components/MapBlock'
import { Alert, AlignedImage, MapLink, Row, Spacing, Touchable } from 'src/retired/elements'
import { CurrencyFormat, CurrencyCode, currencies } from 'src/containers/modules/CurrencyFormat'
import { Link } from 'src/navigation'
import OrganizationChip from 'src/retired/shared/OrganizationChip'
import { Communications } from 'src/utils'
import config from 'src/config'
import Tooltip from 'src/retired/elements/Tooltip'
import Deed from 'src/entities/deed/model'
import { AddToCalendarBlock } from 'src/containers/screens/Deed/components/AddToCalendarBlock'
import { selectCurrentUser, selectUserBrand } from 'src/entities/user/selectors'
import { getTimeLeftToStartOfEvent } from 'src/containers/screens/Deed/utils'
import { Body1, Body2 } from 'src/retired/shared/Typography'

import { ImportOpportunityButton } from './ImportOpportunityButton'

export const eventTypes = ['Event', 'BaseEvent']

const DonationCreditRow = styled(Row)<EmotionTheme>(
  ({ theme }: { theme: EmotionTheme }) => `
  width: ${theme.metrics.isSmall ? `${theme.metrics.screenWidth}px;` : '100%'}
  margin: -25px -25px 25px -25px;
  padding: 15px 25px;
  align-items: center;
  border-bottom-width: 1;
  border-bottom-color: ${theme.colors.gray02};
`
)

interface InfoBlockProps {
  deed: Deed
  signUpLocation: string
}
export const InfoBlock = ({ deed, signUpLocation }: InfoBlockProps): JSX.Element => {
  const user = useSelector(selectCurrentUser)
  const isEmployee = user?.isEmployee()
  const brand = useSelector(selectUserBrand) || deed?.partner
  const { t } = useTranslation('deedScreen')
  const { colors, metrics } = useDeedTheme()

  const virtualEvent = eventTypes.includes(deed.type) && deed.virtual && deed.externalLink
  const nonprofits = deed.getNonprofits()
  const timeLeftToStartOfEvent = getTimeLeftToStartOfEvent(deed)

  return (
    <Box half={eventTypes.includes(deed.type)} first>
      <View style={{ width: '100%' }}>
        {deed.creditAmountPerHour > 0 && (
          <DonationCreditRow>
            {brand ? (
              <>
                {brand.logo && (
                  <AlignedImage
                    alignment="left"
                    width={30}
                    height={30}
                    style={{ marginRight: 15 }}
                    source={{ uri: brand.logo }}
                  />
                )}
                <Body2 weight="500" style={{ width: metrics.isSmall ? metrics.screenWidth - 80 : '100%' }}>
                  <Trans
                    t={t}
                    i18nKey={isEmployee ? 'volunteerHourDonationCreditEmployee' : 'volunteerHourDonationCreditGuest'}
                    values={{ organizationName: brand.name }}
                    components={{
                      CurrencyFormat: (
                        <CurrencyFormat
                          amount={deed.creditAmountPerHour}
                          baseCurrency={deed.creditCurrencyCode as CurrencyCode}
                          short
                        />
                      ),
                    }}
                  />
                </Body2>
              </>
            ) : (
              <Body2 weight="500" center>
                {t('earnInDonationCredit', {
                  currencySymbol: currencies[deed.creditCurrencyCode]?.symbol,
                  creditAmountPerHour: deed.creditAmountPerHour,
                })}
              </Body2>
            )}
          </DonationCreditRow>
        )}
        <Row>
          <Body1>
            {eventTypes.includes(deed.type) ? t`hostedBy` : deed.type === 'Project' ? t`organizedBy` : t`supporting`}:
          </Body1>
        </Row>
        <View
          style={{
            flexShrink: 1,
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            marginTop: 20,
            marginBottom: 10,
          }}
        >
          {nonprofits?.size ? (
            nonprofits.map((nonprofit) => (
              <Spacing marginBottom={10} key={nonprofit.id}>
                <OrganizationChip organization={nonprofit} link />
              </Spacing>
            ))
          ) : deed.partner ? (
            <Spacing marginBottom={10} key={deed.partner.id}>
              <OrganizationChip organization={deed.partner} />
            </Spacing>
          ) : null}
        </View>
      </View>

      {deed.organizerName && (deed.organizerEmail || deed.organization?.website) ? (
        <Spacing
          marginBottom={15}
          style={{
            borderTopStyle: 'solid',
            borderTopWidth: 1,
            borderTopColor: colors.gray04,
            paddingTop: 18,
          }}
        >
          <Row>
            <Body1 marginRight={18}>{t`pointOfContact`}:</Body1>
            <Touchable
              onPress={() => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                deed.organizerEmail
                  ? Alert.alert(`Contact ${deed.organizerName}?`, '', [
                      { text: t`common:Cancel`, style: 'cancel' },
                      {
                        text: t`common:Yes`,
                        onPress: () => Communications.email([deed.organizerEmail], '', '', deed.name, ''),
                      },
                    ])
                  : Communications.web(deed.organization.website)
              }}
            >
              <Body1 weight="500" numberOfLines={1} colour={colors.brandColor}>
                {deed.organizerName}
              </Body1>
            </Touchable>
          </Row>
        </Spacing>
      ) : null}

      {deed.getShortLocation() ? (
        <Spacing
          marginBottom={15}
          style={{
            borderTopStyle: 'solid',
            borderTopWidth: 1,
            borderTopColor: colors.gray04,
            paddingTop: 18,
          }}
        >
          <Row>
            <Body1 marginRight={18}>{t`location`}:</Body1>
            {metrics.isSmall && deed.type !== 'Campaign' && deed.location && !deed.virtual ? (
              <View style={{ flex: 1 }}>
                <MapLink
                  size={15}
                  underline
                  latitude={deed.locationLatLng.coordinates[1]}
                  longitude={deed.locationLatLng.coordinates[0]}
                >
                  {deed.location}
                </MapLink>
              </View>
            ) : (
              <Row style={{ flexGrow: 0 }}>
                <Body1 weight="500" numberOfLines={1}>
                  {deed.getShortLocation()}
                </Body1>
                {virtualEvent && (
                  <>
                    {/** Show virtual event link starting 6 hours before event through end */}
                    {deed.isUserAttending(user) &&
                    Number.isFinite(timeLeftToStartOfEvent) &&
                    timeLeftToStartOfEvent >= 0 - deed.duration &&
                    timeLeftToStartOfEvent <= 360 ? (
                      <Body2
                        onPress={() =>
                          Communications.web(`${config.apiEndpoint}/api/deeds/${deed.id}/external/${user.id}`)
                        }
                        weight="500"
                        colour={colors.blue}
                        underline
                        style={{ paddingLeft: 8 }}
                      >
                        {t`joinHere`}
                      </Body2>
                    ) : !deed.isPast() ? (
                      <Tooltip title="Virtual">{t`theVideoConferenceLink`}</Tooltip>
                    ) : null}
                  </>
                )}
              </Row>
            )}
          </Row>
        </Spacing>
      ) : null}

      {!!deed.externalLink && !virtualEvent && (
        <Spacing
          marginBottom={15}
          style={{ borderTopStyle: 'solid', borderTopWidth: 1, borderTopColor: colors.gray04, paddingTop: 18 }}
        >
          <Row>
            <Body1 marginRight={18}>{t`hostedWebsite`}:</Body1>
            {deed.isOpportunity ? (
              <ImportOpportunityButton opportunityId={deed.id} signUpLocation={signUpLocation}>
                <Body1 weight="500" underline colour={colors.brandColor}>
                  {t`externalLink`}
                </Body1>
              </ImportOpportunityButton>
            ) : (
              <Link to={signUpLocation}>
                <Body1 weight="500" underline colour={colors.brandColor}>
                  {t`externalLink`}
                </Body1>
              </Link>
            )}
            <Tooltip>{t`thisProjectIsHostedOnAnotherWebsite`}</Tooltip>
          </Row>
        </Spacing>
      )}
      {!metrics.isLarge && eventTypes.includes(deed.type) ? (
        <Spacing
          marginBottom={15}
          style={{ borderTopStyle: 'solid', borderTopWidth: 1, borderTopColor: colors.gray04, paddingTop: 18 }}
        >
          <Row>
            <Body1 marginRight={18}>{t`date`}:</Body1>
            <AddToCalendarBlock deed={deed} />
          </Row>
        </Spacing>
      ) : null}
    </Box>
  )
}
