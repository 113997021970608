// copied from organizer

import { Observable } from 'rxjs'
import _ from 'lodash'
import { geocodeByAddress } from 'react-places-autocomplete'

import { FlippedCoordinates, LocationAddress } from 'src/utils/coordinates'

export const addressToLocation = (address: string) =>
  Observable.fromPromise(geocodeByAddress(address)).map(([result]): LocationAddress | null => {
    if (!result) {
      return null
    }

    // eslint-disable-next-line camelcase
    const { formatted_address, address_components, geometry } = result

    // eslint-disable-next-line camelcase
    const addressComponents = _.keyBy(address_components, (component) =>
      _.without(component.types, 'political', 'establishment').shift()
    )

    // eslint-disable-next-line camelcase
    const splitAddress = formatted_address.split(',')

    const location = {
      // @NOTE-CH: The order here is wrong, but we can't change it now because all the Deeds created in the app or organizer have the [Longitude/Latitude] order.
      // Both applications are aware of this and flip the order when needed.
      coordinates: [geometry.location.lng(), geometry.location.lat()] as FlippedCoordinates,
      type: 'Point' as const,
    }

    const locationObject = {
      address:
        addressComponents.street_number && addressComponents.route
          ? `${addressComponents.street_number.short_name} ${addressComponents.route.short_name}`
          : splitAddress[splitAddress.length > 4 ? 1 : 0],
      area: (
        addressComponents.neighborhood ||
        addressComponents.sublocality ||
        addressComponents.locality ||
        addressComponents.administrative_area_level_3 ||
        addressComponents.administrative_area_level_2 ||
        {}
      ).long_name,
      city: (
        addressComponents.locality ||
        addressComponents.sublocality ||
        addressComponents.administrative_area_level_3 ||
        addressComponents.administrative_area_level_2 ||
        addressComponents.neighborhood ||
        {}
      ).long_name,
      state: addressComponents.administrative_area_level_1?.long_name,
      stateCode: addressComponents.administrative_area_level_1?.short_name,
      postalCode: addressComponents.postal_code?.short_name,
      country: addressComponents.country?.long_name,
      countryCode: addressComponents.country?.short_name,
      geo: location,
    }

    return { locationObject, location }
  })

export default addressToLocation
