import { BOOTSTRAP, BRAND } from './constants'

export interface Bootstrap {
  type: typeof BOOTSTRAP
}
export const bootstrapAction = () => ({
  type: BOOTSTRAP,
})

export interface Brand {
  type: typeof BRAND
}
export const brandAction = () => ({
  type: BRAND,
})
