import { ActionsObservable } from 'redux-observable'
import i18n from 'i18next'

import DonationApi from 'src/entities/donation/api'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'

import { validateReceiptAction, validateReceiptSuccessAction, validateReceiptFailedAction } from './actions'
import { VALIDATE_RECEIPT } from './constants'

const validateReceipt = (action$: ActionsObservable<ReturnType<typeof validateReceiptAction>>) =>
  action$.ofType(VALIDATE_RECEIPT).exhaustMap(({ data }) =>
    DonationApi.analyzeReceipt(data)
      .mergeMap((result) => [validateReceiptSuccessAction(result)])
      .catch((error: Error) => [validateReceiptFailedAction(error), showErrorAction(i18n.t('receiptValidationError'))])
  )

export default [validateReceipt]
