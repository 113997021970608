import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { DeedMap } from 'src/entities/deed/reducer'

import { ResultsTitle } from '../../common/ResultsTitle'
import { ResultsWrapper } from '../../common/styles'
import { StaticDeedListWithPagination } from '../../common/StaticDeedListWithPagination'
import { VolunteerTypes } from '../../utils'

import { useAugmentDeedsWithOpportunities } from './useAugmentDeedsWithOpportunities'

export const VolunteerResults = ({
  volunteerProjects,
  volunteerEvents,
  volunteerProjectsLoading,
  volunteerEventsLoading,
  volunteerTypes,
  loadMoreProjects,
  loadMoreEvents,
  hasMoreItemsEvents,
  hasMoreItemsProjects,
  volunteerEventsLoaded,
  volunteerProjectsLoaded,
  searchTerm,
}: {
  volunteerProjects: DeedMap
  volunteerEvents: DeedMap
  volunteerProjectsLoading: boolean
  volunteerEventsLoading: boolean
  volunteerTypes: VolunteerTypes[]
  loadMoreProjects: () => void
  loadMoreEvents: () => void
  hasMoreItemsProjects: boolean
  hasMoreItemsEvents: boolean
  volunteerEventsLoaded: boolean
  volunteerProjectsLoaded: boolean
  searchTerm?: string
}) => {
  const { t } = useTranslation('searchScreen')
  const { metrics } = useDeedTheme()

  const eventsFeed = useAugmentDeedsWithOpportunities({
    type: 'Event',
    deeds: volunteerEvents,
    loadMore: loadMoreEvents,
    hasMoreItems: hasMoreItemsEvents,
    loaded: volunteerEventsLoaded,
    loading: volunteerEventsLoading,
  })
  const projectsFeed = useAugmentDeedsWithOpportunities({
    type: 'Project',
    deeds: volunteerProjects,
    loadMore: loadMoreProjects,
    hasMoreItems: hasMoreItemsProjects,
    loaded: volunteerProjectsLoaded,
    loading: volunteerProjectsLoading,
  })

  const volunteerEventsTitle =
    searchTerm?.length && !volunteerEventsLoading
      ? `${t('volunteerEventsTitleMatching', { count: eventsFeed.totalCount })}`
      : `${t('volunteerEventsTitle')}:`

  const volunteerProjectsTitle =
    searchTerm?.length && !volunteerProjectsLoading
      ? `${t('volunteerProjectsTitleMatching', { count: projectsFeed.totalCount })}`
      : `${t('volunteerProjectsTitle')}:`

  const oneOffSelected = !volunteerTypes?.length || volunteerTypes?.includes(VolunteerTypes.oneOff)
  const ongoingProjectSelected = !volunteerTypes?.length || volunteerTypes?.includes(VolunteerTypes.ongoing)

  return (
    <ResultsWrapper>
      {oneOffSelected && (
        <>
          <ResultsTitle title={volunteerEventsTitle} searchTerm={searchTerm} loading={volunteerEventsLoading} />
          <StaticDeedListWithPagination {...eventsFeed} />
        </>
      )}
      {ongoingProjectSelected && (
        <View style={{ marginTop: oneOffSelected && metrics.isSmall ? 24 : 0 }}>
          <ResultsTitle title={volunteerProjectsTitle} searchTerm={searchTerm} loading={volunteerProjectsLoading} />
          <StaticDeedListWithPagination {...projectsFeed} />
        </View>
      )}
    </ResultsWrapper>
  )
}
