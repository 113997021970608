import _ from 'lodash'
import { Record, List } from 'immutable'

import { currencies, CurrencyCode, AmountCurrencies } from 'src/containers/modules/CurrencyFormat'
import {
  CorporateProgram,
  DonationProvider,
  DonationStatus,
  MatchRequestStatus,
  PaymentMethodType,
} from 'src/generated/graphql'

import User from '../user/model'
import Deed from '../deed/model'
import Campaign from '../campaign/model'
import Organization from '../organization/model'
import DonationSchedule from '../donationSchedule/model'

interface Address {
  line1: string
  city: string
  state: string
  postal_code: string
}

interface BillingDetails {
  name: string
  email: string
  phone: string
  address: Address
}

interface Card {
  last4: string
}

interface PaymentMethodDetails {
  type: PaymentMethodType
  card: Card
  additionalData: Map<string, any>
}

// payroll types
interface PayrollDeduction {
  employeePayrollRun: string
  name: string
  employeeDeduction: number
  companyDeduction: number
  currency: string
  remoteData?: unknown
}

enum PayrollRunState {
  PAID = 'PAID',
  DRAFT = 'DRAFT',
  IN_PROGRESS = 'IN_PROGRESS',
  APPROVED = 'APPROVED',
  FAILED = 'FAILED',
  CLOSED = 'CLOSED',
}

interface PayrollRun {
  id: string
  deduction?: PayrollDeduction[]
  endDate: Date
  payDate?: Date
  runState: PayrollRunState
  startDate: Date
}

export enum DonationType {
  CorporateDonation = 'CorporateDonation',
  PayrollDonation = 'PayrollDonation',
  PayrollFlatFileDonation = 'PayrollFlatFileDonation',
}

export enum DonationCommitmentStatus {
  Recurring = 'recurring',
  Pledged = 'pledged',
  Matched = 'matched',
  Nothing = '',
}

const properties = {
  id: '',
  user: User,
  nonprofits: List(),
  deed: Deed,
  externalDeedId: '',
  externalDeedName: '',
  externalNonprofitName: '',
  identifier: '',
  description: '',
  amount: 0,
  amountCurrencies: {},
  processingFee: 0,
  matchedAmount: 0,
  matchedAmountCurrencies: {},
  matchingPaymentMethods: [],
  matchingPaymentMethodsExclude: false,
  total: 0,
  currency: '',
  receiptUrl: '',
  receiptNumber: '',
  createdAt: null,
  date: null,
  payrollRun: null,
  payrollRunFlatFileId: undefined,
  provider: '',
  privacy: 'public',
  dedication: '',
  designation: '',
  recurringId: '',
  status: '',
  payrollStatus: '',
  matchingRequestStatus: '',
  cardSuffix: '',
  isActive: false,
  billingDetails: {},
  paymentMethodDetails: {},
  paymentMethodDetailsType: '',
  imported: false,
  campaign: null,
  donationSchedule: null,
  disbursedAt: undefined,
  employeeNote: undefined,
  matchingProvider: undefined,
  nextDonationRecommendationLink: undefined,
  coveredFeeDisbursedAt: undefined,
  coveredFeeDisbursementInstruction: undefined,
  coveredFeeDisbursementTransactionIdentifier: undefined,
  coveredFeeDisbursementTransactionUrl: undefined,
  donationDisbursedAt: undefined,
  donationDisbursementInstruction: undefined,
  donationDisbursementTransactionIdentifier: undefined,
  donationDisbursementTransactionUrl: undefined,
  matchDisbursedAt: undefined,
  matchedAt: undefined,
  matchDisbursementInstruction: undefined,
  matchDisbursementTransactionIdentifier: undefined,
  matchDisbursementTransactionUrl: undefined,
  corporateProgramId: undefined,
  metadata: undefined,
  __t: undefined,
}

export default class Donation extends Record(properties, 'Donation') implements Donation {
  public readonly id!: string

  public readonly user!: User

  public readonly deed!: Deed

  public readonly externalDeedId!: string

  public readonly externalDeedName!: string

  public readonly externalNonprofitName?: string

  // We currently use arrays and Immutable Lists inconsistently
  public readonly nonprofits?: List<Organization> | Organization[]

  public readonly identifier!: string

  public readonly amount!: number

  public readonly amountCurrencies!: AmountCurrencies

  public readonly processingFee!: number

  public readonly matchedAmount!: number

  public readonly matchedAmountCurrencies!: AmountCurrencies

  public readonly matchingPaymentMethods!: string[]

  public readonly matchingPaymentMethodsExclude!: boolean

  public readonly total!: number

  public readonly currency!: CurrencyCode

  public readonly receiptUrl!: string

  public readonly receiptNumber!: string

  public readonly createdAt!: Date

  public readonly date!: Date

  public readonly provider!: DonationProvider

  public readonly privacy!: string

  public readonly dedication!: string

  public readonly designation!: string

  public readonly description?: string

  public readonly recurringId!: string

  public readonly status!: DonationStatus

  public readonly payrollStatus!: 'Deducted' | 'Pending' | 'Failed' | 'Failure'

  public readonly matchingRequestStatus!: MatchRequestStatus

  public readonly cardSuffix!: string

  public readonly isActive!: boolean

  public readonly billingDetails!: BillingDetails

  public readonly payrollRun?: PayrollRun

  public readonly payrollRunFlatFileId?: string

  public readonly paymentMethodDetails!: PaymentMethodDetails

  public readonly paymentMethodDetailsType!: string

  public readonly imported!: boolean

  public readonly campaign!: Campaign

  public readonly donationSchedule?: DonationSchedule

  public readonly disbursedAt?: Date

  public readonly matchedAt?: Date

  public readonly employeeNote?: string

  public readonly matchingProvider?: string

  public readonly nextDonationRecommendationLink?: string

  public readonly coveredFeeDisbursedAt?: Date

  public readonly coveredFeeDisbursementInstruction?: string

  public readonly coveredFeeDisbursementTransactionIdentifier?: string

  public readonly coveredFeeDisbursementTransactionUrl?: string

  public readonly donationDisbursedAt?: Date

  public readonly donationDisbursementInstruction?: string

  public readonly donationDisbursementTransactionIdentifier?: string

  public readonly donationDisbursementTransactionUrl?: string

  public readonly matchDisbursedAt?: Date

  public readonly matchDisbursementInstruction?: string

  public readonly matchDisbursementTransactionIdentifier?: string

  public readonly matchDisbursementTransactionUrl?: string

  public readonly metadata?: {
    matchRequestRejectionReason?: string
  }

  // Should be renamed to corporateProgram
  public readonly corporateProgramId?: CorporateProgram

  public readonly __t?: DonationType.PayrollFlatFileDonation | DonationType.PayrollDonation | undefined

  constructor(values: Partial<Donation> = {}) {
    const validValues = _.pick(values, Object.keys(properties))
    const {
      nonprofits = [],
      disbursedAt,
      matchedAt,
      matchDisbursedAt,
      donationSchedule,
      date,
      createdAt,
      deed,
      user,
      campaign,
    } = values

    const nonprofitObjectList = List<Organization>(
      nonprofits.map((nonprofit: Organization) => new Organization(nonprofit))
    )

    super({
      ...validValues,
      nonprofits: nonprofitObjectList,
      user: user ? new User(user) : user,
      deed: deed ? new Deed(deed) : deed,
      createdAt: new Date(createdAt as Date),
      date: new Date(date as Date),
      donationSchedule:
        donationSchedule &&
        new DonationSchedule(
          donationSchedule as unknown as DonationSchedule & { nonprofits?: Partial<Organization>[] }
        ),
      campaign: campaign?.id || campaign?._id ? new Campaign(campaign) : campaign,
      disbursedAt: disbursedAt ? new Date(disbursedAt) : disbursedAt,
      matchedAt: matchedAt ? new Date(matchedAt) : matchedAt,
      matchDisbursedAt: matchDisbursedAt ? new Date(matchDisbursedAt) : matchDisbursedAt,
    })
  }

  public isByUser(user: string | User): boolean {
    const userId = typeof user === 'string' ? user : user?.id
    return this.user?.id === userId
  }

  public isForDeed(deed: string | Deed): boolean {
    const deedId = typeof deed === 'string' ? deed : deed?.id
    return (this.deed?.id || this.deed) === deedId || this.externalDeedId === deedId
  }

  public getCurrencySymbol(): string {
    return currencies?.[this.currency?.toUpperCase()]?.symbol || '$'
  }

  public isDeedCredit(): boolean {
    return this.paymentMethodDetails?.type === PaymentMethodType.DeedCredit
  }

  public isPayroll(): boolean {
    return this.paymentMethodDetails?.type === PaymentMethodType.Payroll
  }

  public isExternalMatchRequest(): boolean {
    return !this.imported && this.paymentMethodDetails?.type === PaymentMethodType.External
  }

  public isCurrentlyPledged(): boolean {
    if (!this.isAssociatedToPledgingCampaign() || !this.isRecurring()) {
      return false
    }

    return this.campaign.isActivePledge()
  }

  public isAssociatedToPledgingCampaign() {
    if (!this.campaign) {
      return false
    }

    return this.campaign.isGivingPledgeCampaign()
  }

  public getPledgedAtDate(): Date | null {
    if (!this.isAssociatedToPledgingCampaign() || !this.donationSchedule) {
      return null
    }

    const { createdAt } = this.donationSchedule

    return createdAt
  }

  public isRecurring(): boolean {
    if (!this.donationSchedule) {
      return false
    }

    return this.donationSchedule.isRecurring()
  }

  public getDonationCommitment() {
    if (this.isRecurring()) {
      return DonationCommitmentStatus.Recurring
    }

    return DonationCommitmentStatus.Nothing
  }

  isExternal(): boolean {
    return this.paymentMethodDetails?.type === PaymentMethodType.External || this.provider === DonationProvider.External
  }

  get providerName(): string {
    if (this.imported) {
      return 'Imported'
    }
    if (this.isExternal()) {
      return 'External'
    }
    return this.provider
  }
}

export interface PayrollApiDonation extends Donation {
  __t: DonationType.PayrollDonation
}

export interface PayrollFlatFileDonation extends Donation {
  __t: DonationType.PayrollFlatFileDonation
}
