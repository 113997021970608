import { fromJS, OrderedSet } from 'immutable'
import { AnyAction } from 'redux'

import { TypedMap } from 'src/utils/typed-map'

import {
  CLEAR,
  INVITE_ATTENDEES,
  INVITE_ATTENDEES_FAILED,
  INVITE_ATTENDEES_SUCCESS,
  SEARCH,
  SEARCH_FAILED,
  SEARCH_SUCCESS,
} from './constants'

export type InviteModalState = TypedMap<{
  results: OrderedSet<object>
  term: string
  loading: boolean
  error: object | null
  submitting: boolean
  submitError: object | null
}>

export const initialState: InviteModalState = fromJS({
  results: OrderedSet(),
  term: '',
  loading: false,
  error: null,
  submitting: false,
  submitError: null,
})

export default (state = initialState, action: AnyAction): InviteModalState => {
  switch (action.type) {
    case SEARCH:
      if (action.term.length > 1) {
        return state.merge({ loading: true, term: action.term, error: null })
      }
      return state.merge({
        loading: false,
        term: action.term,
        results: OrderedSet(),
        error: null,
      })

    case SEARCH_SUCCESS:
      return state.merge({
        loading: false,
        results: OrderedSet(action.results),
      })

    case SEARCH_FAILED:
      return state.merge({ loading: false, error: action.error })

    case INVITE_ATTENDEES:
      return state.merge({ submitting: true })

    case INVITE_ATTENDEES_SUCCESS:
      return state.merge({ submitting: false })

    case INVITE_ATTENDEES_FAILED:
      return state.merge({ submitting: false, submitError: action.error })

    case CLEAR:
      return initialState

    default:
      return state
  }
}
