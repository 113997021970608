import { Box, Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'

import { Body2 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'

export interface WarningListItemProps {
  content: string
  confirmButtonText: string
  onClickConfirm: () => void
  onAnimationEnd: () => void
}

export const WarningListItem = ({
  content,
  confirmButtonText,
  onClickConfirm,
  onAnimationEnd,
}: WarningListItemProps) => {
  const [isExitingAnimationStart, setIsExitingAnimationStart] = useState(false)
  const { metrics, colors } = useDeedTheme()

  const { t } = useTranslation()

  const handleCancelClick = () => {
    setIsExitingAnimationStart(true)
  }

  const handleAnimationEnd = () => {
    if (isExitingAnimationStart) {
      onAnimationEnd()
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: metrics.isSmall ? 'column' : 'row',
        gap: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: colors.statusNeutral,
        py: 2,
        pl: 2,
        pr: 1,
        borderRadius: 2,
        animation: `${isExitingAnimationStart ? 'slide-out' : 'slide-in'} 0.3s ease-in-out`,
        '@keyframes slide-in': {
          from: { transform: 'translateX(100%)', opacity: 0 },
          to: { transform: 'translateX(0)', opacity: 1 },
        },
        '@keyframes slide-out': {
          from: { transform: 'translateX(0)', opacity: 1 },
          to: { transform: 'translateX(100%)', opacity: 0 },
        },
      }}
      onAnimationEnd={handleAnimationEnd}
    >
      <Body2>{content}</Body2>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Button
          variant="contained"
          onClick={onClickConfirm}
          endIcon={<CheckCircleOutlineIcon />}
          size="small"
          sx={{ height: 24 }}
        >
          {confirmButtonText || t`common:confirm`}
        </Button>
        <Button
          variant="outlined"
          onClick={handleCancelClick}
          endIcon={<CancelOutlinedIcon />}
          size="small"
          sx={{ height: 24 }}
        >
          {t`common:cancel`}
        </Button>
      </Box>
    </Box>
  )
}
