import React from 'react'
import difference from 'lodash/difference'
import { styled } from '@mui/material/styles'
import { Select as MUISelect, MenuItem, ListSubheader, Box, Chip } from '@mui/material'
import type { SelectProps as MUISelectProps, SelectChangeEvent } from '@mui/material'

import truthy from 'src/utils/truthy'
import { EmotionTheme } from 'src/theme/ThemeProvider'

interface OptionActual {
  value: string
  title: string
}
interface OptionGroups {
  label: string
  options: OptionActual[]
}
export interface SelectProps
  extends Pick<MUISelectProps<string | string[]>, 'value' | 'placeholder' | 'multiple' | 'disabled'> {
  options?: OptionActual[]
  optionGroups?: Array<OptionGroups | OptionActual>
  style?: React.CSSProperties
  customIcon?: MUISelectProps['IconComponent']
  onSelect?: (value: SelectProps['value']) => void
  onDeselect?: (value: SelectProps['value']) => void
  onFocus?: () => void
  transparent?: boolean
  iconOnly?: boolean
  // large only works together with iconOnly=true
  large?: boolean
  customBorderColor?: string
}

type StyledSelectProps = MUISelectProps & {
  transparent?: boolean
  iconOnly?: boolean
  customBorderColor?: string
  large?: boolean
}

const nonMuiProps = ['transparent', 'iconOnly', 'customBorderColor', 'large']

const StyledSelect = styled(MUISelect, {
  shouldForwardProp: (prop) => !nonMuiProps.includes(prop),
})((props: StyledSelectProps & { theme?: EmotionTheme }) => {
  const colors = props?.theme?.colors
  return {
    color: props.transparent && 'white',
    backgroundColor: 'transparent',
    border: '0 none',
    '&.Mui-focused': { backgroundColor: 'transparent' },
    '& legend': { display: 'none' },
    '& fieldset': { top: 0, border: 'none' },
    '& .MuiInputBase-input': {
      fontSize: 12,
      letterSpacing: -0.3,
      fontFamily: 'GTWalsheimLC',
      position: 'relative',
      backgroundColor: props.transparent ? 'transparent' : 'white',
      border: `1px solid ${props.customBorderColor || colors?.gray02}`,
      borderRadius: 20,
      padding: props.iconOnly ? (props.large ? '8px 14px' : '2px') : '7.5px 16px',
      '&:focus, &:hover': {
        borderRadius: 20,
        background: props.transparent ? 'transparent' : 'white',
        borderColor: colors?.grayLight,
      },
    },
    '&.Mui-disabled .MuiInputBase-input': {
      background: '#f5f5f5',
      cursor: 'not-allowed',
      '&:focus, &:hover': {
        borderColor: colors?.gray02,
      },
    },
    '& .MuiSelect-select': {
      paddingRight: props.iconOnly && '25px!important',
    },
  }
})

const StyledMenuItem = styled(MenuItem)(() => ({
  fontSize: 14,
  '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:active, &.Mui-selected:focus, &.Mui-active, &.Mui-focused': {
    backgroundColor: '#f0f7ff',
    fontWeight: 'bold',
  },
}))

const StyledPlaceholder = styled('span')((props: { theme?: EmotionTheme }) => ({
  color: props.theme?.colors.grayLight,
}))

export const Select: React.FC<SelectProps> = ({
  value,
  placeholder = '',
  multiple = false,
  disabled = false,
  options = [],
  optionGroups = [],
  style = {},
  customIcon,
  onSelect,
  onDeselect,
  onFocus,
  transparent,
  iconOnly,
  customBorderColor,
  large,
}) => {
  const allOptions = (options || []).concat((optionGroups || []).flatMap((o) => ('options' in o ? o.options : o)))
  const getOption = (currentValue: string) => allOptions.find((opt) => opt.value === currentValue)

  const onChange = (event: SelectChangeEvent<unknown>) => {
    const newValue = event.target.value
    if (multiple && value && Array.isArray(newValue) && value.length > newValue.length) {
      onDeselect?.(difference(value, newValue)?.[0])
    } else {
      onSelect?.(newValue as string)
    }
  }

  const renderValue = (selected: unknown): React.ReactNode => {
    if (iconOnly) {
      return null
    }
    if (Array.isArray(selected)) {
      return renderValueMultiple(selected as string[])
    }

    return renderValueSingle(selected as string)
  }

  const renderValueSingle = (selected: string) => {
    if (!selected) {
      return <StyledPlaceholder>{placeholder}</StyledPlaceholder>
    }

    return getOption(selected)?.title ?? selected
  }

  const renderValueMultiple = (selected: string[]) => {
    if (selected.length === 0) {
      return <StyledPlaceholder>{placeholder}</StyledPlaceholder>
    }

    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {selected
          .map(getOption)
          .filter(truthy)
          // eslint-disable-next-line @typescript-eslint/no-shadow
          .map(({ title, value }) => (
            <Chip key={value} label={title} />
          ))}
      </Box>
    )
  }

  return (
    <StyledSelect
      value={value}
      label={placeholder}
      displayEmpty
      multiple={multiple}
      disabled={disabled}
      style={style}
      IconComponent={customIcon}
      onFocus={onFocus}
      onChange={onChange}
      renderValue={renderValue}
      MenuProps={{
        style: {
          maxHeight: style.maxHeight ?? 420,
        },
      }}
      // @ts-expect-error transparent is a custom prop
      transparent={transparent}
      iconOnly={iconOnly}
      customBorderColor={customBorderColor}
      large={large}
    >
      {optionGroups.length > 0
        ? optionGroups.map((optionGroup) =>
            'options' in optionGroup ? (
              [
                <ListSubheader>{optionGroup.label}</ListSubheader>,
                optionGroup.options.map((option) => (
                  <StyledMenuItem key={`${option.title}-${option.value}`} value={option.value}>
                    {option.title}
                  </StyledMenuItem>
                )),
              ]
            ) : (
              <StyledMenuItem key={optionGroup.title} value={optionGroup.value}>
                {optionGroup.title}
              </StyledMenuItem>
            )
          )
        : options.map((option) => (
            <StyledMenuItem key={`${option.value}-${option.title}`} value={option.value}>
              {option.title}
            </StyledMenuItem>
          ))}
    </StyledSelect>
  )
}
