import { Observable } from 'rxjs'

import DeedsApi from 'src/entities/deed/api'
import { selectDeedById } from 'src/entities/deed/selectors'

import { initFailedAction, initSuccessAction } from './actions'
import { INIT } from './constants'

const init = (action$, store) =>
  action$.ofType(INIT).mergeMap(({ id }) => {
    const actions = []
    const state = store.getState()

    const deed = selectDeedById(state, id)
    if (!deed) {
      actions.push(DeedsApi.fetch(id))
    }

    if (actions.length === 0) {
      return Observable.of(initSuccessAction())
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [...resultingActions, initSuccessAction()])
      .catch((e) => Observable.of(initFailedAction(e)))
  })
export default [init]
