import React from 'react'
import { useSelector } from 'react-redux'
import { differenceInCalendarDays } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { styled } from 'src/theme/styled'
import CardHorizontal from 'src/retired/shared/CardHorizontal'
import DonateButton from 'src/retired/shared/DonateButton'
import Deed from 'src/entities/deed/model'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { EmotionTheme } from 'src/theme/ThemeProvider'

const CardWrapper = styled.View<object, EmotionTheme>`
  background-color: white;
  border-bottom-width: 1px;
  border-bottom-color: #d8d8d8;
  padding: ${({ theme }) => (theme.metrics.isSmall ? '0 25px' : '0 15px')};
`

interface DeedHorizontalPropTypes {
  deed: Deed
  ignoreCampaignParam: boolean
}

const DeedHorizontal: React.FC<DeedHorizontalPropTypes> = ({ deed, ignoreCampaignParam = false }) => {
  const { t } = useTranslation('deedHorizontal')
  const user = useSelector(selectCurrentUser)

  let formattedDate
  if (['Event', 'BaseEvent'].includes(deed.type)) {
    formattedDate = t('date:dayMonthYear', {
      date: deed.virtual
        ? deed.startingAt || deed.createdAt
        : { value: deed.startingAt || deed.createdAt, timeZone: deed.timeZone },
    })
  } else if (deed.endingAt) {
    const daysLeft = differenceInCalendarDays(new Date(deed.endingAt), new Date())
    formattedDate = t('daysLeft', { count: daysLeft })
  } else {
    formattedDate = t`ongoing`
  }

  return (
    <CardWrapper>
      <CardHorizontal
        link={`/deeds/${deed.id}`}
        line1={deed.name}
        line2={`${formattedDate} • ${deed.getShortLocation()}`}
        creditAmountPerHour={deed.creditAmountPerHour}
        cta={
          deed.type === 'Campaign' &&
          (!user || user?.hasFeature('donations')) && (
            <DonateButton model={deed} size="extraSmall" ignoreCampaignParam={ignoreCampaignParam}>
              {t`donate`}
            </DonateButton>
          )
        }
      />
    </CardWrapper>
  )
}

export default DeedHorizontal
