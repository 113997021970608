import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { View } from 'react-primitives'
import { useDispatch, useSelector } from 'react-redux'

import { CurrencyFormat, useCurrency } from 'src/containers/modules/CurrencyFormat'
import { selectUpcomingDeedsForUser, selectUpcomingDeedsLoaded } from 'src/entities/deed/selectors'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { Link } from 'src/navigation'
import { Gradient, Image } from 'src/retired/elements'
import Icon from 'src/retired/shared/Icon'
import { Body1, Body2 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { formatFullName } from 'src/utils'
import { useInjectEpics } from 'src/utils/injectEpics'
import { useInjectReducer } from 'src/utils/injectReducer'
import truthy from 'src/utils/truthy'
import Hoverable from 'src/retired/shared/Hoverable'

import { AvatarContainer, AvatarImage } from '../../Profile/Profile.styled'
import { fetchUpcomingDeedsAction } from '../actions'
import epics from '../epics'
import reducer from '../reducer'

const QuickActionItem = ({
  title,
  subtitle,
  link,
  imageUri,
  iconName,
  paddingTop,
  hideArrow = false,
  style,
}: {
  title: string
  subtitle?: string | JSX.Element
  link: string
  imageUri?: string | { uri: string }
  iconName?: string
  paddingTop: boolean
  hideArrow?: boolean
  style?: React.CSSProperties
}) => {
  const { colors } = useDeedTheme()

  return (
    <Link to={link} style={{ paddingTop: paddingTop ? 8 : 0, ...style }}>
      <Hoverable>
        {(isHovered: boolean) => (
          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              paddingHorizontal: 16,
              minHeight: 50,
              height: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: isHovered ? colors.gray06 : colors.gray03,
              borderRadius: 8,
              transition: 'background-color 0.2s ease',
            }}
          >
            {imageUri ? (
              <AvatarContainer colors={colors} style={{ marginVertical: 12 }}>
                <AvatarImage>
                  <Image source={imageUri} alt={title} title={title} style={{ height: 45, width: 45 }} />
                </AvatarImage>
              </AvatarContainer>
            ) : null}
            {iconName ? (
              <View style={{ paddingRight: 12 }}>
                <Icon icon={iconName} />
              </View>
            ) : null}
            <View style={{ flex: 1, paddingRight: 16, paddingVertical: 16 }}>
              <Body1 style={{ fontWeight: '500', marginBottom: subtitle ? 6 : 0 }}>{title}</Body1>
              {subtitle ? typeof subtitle === 'string' ? <Body2>{subtitle}</Body2> : subtitle : null}
            </View>
            {hideArrow ? null : <Icon icon="arrowRight" width={23} height={22} />}
          </View>
        )}
      </Hoverable>
    </Link>
  )
}

const QuickActionsFooter = () => {
  const { colors } = useDeedTheme()

  return (
    <View
      style={{
        flex: 1,
        marginTop: 8,
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
        backgroundColor: colors.gray03,
        borderRadius: 8,
        paddingTop: 16,
      }}
    >
      <Gradient
        height="80%"
        startOpacity={0}
        endColor={colors.gray03}
        endOpacity={1}
        vertical
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderRadius: 8,
        }}
      />
      <Icon icon="heartOnHandIllustration" />
    </View>
  )
}
const QuickActions = ({ style }: { style?: React.CSSProperties }) => {
  const { t } = useTranslation('homeScreen')
  const { metrics } = useDeedTheme()

  const displayCurrency = useCurrency()
  const dispatch = useDispatch()
  useInjectReducer({ key: 'home', reducer })
  useInjectEpics({ key: 'home', epics })

  const user = useSelector(selectCurrentUser)
  const upcomingDeedsLoaded = useSelector(selectUpcomingDeedsLoaded)
  const upcomingDeeds = useSelector((state) => selectUpcomingDeedsForUser(state, user))

  useEffect(() => {
    if (!upcomingDeedsLoaded && volunteeringEnabled) {
      dispatch(fetchUpcomingDeedsAction())
    }
  }, [])

  if (!user) {
    return null
  }

  const donationCreditEnabled = user.hasFeature('donations') && user.hasFeature('donationCredit')
  const donationsExternalMatchingEnabled = user.hasFeature('donationMatching') && user.hasFeature('externalMatching')
  const volunteeringEnabled = user.hasFeature('volunteering')
  const userCreateEventEnabled = user.hasFeature('userCreateEvent')
  const userCreateFundraiserEnabled = user.hasFeature('userCreateFundraiser')
  const userNominateNonprofitEnabled = user.hasFeature('userNominateNonprofit')

  const quickActions = [
    {
      title: formatFullName(user?.fullName),
      subtitle: donationCreditEnabled ? (
        <Body2>
          <Trans
            t={t}
            i18nKey="quickNavigation-profileSubtitle"
            components={{
              CurrencyFormat: (
                <CurrencyFormat
                  amount={user.donationCreditsWallet?.balance ?? 0}
                  baseCurrency={user.donationCreditsWallet?.currencyCode ?? displayCurrency?.code}
                />
              ),
            }}
          />
        </Body2>
      ) : undefined,
      link: '/profile',
      uri: user?.mainPicture,
      hideArrow: true,
    },
    volunteeringEnabled
      ? {
          title: t('quickNavigation-upcomingDeeds', { amount: upcomingDeeds.size ? `(${upcomingDeeds.size})` : '' }),
          link: '/profile/deeds',
          iconName: 'localActivityOutlined',
        }
      : null,
    donationsExternalMatchingEnabled
      ? {
          title: t`quickNavigation-offPlatformDonation`,
          link: '/off-platform',
          iconName: 'savingsOutlined',
        }
      : null,
    volunteeringEnabled
      ? {
          title: t`quickNavigation-logVolunteerHours`,
          link: '/profile/volunteer-hours/select-deed',
          iconName: 'hourglassEmptyOutlined',
        }
      : null,
    userCreateEventEnabled
      ? {
          title: t`quickNavigation-createVolunteerEvent`,
          link: '/create-deed/event',
          iconName: 'autoFixHighOutlined',
        }
      : null,
    userCreateFundraiserEnabled
      ? {
          title: t`quickNavigation-startFundraiser`,
          link: '/create-deed/fundraiser',
          iconName: 'autoGraphOutlined',
        }
      : null,
    userNominateNonprofitEnabled
      ? {
          title: t`quickNavigation-nominateOrganization`,
          link: '/nominate-nonprofit/search',
          iconName: 'workspacePremiumOutlined',
        }
      : null,
  ].filter(truthy)

  const showQuickActionsFooter = quickActions.length < 6 && metrics.isLarge
  return (
    <View style={{ flex: 1, alignItems: showQuickActionsFooter ? undefined : 'stretch', ...style }}>
      {quickActions.map((quickAction, index) => (
        <QuickActionItem
          key={quickAction.link}
          hideArrow={quickAction.hideArrow}
          paddingTop={index !== 0}
          title={quickAction.title}
          subtitle={quickAction.subtitle}
          link={quickAction.link}
          imageUri={quickAction.uri}
          style={showQuickActionsFooter ? undefined : { flex: 1 }}
          iconName={quickAction.iconName}
        />
      ))}
      {showQuickActionsFooter ? <QuickActionsFooter /> : null}
    </View>
  )
}

export default QuickActions
