import { Box, Button } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import _ from 'lodash'

import { Body1, Body2 } from 'src/retired/shared/Typography'
import Deed from 'src/entities/deed/model'
import { ratedDeedAction } from 'src/entities/user/actions'
import { SearchInput } from 'src/components/SearchInput/SearchInput'
import { formatFullName } from 'src/utils'
import { metrics } from 'src/theme'

import { AddFriendCard } from './AddFriendCard'
import { resetRateDeedFlowAction, submitFriendRequestAction } from './actions'
import { selectAddedFriends, selectLoadingFriendRequestId } from './selectors'

const MIN_ATTENDEE_AMOUNT_TO_SHOW_SEARCH_BAR = 15
export const AddFriendStep = ({
  deed,
  redirectLocation,
  attendees,
}: {
  deed: Deed
  attendees: object[]
  redirectLocation: string
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation('deedScreen')

  const loadingFriendRequestId = useSelector(selectLoadingFriendRequestId)
  const addedFriends = useSelector(selectAddedFriends)

  const [showAllAttendees, setShowAllAttendees] = useState<boolean>(false)
  const [currentSearchTerm, setCurrentSearchTerm] = useState('')
  const [filteredAttendees, setFilteredAttendees] = useState(attendees)

  const showSearchBar = attendees.length > MIN_ATTENDEE_AMOUNT_TO_SHOW_SEARCH_BAR && showAllAttendees

  const handleSearch = useCallback(
    _.debounce((searchTerm: string) => {
      setFilteredAttendees(
        searchTerm
          ? attendees.filter((attendee) =>
              formatFullName(attendee.preferredName || attendee.fullName)
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
            )
          : attendees
      )
    }, 300),
    [attendees]
  )

  useEffect(() => {
    handleSearch(currentSearchTerm)
  }, [attendees, currentSearchTerm, handleSearch])

  const handleSearchInputChange = (value: string) => {
    setCurrentSearchTerm(value)
    handleSearch(value)
  }

  const handleClose = () => {
    dispatch(ratedDeedAction()) // update user object to remove the rated deed, if we had removed it when rated then there would be no deed to show the add friend step for that rated deed.
    dispatch(resetRateDeedFlowAction())
    history.replace(redirectLocation)
  }

  return (
    <>
      <Body1 weight="500" style={{ textAlign: 'center' }}>
        {t`addFriendStepDescription`}
      </Body1>
      <Box sx={{ mb: 2 }} />
      <Body2 style={{ textAlign: 'center' }}>{t`addFriendsFromTheDeedBelow`}</Body2>

      {showSearchBar && (
        <Box sx={{ mt: 3, width: metrics.isSmall ? '90%' : '65%' }}>
          <SearchInput
            outlined
            small
            fieldName="searchAttendees"
            placeholder={t('searchAttendees')}
            inputValue={currentSearchTerm}
            onChange={(__, value) => handleSearchInputChange(value)}
            onReset={() => handleSearchInputChange('')}
            triggerSearch={() => handleSearch(currentSearchTerm)}
          />
        </Box>
      )}

      {attendees && attendees.length > 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: metrics.isSmall ? '100%' : '65%', mt: 2 }}>
          {(showAllAttendees ? filteredAttendees : filteredAttendees.slice(0, 3)).map((attendee) => (
            <AddFriendCard
              key={attendee.id}
              user={attendee}
              added={addedFriends?.includes(attendee.id)}
              onClick={() => dispatch(submitFriendRequestAction(deed.id, attendee.id))}
              loading={loadingFriendRequestId === attendee.id}
            />
          ))}
        </Box>
      )}
      {attendees.length > 3 && (
        <Button variant="outlined" onClick={() => setShowAllAttendees(!showAllAttendees)} sx={{ mt: 2, minWidth: 200 }}>
          {showAllAttendees ? t`common:viewLess` : t`common:viewAll`}
        </Button>
      )}
      <Button onClick={handleClose} sx={{ mt: 2, minWidth: 200 }}>
        {addedFriends.length > 0 ? t`common:close` : t`common:skip`}
      </Button>
    </>
  )
}
