import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { Box, Button, IconButton } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import User from 'src/entities/user/model'
import { Text } from 'src/retired/elements'
import { Body1 } from 'src/retired/shared/Typography'
import { colors } from 'src/theme'

interface SelectedUsersListProps {
  selected: Map<string, Pick<User, 'id' | 'fullName' | 'email'>>
  onRemove: (id: string, user: Pick<User, 'id' | 'fullName' | 'email'>) => void
  onClearAll: () => void
}

export const SelectedUsersList: React.FC<SelectedUsersListProps> = ({ selected, onRemove, onClearAll }) => {
  const { t } = useTranslation('deedScreen')
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center',
          mb: 1,
        }}
      >
        <Body1>{t`selectedUsers`}</Body1>
        <Button size="small" onClick={onClearAll}>
          {t`deselectAll`}
        </Button>
      </Box>

      <Box
        sx={{
          overflowY: 'scroll',
          maxHeight: 250,
          width: '100%',
          border: `1px solid ${colors.borderGray}`,
          borderRadius: 2,
        }}
      >
        {[...selected.entries()].map(([key, user]) => (
          <Box
            key={key}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              alignItems: 'center',
              pl: 2,
              pr: 1,
              transition: 'background-color 0.3s',
              '&:hover': {
                backgroundColor: colors.gray03,
              },
            }}
          >
            <Text numberOfLines={1} lineHeight="26px">
              {user.fullName.first} {user.fullName.last}
            </Text>
            <IconButton onClick={() => onRemove(user.id || key, user)}>
              <CancelOutlinedIcon color="error" fontSize="small" />
            </IconButton>
          </Box>
        ))}
      </Box>
    </Box>
  )
}
