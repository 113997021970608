import React from 'react'
import { View } from 'react-primitives'

import { Touchable } from 'src/retired/elements'
import { H5 } from 'src/retired/shared/Typography'
import Icon from 'src/retired/shared/Icon'
import { useDeedTheme } from 'src/theme/ThemeProvider'

export const CloseButton = ({
  onClose,
  size = 'large',
  style,
}: {
  onClose: () => void
  size?: 'small' | 'large'
  style?: React.CSSProperties
}) => {
  const { colors } = useDeedTheme()
  const buttonSize = size === 'small' ? 36 : 40
  return (
    <Touchable onPress={onClose}>
      <View
        style={{
          width: buttonSize,
          height: buttonSize,
          borderRadius: 50,
          borderWidth: 1,
          borderColor: colors.grayMild,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: colors.white,
          ...style,
        }}
      >
        <Icon icon="closeBlack" width={10} height={10} />
      </View>
    </Touchable>
  )
}

export const ContentWrapper = ({
  children,
  onClose,
  title,
}: {
  children: React.ReactNode
  onClose: () => void
  title?: string | JSX.Element
}): JSX.Element => {
  const { colors, metrics } = useDeedTheme()
  return (
    <View
      style={{
        minWidth: 150,
        minHeight: 200,
        padding: metrics.isLarge ? 32 : 20,
        backgroundColor: colors.white,
      }}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {title && <H5 style={{ width: '100%', textAlign: 'center', paddingLeft: '32px' }}>{title}</H5>}
        {onClose && <CloseButton onClose={onClose} size={metrics.isSmall ? 'small' : 'large'} />}
      </View>
      {children}
    </View>
  )
}
