import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { View } from 'react-primitives'
import { bindActionCreators, compose } from 'redux'
import { format, toDate, startOfDay, startOfToday } from 'date-fns'
import { withTheme } from '@emotion/react'
import { useTranslation, withTranslation } from 'react-i18next'

import { styled } from 'src/theme/styled'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { withRouter } from 'src/navigation'
import { injectReducer, findMissingItems } from 'src/utils'
import { Switch, Row, AlignedImage } from 'src/retired/elements'
import { selectGroupedCauses } from 'src/entities/cause/selectors'
import { selectDeedsCountByDate } from 'src/entities/deed/selectors'
import { selectSkills } from 'src/entities/skill/selectors'
import { selectCurrentUser } from 'src/entities/user/selectors'
import SelectBox from 'src/retired/shared/SelectBox'
import { DatePicker } from 'src/components/DatePicker/DatePicker'
import Button from 'src/retired/shared/Button'
import DeedTypeToggle from 'src/containers/modules/DeedTypeToggle'
import Touchable from 'src/retired/shared/Touchable'
import { Body2 } from 'src/retired/shared/Typography'

import {
  selectSelectedDaysAndTimes,
  selectSelectedDate,
  selectSelectedLocation,
  selectSelectedCauses,
  selectSelectedSkills,
  selectCorporateOnly,
  selectInitialized,
} from './selectors'
import * as Actions from './actions'
import reducer from './reducer'

const FilterContainer = styled.View`
  margin: ${(props) => (props.mobile ? '10px 0' : '5px 0px 5px 10px')};
  max-width: 500px;
`

const Filters = ({
  actions,
  selectedDaysAndTimes,
  selectedDate,
  selectedLocation,
  selectedCauses,
  corporateOnly,
  deedsCountByDate,
  groupedCauses,
  user,
  selectedSkills,
  skills,
  deedType,
  mobile,
  toggle,
  toggleName,
  partner, // TODO: reduce props number by reading them from `partner`
  color,
}) => {
  const { t } = useTranslation('feedFilter')
  const { colors } = useDeedTheme()

  const partnerNonprofitsOnly = user?.getSetting('partnerNonprofitsOnly') ?? false

  return (
    <>
      {!partnerNonprofitsOnly && toggle && deedType !== 'Campaign' && (
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            marginRight: 10,
            height: 50,
          }}
        >
          <Touchable onPress={() => actions.toggleCorporateOnlyAction(true)}>
            {partner?.mainPicture ? (
              <AlignedImage
                alignment="right"
                width={60}
                height={23}
                style={{ marginLeft: 8, marginRight: 8 }}
                source={{ uri: partner.mainPicture }}
              />
            ) : (
              <Body2 right weight={corporateOnly ? '500' : 'normal'}>
                {toggleName}
              </Body2>
            )}
          </Touchable>
          <Switch
            value={!corporateOnly}
            onValueChange={() => actions.toggleCorporateOnlyAction(!corporateOnly)}
            style={{ marginHorizontal: 10 }}
            trackColor={{ true: color, false: color }}
            thumbColor={colors.white}
          />
          <Touchable onPress={() => actions.toggleCorporateOnlyAction(false)}>
            <Body2 weight={!corporateOnly ? '500' : 'normal'}>{t`allDeeds`}</Body2>
          </Touchable>
        </View>
      )}
      {['Event', 'BaseEvent'].includes(deedType) ? (
        <FilterContainer mobile={mobile}>
          <SelectBox
            multiple
            onSelect={(value) => {
              if (typeof value === 'string') {
                return actions.toggleSelectedDaysOrTimeAction(value)
              }
              const singleValue = findMissingItems(selectedDaysAndTimes, value)[0]
              return actions.toggleSelectedDaysOrTimeAction(singleValue)
            }}
            onDeselect={(value) => actions.toggleSelectedDaysOrTimeAction(value)}
            value={selectedDaysAndTimes.toArray()}
            placeholder={t`daysTime`}
            optionGroups={[
              {
                label: t`dayOfWeek`,
                options: [t`weekdays`, t`weekends`].map((item) => ({ value: item, title: item })),
              },
              {
                label: t`timeOfDay`,
                options: [t`morning`, t`afternoon`, t`evening`].map((item) => ({ value: item, title: item })),
              },
            ]}
            style={{ minWidth: 150 }}
          ></SelectBox>
        </FilterContainer>
      ) : null}
      {['Event', 'BaseEvent'].includes(deedType) && !mobile ? (
        <FilterContainer style={{ width: 150 }}>
          <DatePicker
            placeholder={t`date`}
            value={selectedDate ? toDate(selectedDate) : null}
            onChange={(value) => actions.setSelectedDateAction(value ? startOfDay(value) : null)} // onSetToday sets current time, but we need from the midnight
            minDate={new Date()}
            enableTodayAction={!!deedsCountByDate[format(startOfToday(), 'yyyy/MM/dd')]}
            availableDates={deedsCountByDate}
          />
        </FilterContainer>
      ) : null}
      {deedType === 'Project' ? (
        <FilterContainer mobile={mobile}>
          <SelectBox
            multiple
            onSelect={(value) => {
              if (typeof value === 'string') {
                return actions.toggleSelectedLocationAction(value)
              }

              const singleValue = findMissingItems(selectedLocation, value)[0]
              return actions.toggleSelectedLocationAction(singleValue)
            }}
            onDeselect={(value) => actions.toggleSelectedLocationAction(value)}
            value={selectedLocation.toArray()}
            placeholder={`${t`inPerson`}?`}
            options={[t`inPerson`, t`virtual`].map((item) => ({ value: item, title: item }))}
            style={{ minWidth: 150 }}
          />
        </FilterContainer>
      ) : null}
      {deedType === 'Project' ? (
        <FilterContainer mobile={mobile}>
          <SelectBox
            multiple
            onSelect={(value) => {
              if (typeof value === 'string') {
                return actions.toggleSelectedSkillAction(value)
              }
              const singleValue = findMissingItems(selectedSkills, value)[0]
              return actions.toggleSelectedSkillAction(singleValue)
            }}
            onDeselect={(value) => actions.toggleSelectedSkillAction(value)}
            value={selectedSkills.toArray()}
            placeholder={t`skills`}
            options={skills.toArray().map((skill) => ({ value: skill.get('id'), title: skill.name }))}
            style={{ minWidth: 150 }}
          />
        </FilterContainer>
      ) : null}
      <FilterContainer mobile={mobile}>
        <SelectBox
          multiple
          onSelect={(value) => {
            if (typeof value === 'string') {
              return actions.toggleSelectedCauseAction(value)
            }
            const singleValue = findMissingItems(selectedCauses, value)[0]
            return actions.toggleSelectedCauseAction(singleValue)
          }}
          onDeselect={(value) => actions.toggleSelectedCauseAction(value)}
          value={selectedCauses.toArray()}
          placeholder={t`causes`}
          optionGroups={groupedCauses}
          style={{ minWidth: 200 }}
        />
      </FilterContainer>
    </>
  )
}

Filters.propTypes = {
  deedType: PropTypes.string,
  actions: PropTypes.object.isRequired,
  selectedDaysAndTimes: PropTypes.object,
  selectedDate: PropTypes.instanceOf(Date),
  selectedLocation: PropTypes.object,
  selectedCauses: PropTypes.object,
  deedsCountByDate: PropTypes.object,
  groupedCauses: PropTypes.array,
  selectedSkills: PropTypes.object,
  skills: PropTypes.object,
  mobile: PropTypes.bool,
  toggle: PropTypes.bool,
  toggleName: PropTypes.string,
  color: PropTypes.string,
  corporateOnly: PropTypes.bool,
}

class FeedFilter extends PureComponent {
  static propTypes = {
    match: PropTypes.object,
    deedType: PropTypes.string,
    feedTypes: PropTypes.object,
    actions: PropTypes.object.isRequired,
    selectedTimes: PropTypes.object,
    selectedDate: PropTypes.string,
    selectedDays: PropTypes.object,
    selectedLocation: PropTypes.object,
    selectedCauses: PropTypes.object,
    deedsCountByDate: PropTypes.object,
    pillars: PropTypes.object,
    ERGs: PropTypes.object,
    categories: PropTypes.object,
    SDGs: PropTypes.object,
    selectedSkills: PropTypes.object,
    skills: PropTypes.object,
    user: PropTypes.object,
    mobile: PropTypes.bool,
    initialized: PropTypes.bool,
    close: PropTypes.func,
    partner: PropTypes.object,
  }

  render() {
    const {
      t,
      initialized,
      feedTypes,
      mobile,
      close,
      actions,
      match,
      user,
      theme: { colors, metrics },
    } = this.props

    if (!initialized && user) {
      actions.initAction(user?.organization?.settings.corporateOnlyToggleDefault)
    }

    const deedType = feedTypes[match.url]?.deedType || 'Event'
    const isEmployee = user?.isEmployee()
    const filterProps = {
      ...this.props,
      deedType,
      toggle: isEmployee,
      toggleName: isEmployee ? user.organization.name : '',
      partner: isEmployee ? user.organization : undefined,
      color: isEmployee ? colors.brandColor : '',
    }

    if (mobile) {
      return (
        <>
          <View style={{ maxWidth: 400, width: '100%', alignSelf: 'center' }}>
            <Filters {...filterProps} />
          </View>
          <Row style={{ marginTop: 35, marginBottom: 15, justifyContent: 'center' }}>
            <Button color="gray" style={{ marginRight: 25 }} fluid onPress={actions.resetAction} iconLeft="refresh">
              {t`reset`}
            </Button>
            <Button palette="primary" onPress={close} iconLeft="tick">
              {t`filterDeeds`}
            </Button>
          </Row>
        </>
      )
    }

    return (
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginLeft: metrics.isMedium ? 12 : 0,
          marginRight: metrics.isMedium ? 12 : 0,
        }}
      >
        <View style={{ flexShrink: 0, flexGrow: 0, marginTop: metrics.isSmall ? 10 : 0 }}>
          {deedType !== 'Campaign' && <DeedTypeToggle feedTypes={feedTypes} />}
        </View>
        <View style={{ flexGrow: 1 }} />
        <Row style={{ flexWrap: 'wrap', justifyContent: 'flex-end' }}>
          <Filters {...filterProps} />
        </Row>
      </View>
    )
  }
}

const withConnect = connect(
  (state) => ({
    groupedCauses: selectGroupedCauses(state),
    user: selectCurrentUser(state),
    skills: selectSkills(state),
    selectedDaysAndTimes: selectSelectedDaysAndTimes(state),
    selectedDate: selectSelectedDate(state),
    selectedLocation: selectSelectedLocation(state),
    selectedCauses: selectSelectedCauses(state),
    selectedSkills: selectSelectedSkills(state),
    corporateOnly: selectCorporateOnly(state),
    deedsCountByDate: selectDeedsCountByDate(state),
    initialized: selectInitialized(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
  })
)
const withReducer = injectReducer({ key: 'feedFilter', reducer })

export default compose(withReducer, withConnect, withRouter, withTheme, withTranslation('feedFilter'))(FeedFilter)
