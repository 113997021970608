import { INIT, INIT_FAILED, INIT_SUCCESS } from './constants'

export const initAction = (id) => ({
  type: INIT,
  id,
})
export const initSuccessAction = () => ({
  type: INIT_SUCCESS,
})
export const initFailedAction = (error) => ({
  type: INIT_FAILED,
  error,
})
