import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as FeedActions from 'src/containers/screens/Feed/actions'
import {
  selectDeedTypeFeedLoading,
  selectDeedTypeFeedCurrentPage,
  selectDeedTypeFeedMoreResults,
  selectFilteredSearchResults,
  selectDeedTypeFeedLoaded,
} from 'src/containers/screens/Feed/selectors'
import type { State } from 'src/reducers'

import { useSearchParams } from '../../utils'

import { VolunteerResults } from './VolunteerResults'

export const VolunteerResultsContainer = () => {
  const dispatch = useDispatch()

  const { searchTerm, location, coordinates, radius, volunteerTypes, categories, ergs, pillars, sdgs } =
    useSearchParams()

  /* FIXME: Replace `selectFilteredSearchResults` with `selectFilteredDeedsByAllFilters` (we'll need to add the other filters there) */
  const volunteerProjects = useSelector((state: State) =>
    selectFilteredSearchResults(state, 'Project', { coordinates, radius })
  )
  const volunteerEvents = useSelector((state: State) =>
    selectFilteredSearchResults(state, 'Event', { coordinates, radius })
  )

  const volunteerProjectsLoaded = useSelector((state: State) => selectDeedTypeFeedLoaded(state, 'Project'))
  const volunteerEventsLoaded = useSelector((state: State) => selectDeedTypeFeedLoaded(state, 'Event'))
  const volunteerProjectsLoading = useSelector((state: State) => selectDeedTypeFeedLoading(state, 'Project'))
  const volunteerEventsLoading = useSelector((state: State) => selectDeedTypeFeedLoading(state, 'Event'))

  const query = {
    searchTerm,
    location,
    radius,
    coordinates,
    categories,
    ergs,
    pillars,
    sdgs,
  }

  const currentPageProjects = useSelector((state) => selectDeedTypeFeedCurrentPage(state, 'Project'))
  const hasMoreItemsProjects = useSelector((state) => selectDeedTypeFeedMoreResults(state, 'Project'))
  const loadMoreProjects = () => {
    dispatch(
      FeedActions.loadFeedForDeedTypeAction('Project', { ...query, page: currentPageProjects + 1, isLoadMore: true })
    )
  }

  const currentPageEvents = useSelector((state) => selectDeedTypeFeedCurrentPage(state, 'Event'))
  const hasMoreItemsEvents = useSelector((state) => selectDeedTypeFeedMoreResults(state, 'Event'))
  const loadMoreEvents = () => {
    dispatch(
      FeedActions.loadFeedForDeedTypeAction('Event', { ...query, page: currentPageEvents + 1, isLoadMore: true })
    )
  }

  return (
    <VolunteerResults
      volunteerProjects={volunteerProjects}
      volunteerEvents={volunteerEvents}
      volunteerProjectsLoading={volunteerProjectsLoading}
      volunteerEventsLoading={volunteerEventsLoading}
      volunteerTypes={volunteerTypes}
      loadMoreProjects={loadMoreProjects}
      loadMoreEvents={loadMoreEvents}
      hasMoreItemsProjects={hasMoreItemsProjects}
      hasMoreItemsEvents={hasMoreItemsEvents}
      volunteerProjectsLoaded={volunteerProjectsLoaded}
      volunteerEventsLoaded={volunteerEventsLoaded}
      searchTerm={searchTerm}
    />
  )
}
