import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { useImportOpportunityMutation } from 'src/generated/graphql'
import i18n from 'src/i18n'
import { ActivityIndicator, Touchable } from 'src/retired/elements'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { codeProviderMap } from 'src/utils/mapOpportunityToDeed'

export const decodeOpportunityId = (opportunityId: string) => {
  const [, providerCode, providerId] = opportunityId.match(/opportunity-(\w+)-(.+)/) || []
  return {
    providerCode,
    providerId,
  }
}

interface Props {
  children: React.ReactNode
  opportunityId: string
  signUpLocation: string
}

/**
 * Imports an opportunity and redirects to the signUpLocation
 */
export const ImportOpportunityButton = ({ children, opportunityId, signUpLocation }: Props) => {
  const { colors } = useDeedTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const { providerCode, providerId } = decodeOpportunityId(opportunityId)
  const provider = codeProviderMap[providerCode]
  const [importOpportunity, { loading, client }] = useImportOpportunityMutation({
    onError: () => {
      dispatch(showErrorAction(i18n.t('common:anErrorOccurred')))
    },
  })
  if (loading) {
    return <ActivityIndicator size="large" color={colors.brandColor} />
  }
  return (
    <Touchable
      onPress={async () => {
        const importResult = await importOpportunity({
          variables: {
            provider,
            providerId,
          },
        })
        const importedDeedId = importResult.data?.importOpportunity?.id
        if (importedDeedId) {
          history.replace(`/deeds/${importedDeedId}`)
          history.push(
            // @NOTE-DP: we need to replace the fake opportunity id for the real deed id
            signUpLocation.replace(opportunityId, importedDeedId)
          )

          // @NOTE-DP: remove the opportunity from cache, since it has already been imported and should be available as a deed
          client.cache.evict({
            id: client.cache.identify({
              __typename: 'ReducedOpportunity',
              provider,
              providerId,
            }),
          })
          // @NOTE-DP: gargabe collect, so the queries would not get re-triggered
          client.cache.gc()
        }
      }}
    >
      {children}
    </Touchable>
  )
}
