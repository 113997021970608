import React, { useEffect, useState } from 'react'
import { View } from 'react-primitives'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { styled, css } from 'src/theme/styled'
import { useHistory, useParams } from 'src/navigation'
import { useDeedTheme, EmotionTheme } from 'src/theme/ThemeProvider'
import { validators } from 'src/utils'
import { selectCurrentUser } from 'src/entities/user/selectors'
import usePrevious from 'src/utils/usePrevious'
import { getDuration, getHours, getMinutes } from 'src/utils/convertDuration'
import { Spacing, Divider, Loading, Row, TextField, Form, Screen, ScrollView } from 'src/retired/elements'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { H4, Label, Body1, Body2 } from 'src/retired/shared/Typography'
import SelectBox from 'src/retired/shared/SelectBox'
import Button from 'src/retired/shared/Button'
import { getLanguageCode } from 'src/utils/languageUtils'
import { PageTitle } from 'src/components'
import { useUpdateVolunteerTimeOffMutation, useVolunteerTimeOffQuery } from 'src/generated/graphql'
import { DatePicker } from 'src/components/DatePicker/DatePicker'
import { ErrorText } from 'src/components/ErrorText'

import { FormikData } from '../VolunteerTimeForm/types'
import { ModalError } from '../VolunteerTimeOff/ModalError'

const Header = styled.View<object, EmotionTheme>`
  padding: ${({ theme }: { theme: EmotionTheme }) => (theme.metrics.isLarge ? '0 80px 20px 55px' : '0 25px 25px 25px')};
`

const FormContainer = styled.View<object, EmotionTheme>`
  padding: ${({ theme }: { theme: EmotionTheme }) => (theme.metrics.isLarge ? '0px 195px 70px' : '25px')};
`

const roundedInputStyle = css`
  border-radius: 20px;
  border: 1px solid #ebeef0;
  margin: 8px 0 16px;
  padding: 11px 20px;
  min-height: 40px;
  height: auto;
  flex-grow: 0;
`

// NOTE-AZ: move to global types file
export type Nullable<T> = {
  [P in keyof T]: T[P] | null
}

const EditVolunteerTimeOffForm = (): JSX.Element => {
  const { id = '' } = useParams<{ id?: string }>()
  const { t, i18n } = useTranslation('editVolunteerTimeOffFormProfile')
  const { colors, metrics } = useDeedTheme()
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
  const history = useHistory()

  const {
    data: vtoData,
    loading: queryVTOLoading,
    error: queryVTOError,
  } = useVolunteerTimeOffQuery({
    variables: {
      id,
    },
  })

  const [updateVTOMutation, { loading: updateVTOLoading }] = useUpdateVolunteerTimeOffMutation({
    onCompleted: () => {
      history.replace(`/profile/volunteer-time-off-hours/success`)
    },
    onError: () => {
      setIsErrorModalOpen(true)
    },
  })

  const volunteerTimeOff = vtoData?.volunteerTimeOff

  const prevAmount = usePrevious({ volunteerTimeOff })

  const user = useSelector(selectCurrentUser)

  const showVTOMinutesSelector = !user?.getSetting('vtoIncrementsInHours')

  const formik = useFormik({
    initialValues: volunteerTimeOff
      ? {
          hours: volunteerTimeOff.duration ? String(getHours(volunteerTimeOff.duration)) : '0',
          minutes: volunteerTimeOff.duration ? String(getMinutes(volunteerTimeOff.duration)) : '0',
          date: volunteerTimeOff.startingAt as string,
          note: volunteerTimeOff.note,
        }
      : {
          hours: '',
          minutes: '0',
          date: '',
          note: '',
        },
    validate: (values) => {
      const errors: Nullable<FormikData> = {
        hours:
          validators.notEmpty(values.hours) ||
          validators.isNumber(values.hours) ||
          (parseInt(values.hours, 10) < 1 || parseInt(values.hours, 10) > 24 ? 'Invalid number' : ''),
        minutes: validators.notEmpty(values.minutes),
        date: null,
        milestone: null,
      }

      if (user?.organization?.settings?.logHoursNotesMandatory) {
        errors.notes = validators.notEmpty(values.note)
      }

      const isValid = Object.values(errors).every((value) => !value)
      return !isValid ? errors : {}
    },
    onSubmit: () => {
      void updateVTOMutation({
        variables: {
          id,
          data: {
            duration: getDuration(formik.values.hours, formik.values.minutes),
            note: formik.values.note,
          },
        },
      })
    },
  })

  useEffect(() => {
    if (volunteerTimeOff && !prevAmount?.volunteerTimeOff) {
      void formik.setValues({
        date: volunteerTimeOff.startingAt as string,
        hours: volunteerTimeOff.duration ? String(getHours(volunteerTimeOff.duration)) : '0',
        minutes: volunteerTimeOff.duration ? String(getMinutes(volunteerTimeOff.duration)) : '0',
        note: volunteerTimeOff.note,
      })
    }
  }, [volunteerTimeOff, formik, prevAmount?.volunteerTimeOff])

  return (
    <Screen fixed>
      <PageTitle title={t('volunteerTimeOff')} />
      <ScrollView>
        {queryVTOLoading || queryVTOError ? (
          <>
            <NavigationHeader transparent />
            <Spacing marginTop={30} marginBottom={50}>
              {queryVTOLoading && <Loading fill={false} />}
              {queryVTOError && (
                <Body1 colour="redDark" center>
                  {t`common:thereWasAnErrorLoading`}
                </Body1>
              )}
            </Spacing>
          </>
        ) : (
          <>
            <NavigationHeader transparent />
            <Header>
              <Label marginBottom={12} center weight="500" colour={colors.brandColor}>
                {t`volunteerTimeOff`.toLocaleUpperCase(getLanguageCode(i18n.language))}
              </Label>
              <H4 center marginBottom={12}>
                {volunteerTimeOff?.Deed?.name || t`tellUsAboutYourVolunteering`}
              </H4>
              <Body2 center colour={colors.gray}>{t`editYourVolunteerHoursBelow`}</Body2>
            </Header>

            <Divider style={{ marginLeft: 20, marginRight: 20, marginBottom: metrics.isLarge ? 45 : 15 }} />
            {vtoData ? (
              <FormContainer>
                <Form onSubmit={formik.handleSubmit}>
                  <Row style={{ justifyContent: 'space-between' }}>
                    <View style={{ width: '48%' }}>
                      <Label>{t`hours`}</Label>
                      <TextField
                        placeholder={t`enterHours`}
                        onChangeText={(fieldName: string, value: string): void => {
                          if (/^[0-9]+$/.test(value) || !value) {
                            void formik.setFieldValue(fieldName, value)
                          }
                        }}
                        onTouched={async (fieldName: string) => formik.setFieldTouched(fieldName, true, false)}
                        name="hours"
                        value={formik.values.hours}
                        pattern="\d{1,3}"
                        step="1"
                        min="0"
                        max="24"
                        keyboardType="number-pad"
                        returnKeyType="done"
                        style={roundedInputStyle}
                      />
                      {!!formik.errors.hours && formik.touched.hours && <ErrorText text={formik.errors.hours} />}
                    </View>
                    {showVTOMinutesSelector && (
                      <View style={{ width: '48%' }}>
                        <Label>{t`minutes`}</Label>
                        <SelectBox
                          onSelect={(value) => {
                            void formik.setFieldValue('minutes', value)
                          }}
                          onDeselect={() => {
                            void formik.setFieldValue('minutes', '')
                          }}
                          onFocus={() => {
                            void formik.setFieldTouched('minutes', true, false)
                          }}
                          value={formik.values.minutes}
                          placeholder={t`selectMinutes`}
                          options={[
                            { value: '0', title: t`0mins` },
                            { value: '15', title: t`15mins` },
                            { value: '30', title: t`30mins` },
                            { value: '45', title: t`45mins` },
                          ]}
                          style={{
                            marginTop: 8,
                            marginBottom: 16,
                            minWidth: 'auto',
                            width: '100%',
                            marginHorizontal: 0,
                          }}
                        />
                        {!!formik.errors.minutes && formik.touched.minutes && (
                          <ErrorText text={formik.errors.minutes} />
                        )}
                      </View>
                    )}
                  </Row>
                  <View>
                    <Label>{t`common:date`}</Label>
                    <View style={{ marginTop: 8, marginBottom: 16, pointerEvents: 'none' }}>
                      <DatePicker value={formik.values.startingAt} disabled />
                    </View>
                    {!!formik.errors.date && formik.touched.date && <ErrorText text={formik.errors.date} />}
                  </View>
                  <View>
                    <Label>{user?.organization?.settings?.logHoursNotesMandatory ? t`notes` : t`notesOptional`}</Label>
                    <TextField
                      placeholder={t`pleaseAddAShortDescription`}
                      onChangeText={formik.setFieldValue}
                      name="note"
                      value={formik.values.note}
                      style={roundedInputStyle}
                      numberOfLines={8}
                      scrollEnabled={false}
                      multiline
                      onSubmitEditing={formik.handleSubmit}
                    />
                  </View>
                  <Row style={{ justifyContent: 'center', gap: 20 }}>
                    <Button
                      color="transparent"
                      onPress={() => {
                        history.push(`/profile/volunteer-time-off-hours/${id}`)
                      }}
                    >{t`common:Cancel`}</Button>
                    <Button
                      onPress={formik.handleSubmit}
                      palette="primary"
                      loading={updateVTOLoading}
                      disabled={!formik.dirty || !formik.isValid || updateVTOLoading}
                    >
                      {t`common:Submit`}
                    </Button>
                  </Row>
                </Form>
              </FormContainer>
            ) : null}
          </>
        )}
      </ScrollView>
      {isErrorModalOpen && (
        <ModalError visible description={t`anErrorOccuredWhileSaving`} onClose={() => setIsErrorModalOpen(false)} />
      )}
    </Screen>
  )
}

export default EditVolunteerTimeOffForm
