import { differenceInMinutes } from 'date-fns'
import { pick } from 'lodash'

import { causes } from 'src/data/causes'
import Deed from 'src/entities/deed/model'
import { DeedStatus, OpportunityProvider, SearchOpportunitiesQuery } from 'src/generated/graphql'

import truthy from './truthy'

type Opportunity = NonNullable<SearchOpportunitiesQuery['searchOpportunities']>['opportunities'][number]

export const providerCodeMap = {
  [OpportunityProvider.VolunteerMatch]: 'vm',
  [OpportunityProvider.VolunteerGov]: 'vg',
  [OpportunityProvider.PointsOfLight]: 'pl',
  [OpportunityProvider.Idealist]: 'id',
  [OpportunityProvider.Goodera]: 'go',
  [OpportunityProvider.Betterplace]: 'bp',
  [OpportunityProvider.Catchafire]: 'cf',
  [OpportunityProvider.GlobalGiving]: 'gg',
}

export const codeProviderMap = Object.fromEntries(
  Object.entries(providerCodeMap).map(([key, value]) => [value, key])
) as Record<string, OpportunityProvider>

const MAX_ATTENDEE_LIMIT = 100

export const mapOpportunityToDeed = (opportunity: Opportunity) => {
  const nonprofitSource = opportunity.nonprofit

  const provisionalNonprofitData = nonprofitSource && {
    ...pick(nonprofitSource, ['name', 'pictures']),
    mainPicture: nonprofitSource.pictures?.[0],
    tagLine: nonprofitSource.mission,
    brief: nonprofitSource.description,
    phone: nonprofitSource.phoneNumber,
    website: nonprofitSource.url,
  }

  const categories = opportunity.categories
    ?.map((category) => causes.find((cause) => cause.code === category)?.id)
    .filter(truthy)
  const SDGs = opportunity.sdgs?.map((category) => causes.find((cause) => cause.code === category)?.id).filter(truthy)

  const mappedData = {
    ...pick(opportunity, [
      'provider',
      'providerId',
      'type',
      'virtual',
      'name',
      'mustKnows',
      'pictures',
      'externalLink',
      'timeZone',
    ]),
    __t: opportunity.type,
    id: `opportunity-${providerCodeMap[opportunity.provider]}-${opportunity.providerId}`,
    externalId: `ext-${providerCodeMap[opportunity.provider]}-${opportunity.providerId}`,
    mainPicture: opportunity.pictures?.[0],
    description: opportunity.description || '',
    public: false,
    requireAuthentication: true,
    organizerEmail: opportunity.contact?.email,
    organizerPhone: opportunity.contact?.phone,
    organizerName: opportunity.contact?.name,
    attendeeLimit: (opportunity?.participantsLimit || 0) > 0 ? opportunity.participantsLimit : MAX_ATTENDEE_LIMIT,
    startingAt: opportunity.startingAt && new Date(opportunity.startingAt),
    endingAt: opportunity.endingAt && new Date(opportunity.endingAt),
    createdAt: new Date(),
    duration:
      opportunity.endingAt && opportunity.startingAt
        ? differenceInMinutes(new Date(opportunity.endingAt), new Date(opportunity.startingAt))
        : null,
    registrationEndingAt: opportunity.registrationEndingAt ? new Date(opportunity.registrationEndingAt) : undefined,
    status: DeedStatus.Published,
    locationObject: opportunity.location,
    location: opportunity.location?.geo,
    categories,
    SDGs,
    provisionalNonprofitData,
    roles: [],
  }
  return new Deed(mappedData)
}
