import React, { FC, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { useInjectReducer } from 'src/utils/injectReducer'
import { useInjectEpics } from 'src/utils/injectEpics'
import Layout from 'src/retired/blocks/Layout'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { selectIsAuthenticated } from 'src/entities/auth/selectors'
import { selectCampaignById } from 'src/entities/campaign/selectors'
import {
  selectDeedsForCampaign,
  selectDonationsActivitiesForCampaign,
  selectNonprofitsForCampaign,
} from 'src/entities/deed/selectors'
import { setLocalSettingAction } from 'src/localSettings/actions'
import { Loading, Spacing } from 'src/retired/elements'
import { Text } from 'src/retired/shared/Typography'
import { State } from 'src/reducers'
import { PageTitle } from 'src/components'
import { QuickRedirectToLogin } from 'src/navigation/QuickRedirectToLogin'

import { selectError, selectLoading } from './selectors'
import epics from './epics'
import reducer from './reducer'
import CampaignDeedsContainer from './CampaignDeedsContainer'
import CampaignStats from './CampaignStats/CampaignStats'
import HeroBanner from './HeroBanner'
import CommunityFeed from './CommunityFeed'
import ShareBlock from './ShareBlock'
import './loadWebFont'
import { initAction } from './actions'
import { CampaignContent } from './CampaignContent'

const Campaign: FC = () => {
  const { t } = useTranslation('campaignScreen')
  useInjectReducer({ key: 'campaign', reducer })
  useInjectEpics({ key: 'campaign', epics })
  const dispatch = useDispatch()
  const { id = '' } = useParams<{ id?: string; country?: string; lite?: string }>()
  const user = useSelector(selectCurrentUser)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const error = useSelector(selectError)
  useEffect(() => {
    dispatch(initAction(id))
  }, [dispatch, id])

  const campaign = useSelector((state: State) => selectCampaignById(state, id))
  const fundraisers = useSelector((state: State) => selectDeedsForCampaign(state, 'Campaign', campaign))
  const donationActivities = useSelector((state: State) => selectDonationsActivitiesForCampaign(state, id))
  const loading = useSelector(selectLoading)
  const nonprofits = useSelector((state: State) => selectNonprofitsForCampaign(state, id))

  const { colors, metrics } = useDeedTheme()

  const scrollViewEl = useRef(null)
  const [deedsOffset, setDeedsOffset] = useState(0)

  if (error) {
    const forbidden = error.response?.status === 403
    if (forbidden && !isAuthenticated) {
      const previousLocation = `/campaign/${id}`
      dispatch(setLocalSettingAction('previousLocation', previousLocation))
      if (window?.sessionStorage) {
        window.sessionStorage.setItem('previousLocation', previousLocation)
      }

      return <QuickRedirectToLogin />
    }
    return (
      <Layout padding>
        <Text fontSize={metrics.isSmall ? 24 : 30} colour="redDark" center marginTop={metrics.isSmall ? 100 : 200}>
          {forbidden ? t`campaignRestricted` : t`common:thereWasAnErrorLoading`}
        </Text>
      </Layout>
    )
  }
  if (!campaign || (isAuthenticated && !user)) {
    return <Loading backgroundColor={metrics.isLarge ? colors.gray03 : colors.white} />
  }

  const onLayout = (e: Event): void => {
    setDeedsOffset(e.nativeEvent.layout.y)
  }

  const onCtaPress = (e: Event): void => {
    e.preventDefault()
    const additionalOffset = metrics.isLarge ? 100 : metrics.isSmall ? 0 : 60

    scrollViewEl.current?.scrollTo({
      y: deedsOffset + additionalOffset,
      animated: true,
    })
  }

  return (
    <Layout fullWidth padding scrollRef={scrollViewEl} withoutHeader keepTabMenu={metrics.isMedium}>
      <PageTitle title={campaign.name} />
      <HeroBanner
        campaignMetadataLoading={loading}
        campaign={campaign}
        onCtaPress={onCtaPress}
        fundraisers={fundraisers}
      />
      <CampaignStats campaignMetadataLoading={loading} campaign={campaign} user={user} nonprofits={nonprofits} />
      <CampaignContent campaign={campaign} user={user} />
      <CampaignDeedsContainer
        campaign={campaign}
        fundraisers={fundraisers}
        user={user}
        loading={loading}
        onLayout={onLayout}
      />
      {!campaign?.hideActivityFeed && donationActivities.length > 0 && !loading && (
        <CommunityFeed
          ctaButtonColor={campaign.ctaButtonColor}
          ctaTextColor={campaign.ctaTextColor}
          campaignId={campaign.id}
        />
      )}
      {user?.organization?.settings?.disableSocialSharing ? null : (
        <ShareBlock shareLink={campaign.shareLink} campaignName={campaign.title} />
      )}
      <Spacing marginTop={80} />
    </Layout>
  )
}

export default Campaign
