import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import { Box, Button, Divider, IconButton } from '@mui/material'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import Chip from 'src/retired/shared/Chip'
import DonateButton from 'src/retired/shared/DonateButton'
import { Body1, Body2 } from 'src/retired/shared/Typography'
import { colors } from 'src/theme'
import { formatNumber } from 'src/utils/format'
import { selectCurrentUser } from 'src/entities/user/selectors'
import Deed from 'src/entities/deed/model'

import { resetRateDeedFlowAction, submitRateAction } from './actions'
import { selectRateSubmitError, selectRateSubmitloading, selectRateSubmitSuccess } from './selectors'

const RATING_MAX = 5

export const RateStep = ({
  deed,
  noFriendsToAdd,
  redirectLocation,
  setShowAddFriendStep,
}: {
  deed: Deed
  noFriendsToAdd: boolean
  redirectLocation: string
  setShowAddFriendStep: Dispatch<SetStateAction<boolean>>
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('deedScreen')
  const history = useHistory()

  const rateSubmitLoading = useSelector(selectRateSubmitloading)
  const rateSubmitSuccess = useSelector(selectRateSubmitSuccess)
  const rateSubmitError = useSelector(selectRateSubmitError)
  const user = useSelector(selectCurrentUser)

  const [rating, setRating] = useState<number>(0)

  useEffect(() => {
    if (rateSubmitError) {
      setRating(0)
    }
  }, [rateSubmitError])

  const handleStarClick = (index: number) => {
    const newRating = index + 1
    setRating(newRating)
    const setDeedAsRated = noFriendsToAdd
    dispatch(submitRateAction(deed.id, newRating, setDeedAsRated))
  }

  const showDonateButton = deed.organization?.canDonate() && user?.hasFeature('donations')

  return (
    <>
      <Body1 weight="500" style={{ textAlign: 'center' }}>
        {t`congrats`} {t`howWasYourDeed`}
      </Body1>
      <Box mb={2} />
      <Body2 style={{ textAlign: 'center' }}>{t`yourFeedbackHelpsUs`}</Body2>
      <Box mb={2} />
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
        {Array.from({ length: RATING_MAX }).map((_, index) => (
          <IconButton
            sx={{
              cursor: rateSubmitLoading ? 'default' : 'pointer',
              transform: rating === index + 1 ? 'scale(1.5)' : 'scale(1)',
              transition: 'transform 0.3s ease-in-out',
              color: colors.yellow,
              opacity: rateSubmitLoading ? 0.7 : 1,
            }}
            key={index}
            onClick={() => (rateSubmitLoading || rateSubmitSuccess ? null : handleStarClick(index))}
          >
            {rating > index ? <StarIcon /> : <StarBorderIcon />}
          </IconButton>
        ))}
      </Box>

      {showDonateButton && (
        <>
          <Divider sx={{ mt: 2, mb: 3, width: '100%' }} />
          <DonateButton model={deed.organization}>{t`donateToOrganization`}</DonateButton>
          {deed.matchingPercentage &&
          deed.matchingMaximumCurrencies &&
          deed.matchingMaximumCurrencies[deed.currencyCode] > 0 ? (
            <Box mt={2}>
              <Chip type="square" backgroundColor="yellow" textWeight="bold">
                {`${t('donationsMatched', {
                  percentage: `${formatNumber(deed.matchingPercentage / 100)}X`,
                })}`.toUpperCase()}
              </Chip>
            </Box>
          ) : null}
        </>
      )}

      <Button
        onClick={() => {
          if (rating === 0) {
            const setDeedAsRated = noFriendsToAdd
            dispatch(submitRateAction(deed.id, 0, setDeedAsRated))
          }
          // in database zero means user skipped
          if (noFriendsToAdd) {
            dispatch(resetRateDeedFlowAction())
            history.replace(redirectLocation)
          } else {
            setShowAddFriendStep(true)
          }
          setRating(0)
        }}
        sx={{ mt: 2 }}
      >
        {rateSubmitSuccess
          ? noFriendsToAdd
            ? t`common:close`
            : showDonateButton
            ? t`common:skip`
            : t`common:Next`
          : t`common:skip`}
      </Button>
    </>
  )
}
