import { colors, metrics } from '..'
import { fontStyles } from '../loadWebFont'

import { breakpoints } from './breakpoints'

export const components = {
  MuiCssBaseline: {
    styleOverrides: fontStyles,
  },
  MuiPaper: {
    styleOverrides: {
      rounded: { borderRadius: 8 },
      outlined: { border: `1px solid ${colors.gray02}` },
    },
  },
  MuiContainer: {
    styleOverrides: {
      maxWidthLg: {
        [breakpoints.up('lg')]: {
          maxWidth: metrics.maxWidthWide,
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: colors.black,
        color: colors.white,
        borderColor: colors.black,
      },
      arrow: {
        color: colors.black,
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        borderRadius: 32,
        height: 40,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        top: '-6px',
        '&.MuiInputLabel-shrink': {
          top: '0px',
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        minHeight: 40,
        borderRadius: 32,
        '&.MuiInputBase-multiline': {
          borderRadius: 16,
        },
      },
      input: {
        padding: '8px 16px',
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        backgroundColor: colors.mediumGray,
      },
    },
  },
} as const
