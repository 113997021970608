import { OrderedMap } from 'immutable'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { DeedMap } from 'src/entities/deed/reducer'
import { useSearchOpportunitiesQuery } from 'src/generated/graphql'
import { mapOpportunityToDeed } from 'src/utils/mapOpportunityToDeed'
import { selectCurrentUser } from 'src/entities/user/selectors'

import { useSearchParams } from '../../utils'

const pageSize = 20
const defaultRadius = 10

/**
 * Augment deeds feed with opportunities
 */
export const useAugmentDeedsWithOpportunities = ({
  type,
  deeds,
  loadMore,
  loading,
  loaded,
  hasMoreItems,
}: {
  type: string
  deeds: DeedMap
  loadMore: () => void
  loading: boolean
  loaded: boolean
  hasMoreItems: boolean
}) => {
  const user = useSelector(selectCurrentUser)
  const enabled = user?.hasFeature('autoSourceDeeds') && user?.hasFeature('browsingExternalOpportunities')

  const { currentActiveCauses, attendanceOptions, coordinates, radius, searchTerm, date, days, times } =
    useSearchParams()

  const area = coordinates
    ? {
        // @NOTE-DP: we need to map back to [long, lat] order again after https://github.com/joindeed/app/blob/main/src/containers/screens/GeneralSearch/VolunteerTab/Filters/VolunteerFilters.tsx#L89, since that's what searchOpportunities expects
        coordinates: [coordinates[1], coordinates[0]],
        radius: radius || defaultRadius,
      }
    : // @NOTE-DP: if no location filter is selected, fallback to user's location
      user?._location?.coordinates && {
        coordinates: [user._location.coordinates[1], user._location.coordinates[0]],
        radius: user._location.radius || defaultRadius,
      }

  const categories = currentActiveCauses.map((cause) => cause.code).toArray()

  const filterNotCompatibleWithOpportunities = Boolean(
    // @TODO-DP: filtering on dates is not that easy to implement. Please tackle it whoever is able
    date ||
      days?.length > 0 ||
      times?.length > 0 ||
      // @NOTE-DP: if there are custom categories with no code, we can't filter on them
      !categories.every((category) => Boolean(category))
  )

  const [opportunitiesPage, setOpportunitiesPage] = useState(1)
  const [accumulatedOpportunities, setAccumulatedOpportunities] = useState(OrderedMap())

  // @NOTE-DP: if both options are selected, treat it as no filter applied
  const attendence =
    attendanceOptions?.length === 1 ? (attendanceOptions[0] === 'Virtual' ? 'Virtual' : 'InPerson') : undefined

  // @NOTE-DP: put all dynamic filters here, that should reset the feed state
  const filters = {
    searchTerm,
    attendence,
    categories,
    locations: area ? [area] : undefined,
  }

  const hasMoreItemsAndSomeDeeds =
    hasMoreItems &&
    // @NOTE-DP: this is a bit ugly. Because of client-side deeds filtering, we may end up in a situation when there is `hasMoreItems` but all deeds are filtered out. In that case, we should just load more opportunities immediately
    deeds.size !== 0

  const opportunities = useSearchOpportunitiesQuery({
    variables: {
      types: [type],
      limit: pageSize,
      offset: pageSize * (opportunitiesPage - 1),
      ...filters,
    },
    skip: !enabled || !loaded || hasMoreItemsAndSomeDeeds || filterNotCompatibleWithOpportunities,
  })

  const opportunitiesHasMoreItems = (opportunities.data?.searchOpportunities?.total || 0) > pageSize * opportunitiesPage

  // @NOTE-DP: reset state after filters change
  useEffect(() => {
    setAccumulatedOpportunities(OrderedMap())
  }, [JSON.stringify(filters)])

  // @NOTE-DP: accumulate feed state on "loadMore"
  useEffect(() => {
    if (opportunities.data) {
      const newMap = OrderedMap(
        (opportunities.data?.searchOpportunities?.opportunities || []).map((opp) => [
          opp.providerId,
          mapOpportunityToDeed(opp),
        ])
      )
      setAccumulatedOpportunities((prev) => prev.concat(newMap))
    }
  }, [opportunities.data])

  return {
    deeds: deeds.concat(accumulatedOpportunities) as DeedMap,
    loading: loading || opportunities.loading,
    hasMoreItems: hasMoreItems || opportunitiesHasMoreItems,
    loadMore: () => {
      if (hasMoreItemsAndSomeDeeds) {
        loadMore()
      } else if (opportunitiesHasMoreItems) {
        setOpportunitiesPage(opportunitiesPage + 1)
      }
    },
    totalCount: (opportunities.data?.searchOpportunities?.total || 0) + deeds.size,
  }
}
