import { Action } from 'redux'

import {
  RESET_RATE_DEED_FLOW,
  SUBMIT_FRIEND_REQUEST,
  SUBMIT_FRIEND_REQUEST_FAILED,
  SUBMIT_FRIEND_REQUEST_SUCCESS,
  SUBMIT_RATE,
  SUBMIT_RATE_FAILED,
  SUBMIT_RATE_SUCCESS,
} from './constants'

export interface SubmitRateAction extends Action<typeof SUBMIT_RATE> {
  id: string
  rating: number
  setDeedAsRated: boolean
}
export const submitRateAction = (id: string, rating: number, setDeedAsRated: boolean): SubmitRateAction => ({
  type: SUBMIT_RATE,
  id,
  rating,
  setDeedAsRated,
})

export interface SubmitRateSuccessAction extends Action<typeof SUBMIT_RATE_SUCCESS> {}
export const submitRateSuccessAction = (): SubmitRateSuccessAction => ({
  type: SUBMIT_RATE_SUCCESS,
})

export interface SubmitRateFailedAction extends Action<typeof SUBMIT_RATE_FAILED> {}
export const submitRateFailedAction = (): SubmitRateFailedAction => ({
  type: SUBMIT_RATE_FAILED,
})

export interface SubmitFriendRequestAction extends Action<typeof SUBMIT_FRIEND_REQUEST> {
  id: string
  friendRequest: string
}
export const submitFriendRequestAction = (id: string, friendRequest: string): SubmitFriendRequestAction => ({
  type: SUBMIT_FRIEND_REQUEST,
  id,
  friendRequest,
})

export interface SubmitFriendRequestSuccessAction extends Action<typeof SUBMIT_FRIEND_REQUEST_SUCCESS> {
  friendUserId: string
}
export const submitFriendRequestSuccessAction = (friendUserId: string): SubmitFriendRequestSuccessAction => ({
  type: SUBMIT_FRIEND_REQUEST_SUCCESS,
  friendUserId,
})

export interface SubmitFriendRequestFailedAction extends Action<typeof SUBMIT_FRIEND_REQUEST_FAILED> {}
export const submitFriendRequestFailedAction = (): SubmitFriendRequestFailedAction => ({
  type: SUBMIT_FRIEND_REQUEST_FAILED,
})

export interface ResetRateDeedFlowActionAction extends Action<typeof RESET_RATE_DEED_FLOW> {}
export const resetRateDeedFlowAction = () => ({
  type: RESET_RATE_DEED_FLOW,
})

export type DeedRateActions =
  | SubmitRateAction
  | SubmitRateSuccessAction
  | SubmitRateFailedAction
  | SubmitFriendRequestAction
  | SubmitFriendRequestSuccessAction
  | SubmitFriendRequestFailedAction
  | ResetRateDeedFlowActionAction
