import type { TextFieldProps } from '@mui/material'
import { styled, TextField } from '@mui/material'
import type {
  DatePickerProps as MuiDatePickerProps,
  PickersActionBarAction,
  PickersDayProps,
} from '@mui/x-date-pickers'
import { DatePicker as MuiDatePicker, PickersDay } from '@mui/x-date-pickers'
import { format } from 'date-fns'
import React, { forwardRef, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { EmotionTheme, useDeedTheme } from 'src/theme/ThemeProvider'

export interface CustomDatePickerProps<TDate> extends MuiDatePickerProps<Date, TDate> {
  error?: string | boolean
  placeholder?: string
  onClear?: () => void
  onSetToday?: () => void
  disabledDate?: (day: TDate) => boolean
  availableDates?: Record<string, number>
  style?: React.CSSProperties
  enableTodayAction?: boolean
  enableClearAction?: boolean
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>
type DatePickerProps<TDate> = PartialBy<CustomDatePickerProps<TDate>, 'renderInput'>

const StyledDatePicker = styled(MuiDatePicker)(() => ({
  ':hover': { borderRadius: 20, border: `1px solid #5291ff` }, // NOTE-AZ: replace with the variable from colors.js
}))

const StyledTextField = styled(TextField)<TextFieldProps & { theme?: EmotionTheme }>(({ theme }) => ({
  borderRadius: 20,
  border: `1px solid ${theme.colors.gray02}`,
  backgroundColor: 'white',
  padding: 0,

  '&.Mui-disabled .MuiInputBase-input': {
    background: '#f5f5f5',
    cursor: 'not-allowed',
    '&:focus, &:hover': {
      borderColor: theme.colors.gray02,
    },
  },
  '& .MuiButtonBase-root': {
    color: theme.palette.grey[300],
  },

  '& .MuiInputBase-input': {
    padding: '7px 0 7px 12px',
    fontSize: '12px',
  },

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiInputAdornment-root': {
    '& .MuiSvgIcon-root': {
      height: '0.65em',
    },
  },
}))

export const DatePicker = forwardRef<HTMLInputElement, DatePickerProps<Date>>(
  (
    {
      value,
      disabled = false,
      onChange,
      error,
      minDate,
      maxDate,
      disabledDate,
      availableDates,
      placeholder,
      style,
      enableTodayAction = false,
      enableClearAction = false,
    },
    ref
  ): JSX.Element => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const theme = useDeedTheme()

    const renderDay = useCallback(
      (date: Date, selectedDates: Array<Date | null>, pickersDayProps: PickersDayProps<Date>) => {
        const key = format(date, 'yyyy/MM/dd')
        const isAvailable = availableDates ? !!availableDates[key] : undefined

        return (
          <PickersDay
            {...pickersDayProps}
            day={date}
            autoFocus={false}
            sx={
              availableDates
                ? {
                    color: isAvailable ? theme.palette.grey[800] : theme.palette.grey[400],
                  }
                : undefined
            }
          />
        )
      },
      [availableDates]
    )

    return (
      <StyledDatePicker
        inputRef={ref}
        minDate={minDate}
        maxDate={maxDate}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        value={value}
        disabled={disabled}
        onChange={onChange}
        shouldDisableDate={disabledDate}
        sx={style}
        slots={{
          textField: StyledTextField,
          day: (dayProps) => renderDay(dayProps.day, dayProps.selected, dayProps), // Custom render function for day slot
        }}
        slotProps={{
          textField: {
            size: 'small',
            error: !!error,
            helperText: error,
            onClick: () => setOpen(true),
            placeholder: placeholder ?? t('datePicker:selectDate'),
          },
          actionBar: {
            actions: [
              enableClearAction ? 'clear' : null,
              enableTodayAction ? 'today' : null,
              theme.metrics.isSmall ? 'accept' : null,
            ].filter((action): action is PickersActionBarAction => action !== null),
          },
          dialog: {
            sx: ({ palette }) => ({
              '& button.Mui-selected:focus, & button.Mui-selected:active': { backgroundColor: palette.primary.light },
            }),
          },
        }}
        localeText={{
          cancelButtonLabel: t('datePicker:clear'),
          todayButtonLabel: t('datePicker:today'),
        }}
      />
    )
  }
)
