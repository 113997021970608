import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { Box, Button, CircularProgress } from '@mui/material'
import _ from 'lodash'
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ModalWithCloseButton } from 'src/components/Modal/ModalWithCloseButton'
import { SearchInput } from 'src/components/SearchInput/SearchInput'
import Deed from 'src/entities/deed/model'
import User from 'src/entities/user/model'
import { Avatar, Divider, ScrollView } from 'src/retired/elements'
import { Body1, H5 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { formatFullName } from 'src/utils'
import { WarningListItem } from 'src/components'

import { removeAttendee } from '../../actions'
import { selectRemovingAttendee } from '../../selectors'
import { getEndDateFormattedTime, getStartDateFormattedTime } from '../../utils'

const AttendeeListItem = ({
  attendee,
  deed,
  setConfirmingAttendee,
  filteredAttendees,
  user,
  index,
  removing,
}: {
  attendee: User
  deed: Deed
  setConfirmingAttendee: Dispatch<SetStateAction<string | null>>
  filteredAttendees: User[]
  user: User | undefined
  index: number
  removing: boolean
}) => {
  const { colors } = useDeedTheme()
  const { t } = useTranslation('deedScreen')

  return (
    <Box
      sx={{
        animation: 'fade-in 0.3s ease-in-out',
        '@keyframes fade-in': {
          from: { opacity: 0 },
          to: { opacity: 1 },
        },
        opacity: removing ? 0.8 : 1,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pt: 1, gap: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Avatar
            user={attendee}
            link={user && String(attendee.id) !== String(user.id)}
            style={{ borderWidth: attendee?.clickable ? 2 : 0, borderColor: colors.blue }}
          />
          <Body1>{attendee.name}</Body1>
        </Box>
        {user && deed.isSubmittedByUser(user) && deed.isUpcoming() && user.id !== attendee.id && (
          <Button
            size="small"
            sx={{
              height: 24,
              px: 1,
              pointerEvents: removing ? 'none' : 'auto',
            }}
            variant="contained"
            onClick={() => setConfirmingAttendee(attendee.id)}
          >
            {removing ? <CircularProgress size={12} color="inherit" /> : t`remove`}
          </Button>
        )}
      </Box>
      {index < filteredAttendees.length - 1 && <Divider marginTop={8} marginBottom={0} />}
    </Box>
  )
}

interface AttendeesListModalProps {
  deed: Deed
  visible: boolean
  onClose: () => void
  user?: User
}

export const AttendeesListModal = ({ deed, visible, onClose, user }: AttendeesListModalProps) => {
  const { t } = useTranslation('deedScreen')
  const dispatch = useDispatch()
  const { metrics, colors } = useDeedTheme()

  const { attendees } = deed
  const [currentSearchTerm, setCurrentSearchTerm] = useState('')
  const [filteredAttendees, setFilteredAttendees] = useState(attendees)
  const [confirmingAttendee, setConfirmingAttendee] = useState<string | null>(null) // ID of attendee being confirmed to delete
  const removingAttendee = useSelector(selectRemovingAttendee)

  const handleDelete = (attendeeId: string) => {
    dispatch(removeAttendee(deed.id, attendeeId))
    setConfirmingAttendee(null)
  }

  const handleSearch = useCallback(
    _.debounce((searchTerm: string) => {
      setFilteredAttendees(
        searchTerm
          ? attendees.filter((attendee) =>
              formatFullName(attendee.preferredName || attendee.fullName)
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
            )
          : attendees
      )
    }, 300),
    [attendees]
  )

  useEffect(() => {
    handleSearch(currentSearchTerm)
  }, [attendees, currentSearchTerm, handleSearch])

  const handleSearchInputChange = (value: string) => {
    setCurrentSearchTerm(value)
    handleSearch(value)
  }
  const startDateFormattedTime = getStartDateFormattedTime(deed)
  const endDateFormattedTime = getEndDateFormattedTime(deed)

  const formattedDate = startDateFormattedTime
    ? `${startDateFormattedTime}${endDateFormattedTime ? ` – ${endDateFormattedTime}` : ''}`
    : null

  return (
    <ModalWithCloseButton visible={visible} onClose={onClose} title={<H5 weight="500">{deed?.name}</H5>}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          maxWidth: metrics.isLarge ? 600 : 'auto',
          width: metrics.isSmall ? '100%' : 600,
          paddingTop: '16px',
        }}
      >
        {formattedDate ? (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
            <CalendarTodayIcon />
            {formattedDate}
          </Box>
        ) : null}
        <Box sx={{ display: 'flex', mt: 2 }}>
          <Body1>{`${attendees.length} ${deed.isPast() ? t('attended') : t('attending')}`}</Body1>
          {['BaseEvent', 'Event', 'Project'].includes(deed.type) && !deed.isPast() && (
            <>
              ,<Body1 colour={colors.brandColor}> {t('spotsLeft', { count: deed.numberOfSpotsAvailable() })}</Body1>
            </>
          )}
        </Box>

        <Divider />
        <Box sx={{ pb: 3, width: '100%' }}>
          <SearchInput
            outlined
            small
            fieldName="searchAttendees"
            placeholder={t('searchAttendees')}
            inputValue={currentSearchTerm}
            onChange={(__, value) => handleSearchInputChange(value)}
            onReset={() => handleSearchInputChange('')}
            triggerSearch={() => handleSearch(currentSearchTerm)}
          />
        </Box>
        <ScrollView style={{ width: '100%' }}>
          {filteredAttendees.length ? (
            filteredAttendees.map((attendee, index) => (
              <React.Fragment key={attendee.id}>
                {confirmingAttendee === attendee.id ? (
                  <WarningListItem
                    content={t('areYouSureToDeleteAttendee', { username: attendee?.name })}
                    confirmButtonText={t`remove`}
                    onClickConfirm={() => handleDelete(attendee?.id)}
                    onAnimationEnd={() => setConfirmingAttendee(null)}
                  />
                ) : (
                  <AttendeeListItem
                    index={index}
                    deed={deed}
                    attendee={attendee}
                    filteredAttendees={filteredAttendees}
                    setConfirmingAttendee={setConfirmingAttendee}
                    user={user}
                    removing={removingAttendee === attendee.id}
                  />
                )}
              </React.Fragment>
            ))
          ) : (
            <Body1>{t('noResults')}</Body1>
          )}
        </ScrollView>
      </Box>
    </ModalWithCloseButton>
  )
}
