import CheckIcon from '@mui/icons-material/Check'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import { Box, Button, CircularProgress } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Avatar, Text } from 'src/retired/elements'
import { colors } from 'src/theme'

export const AddFriendCard = ({
  user,
  added,
  onClick,
  loading,
}: {
  user: any
  added: boolean
  onClick: () => void
  loading: boolean
}) => {
  const { t } = useTranslation('deedScreen')

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: 2,
        borderRadius: 2,
        backgroundColor: colors.gray03,
        cursor: 'pointer',
        transition: 'all 0.2s',
        '&:hover': {
          backgroundColor: colors.gray02,
        },
        width: '100%',
      }}
    >
      <Avatar user={user} size={40} link={false} selected={added} />
      <Box sx={{ display: 'flex', flex: 1 }}>
        <Text size={15} medium numberOfLines={2} left>
          {user.name}
        </Text>
      </Box>

      <Button
        size="small"
        onClick={added ? undefined : onClick}
        endIcon={loading ? undefined : added ? <CheckIcon fontSize="small" /> : <PersonAddAltIcon fontSize="small" />}
        sx={{ px: 1 }}
      >
        {loading ? <CircularProgress color="inherit" size={16} /> : added ? t`added` : t`addFriend`}
      </Button>
    </Box>
  )
}
