import { VALIDATE_RECEIPT, VALIDATE_RECEIPT_SUCCESS, VALIDATE_RECEIPT_FAILED } from './constants'

export interface ReceiptValidationLocationObject {
  address: string
  postalCode: string
  stateCode: string
  countryCode: string
}

export interface ReceiptValidationDonor {
  firstName: string
  lastName: string
  email: string
  address: ReceiptValidationLocationObject | null
}

export interface ReceiptValidationNonprofit {
  name: string
  taxId: string
  address: ReceiptValidationLocationObject | null
}

export interface ReceiptValidationDonation {
  amount: number
  feeAmount: number
  totalAmount: number
  currency: string
  date: string
  identifier: string
  address: ReceiptValidationLocationObject | null
}

export interface ReceiptValidationObservations {
  hasIrsDisclosures: boolean
  rewardReceived: boolean
  userIsLikelyDonor: boolean
  donationProcessorRecognized: boolean
  receiptIsLikelyADonation: boolean
  receiptIsLikelyAnAcceptableDonation: boolean
}

export interface ReceiptValidation {
  donor: ReceiptValidationDonor
  nonprofit: ReceiptValidationNonprofit
  donationProcessor: ReceiptValidationNonprofit
  donation: ReceiptValidationDonation
  observations: ReceiptValidationObservations
}

export type ValidateReceiptPayload = {
  receipt: string
}

export interface ValidateReceipt {
  type: typeof VALIDATE_RECEIPT
  data: ValidateReceiptPayload
}
export const validateReceiptAction = (data: ValidateReceiptPayload): ValidateReceipt => ({
  type: VALIDATE_RECEIPT,
  data,
})

export const validateReceiptSuccessAction = (receiptValidation: ReceiptValidation | null) => ({
  type: VALIDATE_RECEIPT_SUCCESS,
  receiptValidation,
})

export const validateReceiptFailedAction = (error: Error) => ({
  type: VALIDATE_RECEIPT_FAILED,
  error,
})

export type Actions =
  | ReturnType<typeof validateReceiptAction>
  | ReturnType<typeof validateReceiptSuccessAction>
  | ReturnType<typeof validateReceiptFailedAction>
