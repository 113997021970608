import React from 'react'
import { useTranslation } from 'react-i18next'

import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { PageTitle } from 'src/components'

const Header = () => {
  const { t } = useTranslation('demoDonationReceipt')

  return (
    <>
      <PageTitle title={t`demoDonationReceipt`} />
      <NavigationHeader transparent title={t`demoDonationReceipt`} />
    </>
  )
}

export default Header
